import React, { Fragment } from 'react';
import './Logo.scss'

interface LogoProps {
    size?: 'base' | 'sm' | 'md' | 'lg',
    isLayered?: boolean
};

const Logo: React.FC<LogoProps> = (props) => {
  return (
    <Fragment>
        <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 131 35" className={`sh-logo sh-logo-${props.size}`}>
            <g>
                <path d="M2,22.4c0.1,0,0.1,0.1,0.1,0.2c0,0.3-0.1,0.5-0.3,0.8L1.5,24c0-0.1,0.1-0.2,0.3-0.4C2,23.4,2.1,23.2,2.3,23
                    c0.2-0.2,0.3-0.4,0.4-0.6c0.1-0.2,0.2-0.3,0.2-0.3v0c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.2,0.2L2,22.1
                    c0.9-0.7,1.8-1.2,2.8-1.7c1-0.5,2-0.7,3.1-0.7c0.3,0,0.6,0.1,0.8,0.2c0.3,0.1,0.5,0.3,0.6,0.6l-0.2,0.1c0,0-0.2,0.1-0.4,0.2
                    c-0.2,0.1-0.5,0.1-0.7,0.2C7.7,20.9,7.5,21,7.2,21c-0.2,0.1-0.4,0.1-0.5,0.1c-0.4,0.2-0.8,0.4-1.2,0.7c-0.4,0.3-0.8,0.6-1.1,0.9
                    c-0.3,0.3-0.6,0.7-0.8,1.1c-0.2,0.4-0.3,0.9-0.3,1.3c0,0.3,0.1,0.7,0.4,1.1s0.5,0.7,0.7,0.9c1,0,2-0.2,3-0.4c1-0.3,1.8-0.7,2.6-1.2
                    c0.8-0.5,1.4-1.2,1.8-2c0.4-0.8,0.7-1.8,0.7-3c0-0.6-0.1-1.2-0.4-1.7c-0.3-0.5-0.6-1-1-1.4c-0.4-0.4-0.8-0.9-1.3-1.3
                    c-0.5-0.4-0.9-0.9-1.3-1.3c-0.4-0.5-0.7-1-1-1.5c-0.3-0.5-0.4-1.2-0.4-1.8c0-0.1,0-0.1,0-0.2s0-0.1,0-0.2c0-0.3,0.2-0.8,0.5-1.5
                    C8.2,8.9,8.6,8.3,9,7.6C9.5,7,10,6.4,10.4,6c0.5-0.5,0.9-0.7,1.3-0.7v0l0.2-0.1C12,5,12.3,4.7,12.6,4.5c0.4-0.2,0.8-0.4,1.3-0.6
                    c0.5-0.2,0.9-0.3,1.4-0.4c0.5-0.1,0.8-0.2,1.1-0.2c0.3,0,0.7,0.1,1,0.2c0.4,0.2,0.7,0.4,1,0.6c0.3,0.3,0.5,0.6,0.8,0.9
                    c0.2,0.3,0.4,0.6,0.4,0.9l0,0.1c0,0.2-0.2,0.5-0.4,1c-0.3,0.5-0.6,1-0.9,1.5c-0.4,0.5-0.7,1-1.1,1.4c-0.4,0.4-0.6,0.6-0.8,0.6
                    c0,0,0-0.1,0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.3s0.2-0.2,0.4-0.3c0.1-0.1,0.2-0.2,0.2-0.2c-0.1,0-0.3,0.2-0.5,0.3s-0.3,0.3-0.4,0.4
                    c-0.1,0-0.2,0.1-0.4,0.2s-0.3,0.2-0.4,0.2c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0-0.1,0.1-0.2,0.1-0.2s0.1-0.1,0.2-0.2l-0.6,0.5
                    c0.1-0.2,0.3-0.5,0.5-0.8c0.2-0.3,0.3-0.6,0.4-0.7l0.1-0.1c-0.1,0.1-0.1,0.2-0.2,0.3C16,9.7,15.9,9.9,15.8,10
                    c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.2-0.3,0.2c0.4-0.7,0.8-1.4,1.2-2.2c0.4-0.7,0.7-1.5,1.1-2.2c-0.6,0-1.1,0.2-1.6,0.6
                    c-0.5,0.4-1,0.8-1.4,1.4c-0.4,0.5-0.8,1.1-1.1,1.7c-0.3,0.6-0.6,1.1-0.9,1.5c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.3,0.1
                    l0.1,0.1c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0,0.1L12.1,12L12,12.2c0,0.1,0,0.2,0,0.4s0,0.2,0,0.4c0,0.4,0.1,0.8,0.4,1
                    c0.2,0.2,0.5,0.5,0.8,0.7c0.5,0.4,1,0.9,1.6,1.5c0.5,0.6,1.1,1.2,1.5,1.8c0.5,0.7,0.8,1.3,1.1,2c0.3,0.7,0.4,1.4,0.4,2.1
                    c0,0.2,0,0.5,0,0.8c0,0.3-0.1,0.5-0.2,0.7c-0.4,1.1-0.9,2-1.6,2.8c-0.7,0.8-1.6,1.4-2.5,1.9c-0.9,0.5-1.9,0.8-3,1.1
                    c-1.1,0.2-2.1,0.3-3.2,0.3c-0.7,0-1.5-0.1-2.2-0.2S3.7,29,3,28.7c-0.7-0.3-1.2-0.8-1.6-1.3c-0.4-0.6-0.6-1.3-0.6-2.2
                    c0-0.5,0.1-1.1,0.3-1.6c0.2-0.5,0.5-0.9,0.8-1.4L2,22.4z M2.5,23.6c0,0,0.1-0.1,0.1-0.2c0-0.1,0-0.2,0.1-0.2c0,0-0.1,0.1-0.1,0.2
                    C2.6,23.5,2.6,23.6,2.5,23.6z M3.4,23.4L3.7,23C3.5,23.1,3.4,23.3,3.4,23.4L3.4,23.4z M4.1,22.8L4.1,22.8c-0.3,0.3-0.4,0.5-0.5,0.8
                    c-0.1,0.2-0.1,0.6-0.2,1L4.1,22.8z M10.2,7.8c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.4-0.4,0.9-0.8,1.4-1.1
                    c0.5-0.3,1-0.7,1.5-1c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.3-0.2,0.3-0.4l-0.2,0l-0.2,0.1c-0.1,0-0.4,0.1-0.9,0.4
                    c-0.4,0.3-0.9,0.6-1.3,1c-0.5,0.4-0.9,0.7-1.2,1.1C10.4,7.5,10.2,7.8,10.2,7.8C10.2,7.9,10.2,7.9,10.2,7.8z M11.2,6.6
                    c-0.1,0-0.3,0.2-0.5,0.4c-0.2,0.3-0.4,0.4-0.5,0.5c0,0,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.2,0.3-0.3C10.8,7,10.9,6.9,11,6.8
                    C11.1,6.7,11.1,6.6,11.2,6.6z M13.3,6.3C13.2,6.7,13,7,12.7,7.2c-0.3,0.2-0.5,0.4-0.7,0.7C12.3,7.6,12.6,7.3,13,7
                    c0.4-0.3,0.7-0.6,1-1l-0.2,0.2l-0.2,0.2l0-0.1l0-0.1h-0.1L13.3,6.3L13.3,6.3z M12.9,24.1c0,0-0.1,0.1-0.2,0.2
                    c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.2,0.3-0.3,0.4c-0.1,0.1-0.1,0.2-0.2,0.3l0.2-0.1c0.2-0.2,0.3-0.4,0.5-0.6
                    C12.7,24.6,12.9,24.3,12.9,24.1z M12.8,23.7c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.1-0.1,0.2-0.1,0.3c0-0.1,0.1-0.2,0.2-0.3
                    C12.7,23.8,12.8,23.7,12.8,23.7z M14.2,8.1c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.1,0.3-0.3,0.5-0.4c0.2-0.1,0.3-0.2,0.5-0.4
                    s0.3-0.3,0.4-0.4C15.1,6.8,14.5,7.3,14,8c-0.5,0.6-0.9,1.3-1.4,2c0.2-0.2,0.4-0.4,0.5-0.6s0.3-0.4,0.5-0.6c0-0.1,0.1-0.2,0.2-0.4
                    C14,8.2,14.1,8.1,14.2,8.1z M12.7,6.9c0.1,0,0.1-0.1,0.2-0.2L12.7,6.9z M15.3,6.2c-0.1,0-0.2,0.1-0.5,0.4S14.3,7,14,7.2
                    c-0.3,0.3-0.5,0.5-0.7,0.8C13.1,8.3,13,8.4,13,8.5v0.1c0.5-0.5,0.8-0.8,1.1-1.1s0.5-0.6,0.8-0.8C15.1,6.4,15.2,6.2,15.3,6.2z
                    M14.3,24.5c0,0-0.1,0.1-0.1,0.1c0,0.1-0.1,0.1-0.1,0.2l0.2-0.2L14.3,24.5z M17.1,23.8c-0.3,0.5-0.7,1-1,1.4
                    c-0.3,0.5-0.7,1-0.9,1.5c0.1-0.1,0.3-0.3,0.5-0.5c0.2-0.2,0.4-0.5,0.7-0.8s0.4-0.6,0.5-0.8C17,24.3,17.1,24,17.1,23.8L17.1,23.8z
                    M16.1,24.1c-0.1,0.2-0.3,0.5-0.5,0.7c-0.2,0.2-0.3,0.5-0.5,0.7c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.3,0.3-0.4
                    c0.1-0.2,0.2-0.3,0.2-0.4C16.1,24.4,16.1,24.3,16.1,24.1z M16.7,9.7c-0.1,0-0.1,0.1-0.2,0.2h0c0,0,0,0,0,0c0,0,0,0,0,0L16.7,9.7z
                    M17.7,22.5c-0.2,0.2-0.3,0.4-0.3,0.6c-0.1,0.2-0.1,0.4-0.2,0.6v0.1c0.1,0,0.3-0.1,0.3-0.3c0.1-0.2,0.1-0.3,0.2-0.4
                    C17.7,22.9,17.7,22.7,17.7,22.5L17.7,22.5z M17.3,10.3c0,0,0-0.1,0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.1,0.2-0.2,0.3-0.4
                    c0.1-0.1,0.2-0.2,0.2-0.2c0,0,0,0.1-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.2-0.3,0.3C17.4,10.2,17.4,10.3,17.3,10.3z
                    M17.7,21.9l0.1,0.1l0-0.2L17.7,21.9z M18.5,6.6L18.5,6.6l0.2,0.1l0-0.1L18.5,6.6z"/>
                <path d="M27.6,17.2c-0.4,0.6-0.8,1.2-1.3,1.8c-0.5,0.6-0.9,1.1-1.4,1.7l0.3-0.6c-0.2,0.2-0.3,0.4-0.5,0.7c-0.2,0.3-0.3,0.5-0.4,0.8
                    l-0.3,0.3L23.9,22l-0.3,0.2c0,0.1-0.1,0.3-0.2,0.4c-0.1,0.2-0.2,0.3-0.3,0.3l-0.1,0.2v0.1c-0.3,0.4-0.7,0.8-1,1.3
                    c-0.3,0.4-0.7,0.8-1.2,1.1v-0.1l-0.1,0.1v0.1c-0.2,0.1-0.5,0.2-0.8,0.3c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0-0.3-0.1-0.5-0.2H19
                    c-0.1-0.2-0.1-0.5-0.1-0.8c0-0.3,0-0.6,0-0.9c0-0.6,0.1-1.3,0.3-2.1c0.2-0.8,0.4-1.7,0.7-2.6c0.3-0.9,0.6-1.9,0.9-2.9
                    c0.3-1,0.7-1.9,1-2.9c0.3-0.9,0.7-1.8,1-2.6c0.3-0.8,0.6-1.5,0.8-2.2c0.1-0.2,0.2-0.5,0.3-0.9c0.2-0.4,0.4-0.8,0.6-1.3
                    c0.2-0.4,0.4-0.8,0.7-1.1s0.4-0.5,0.6-0.5l0.1,0.1c0,0-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.1,0.2c0,0,0,0,0,0.1c0,0,0,0,0,0.1
                    l-0.1,0l-0.1,0.2l-0.2,0.2l0.1,0.1l0.2,0.1l0.1-0.1c0-0.1,0.1-0.3,0.3-0.5c0.1-0.2,0.2-0.3,0.4-0.3c0,0,0.1,0,0.1,0.1
                    c0,0,0,0.1,0,0.1v0.1l0.2,0.1l0.1-0.1c0.1,0,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.2,0.1,0.4c0,0.1,0.1,0.2,0.1,0.4c0,0.1,0,0.2,0,0.3
                    c0,0.2,0,0.5-0.1,0.7c0,0.2-0.1,0.5-0.1,0.7c0,0.1,0,0.2,0,0.3L27,8l0,0C27,8.1,27,8.2,27,8.3c0,0.1,0,0.2,0,0.3c0,0-0.1,0-0.1,0.1
                    c-0.1,0-0.1,0.1-0.1,0.1c0,0.1,0,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.4-0.1,1-0.3,1.8c-0.2,0.7-0.4,1.5-0.7,2.3
                    c-0.3,0.8-0.5,1.6-0.8,2.4c-0.3,0.8-0.5,1.4-0.6,1.9c0.3-0.3,0.7-0.8,1.2-1.3c0.5-0.5,1-1.1,1.6-1.6c0.5-0.5,1.1-1,1.7-1.4
                    c0.6-0.4,1.1-0.6,1.6-0.6c0.3,0,0.6,0.1,0.9,0.3c0.3,0.2,0.5,0.5,0.7,0.8c0.2,0.3,0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1
                    c0,0.2-0.1,0.6-0.2,1.1c-0.2,0.6-0.4,1.2-0.6,2c-0.3,0.7-0.6,1.5-0.9,2.4c-0.3,0.8-0.6,1.7-0.9,2.4s-0.5,1.5-0.8,2.1
                    c-0.2,0.6-0.4,1.1-0.5,1.4c0-0.6,0.1-1.2,0.3-2c0.2-0.8,0.5-1.6,0.8-2.4c0.3-0.8,0.6-1.6,1-2.4c0.3-0.8,0.6-1.5,0.9-2
                    c-0.4,0.7-0.7,1.4-1,2.1c-0.3,0.7-0.6,1.4-0.9,2.1c-0.2,0.5-0.4,1-0.6,1.6c-0.2,0.5-0.4,1-0.5,1.6c0,0.2-0.1,0.4-0.1,0.6
                    c0,0.2-0.1,0.4-0.1,0.6l-0.1,0.5l-0.1-0.1l-0.2,0l-0.5,0.3c-0.5-0.1-1-0.4-1.3-0.8c-0.3-0.4-0.5-0.9-0.5-1.5c0-0.7,0.1-1.4,0.3-2.2
                    c0.2-0.8,0.4-1.6,0.7-2.4c0.3-0.8,0.6-1.6,0.9-2.4c0.3-0.8,0.6-1.5,0.9-2.1l-0.5,0.4c0.1-0.1,0.1-0.3,0.2-0.4
                    c0.1-0.1,0.2-0.2,0.3-0.3c-0.6,0.6-1.2,1.2-1.8,1.9s-1.2,1.4-1.6,2.1c0.1-0.1,0.3-0.3,0.5-0.6c0.2-0.3,0.4-0.5,0.7-0.8
                    c0.2-0.3,0.4-0.6,0.7-0.8S27.5,17.2,27.6,17.2z M19.4,25.1c0.1-0.1,0.2-0.3,0.2-0.4c0-0.2,0.1-0.3,0.1-0.5c0.4-2.2,1-4.3,1.7-6.4
                    c0.7-2.1,1.3-4.2,2-6.3c-0.2,0.5-0.4,1.1-0.7,1.8c-0.3,0.7-0.5,1.5-0.8,2.3c-0.3,0.8-0.6,1.7-0.9,2.5c-0.3,0.9-0.6,1.7-0.8,2.5
                    c-0.2,0.8-0.4,1.6-0.6,2.2c-0.2,0.7-0.2,1.2-0.2,1.7V25.1z M20.8,25.7L20.8,25.7h-0.1H20.8L20.8,25.7z M22.7,23L22.7,23l-0.1,0
                    L22.7,23L22.7,23z M24.3,12.2c-0.1,0.1-0.2,0.3-0.3,0.7c-0.1,0.4-0.3,0.8-0.4,1.3c-0.2,0.5-0.3,0.9-0.4,1.3s-0.2,0.7-0.3,0.9
                    c0.3-0.7,0.6-1.3,0.8-2C24,13.6,24.2,12.9,24.3,12.2z M23.3,11.5L23.3,11.5l0.1-0.3l0,0L23.3,11.5z M23.5,17.8
                    c0.4-1,0.8-2.1,1.1-3.1c0.3-1,0.6-2.1,1-3.2c-0.4,1-0.8,2.1-1.1,3.1C24.1,15.7,23.8,16.8,23.5,17.8z M23.9,18.4
                    c0.3-0.6,0.6-1.2,0.7-1.9c0.2-0.7,0.4-1.4,0.6-2c0.3-0.9,0.6-1.8,0.9-2.7c0.3-0.9,0.5-1.8,0.7-2.7L26.8,9l-0.2,0.3
                    c-0.1,0.5-0.2,1.1-0.4,1.6c-0.2,0.5-0.4,1.1-0.5,1.6c-0.3,1-0.6,2-0.9,2.9C24.4,16.4,24.1,17.4,23.9,18.4L23.9,18.4z M23.9,19.3
                    c0,0,0.1-0.1,0.4-0.4c0.2-0.2,0.5-0.5,0.8-0.9c0.3-0.3,0.6-0.7,1-1.1c0.4-0.4,0.7-0.8,1-1.2s0.6-0.7,0.9-1c0.2-0.3,0.4-0.5,0.5-0.6
                    c-0.3,0.2-0.7,0.5-1.1,0.9s-0.9,0.9-1.4,1.4c-0.5,0.5-0.9,1-1.3,1.5C24.3,18.6,24.1,19,23.9,19.3z M26.2,18.2L26.2,18.2l0,0.2
                    L26.2,18.2L26.2,18.2z M26.2,6L26.2,6L26.2,6l0.1-0.1L26.2,6z M26.7,17.6c-0.1,0.1-0.2,0.2-0.2,0.3l0.2-0.2L26.7,17.6z M27.1,7.7
                    c0,0,0-0.1,0-0.2L27.1,7.7z M27.1,23.4c0-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.1-0.4,0.1-0.6v0c-0.1,0.2-0.1,0.4-0.2,0.6
                    C27.2,23,27.2,23.2,27.1,23.4z M27.6,16.3c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0.1,0.1l0-0.1v-0.1L27.6,16.3z M28,16.8
                    c0,0.1,0,0.1-0.1,0.2s-0.1,0.1-0.2,0.1c0,0,0.1-0.1,0.1-0.2S27.9,16.8,28,16.8z M27.9,24.8c-0.1,0-0.1,0.1,0,0.2V24.8z M28.8,21.7
                    c-0.2,0.5-0.3,0.9-0.5,1.4c-0.2,0.5-0.3,1-0.4,1.5c0,0,0.1-0.2,0.2-0.5c0.1-0.3,0.2-0.6,0.3-0.9c0.1-0.3,0.2-0.7,0.3-1
                    C28.8,21.9,28.8,21.7,28.8,21.7z M28.3,15.9L28.1,16l0.1,0L28.3,15.9z M28.3,18.8l0.2,0l0.1-0.1l-0.1-0.1L28.3,18.8z M30.8,19.4
                    c-0.5,1.1-0.9,2.3-1.4,3.5c-0.5,1.2-0.8,2.4-0.9,3.6c0.3-1.2,0.7-2.4,1.1-3.6C30.1,21.8,30.4,20.6,30.8,19.4z M28.6,18L28.6,18
                    L28.6,18l0.2,0L28.8,18L28.6,18z M29.4,17.1l0.1,0.2L29.4,17.1L29.4,17.1z M32.3,17.4L32.3,17.4h0.1H32.3l0-0.1L32.3,17.4z
                    M32.4,16.9L32.4,16.9L32.4,16.9v-0.1V16.9z M32.8,15.5c-0.1,0.2-0.1,0.4-0.2,0.6s-0.1,0.4-0.1,0.6c0,0,0.1-0.1,0.1-0.2
                    c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0.1-0.3,0.1-0.4C32.8,15.6,32.8,15.5,32.8,15.5L32.8,15.5z"/>
                <path d="M32.3,22.9c0-0.2,0-0.4,0-0.6c0-0.2,0-0.4,0.1-0.6c0.3-1,0.6-2,0.9-2.9c0.3-0.9,0.8-1.8,1.5-2.7c0.3-0.4,0.7-0.9,1.2-1.3
                    c0.4-0.4,0.9-0.8,1.4-1.1c0.5-0.3,1-0.6,1.6-0.8c0.6-0.2,1.1-0.3,1.7-0.3c0.7,0,1.3,0.2,1.7,0.5c0.4,0.4,0.8,0.8,1.1,1.4
                    c0.1-0.1,0.3-0.2,0.5-0.4c0.2-0.2,0.4-0.3,0.6-0.4c0.2-0.1,0.4-0.3,0.6-0.4c0.2-0.1,0.4-0.2,0.5-0.2c0.1,0,0.1,0,0.1,0
                    c0,0.1,0,0.1-0.1,0.2c0,0.1-0.1,0.1-0.1,0.1s0,0,0,0s0,0,0,0c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0c0,0,0,0.1-0.1,0.1
                    c0,0.1,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0l0.3,0.1l0.1,0.3c-0.1,0.3-0.2,0.5-0.3,0.8s-0.2,0.5-0.4,0.7l0.1-0.1
                    c-0.2,0.7-0.5,1.4-0.8,2.2c-0.4,0.8-0.7,1.7-1.1,2.5c-0.4,0.9-0.7,1.7-1,2.5c-0.3,0.8-0.4,1.5-0.4,2.1c0,0.2,0,0.4,0.1,0.5
                    s0.1,0.2,0.2,0.3s0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.1,0.3c-0.2,0.3-0.6,0.4-0.9,0.5c-0.4,0.1-0.8,0.1-1.1,0.1
                    c-0.4,0-0.7-0.1-1-0.3c-0.2-0.2-0.4-0.4-0.6-0.7c-0.1-0.3-0.2-0.6-0.3-0.9c0-0.3-0.1-0.7-0.1-1c0-0.2,0-0.3,0-0.4
                    c0-0.1,0-0.3,0-0.4c-0.2,0.2-0.5,0.5-0.8,0.9c-0.3,0.3-0.7,0.7-1,1c-0.4,0.3-0.7,0.6-1.1,0.9c-0.4,0.2-0.7,0.4-1.1,0.4
                    c-0.4,0-0.8,0-1.1-0.1c-0.3,0-0.6-0.1-0.8-0.3c-0.2-0.1-0.4-0.3-0.6-0.5c-0.2-0.2-0.4-0.5-0.6-0.9c0-0.2,0-0.4,0-0.6
                    C32.3,23.4,32.3,23.2,32.3,22.9z M35.4,16.9h-0.2l0,0L35.4,16.9z M35.3,21.8L35.3,21.8L35.3,21.8L35.3,21.8L35.3,21.8z M35.5,23
                    c0.2-0.2,0.3-0.4,0.4-0.6c0.1-0.3,0.2-0.5,0.2-0.7l-0.6,0.9L35.5,23z M42,15.8c-0.5,0-1.1,0.2-1.6,0.7c-0.5,0.4-1,1-1.5,1.6
                    s-0.9,1.2-1.3,1.8c-0.4,0.6-0.8,1.1-1.1,1.5c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.2,0.4-0.3,0.7s-0.2,0.5-0.3,0.7
                    c-0.1,0.2-0.1,0.4-0.1,0.5v0c0.2,0,0.5-0.2,0.9-0.5c0.4-0.3,0.9-0.7,1.4-1.3c0.5-0.5,1-1.1,1.5-1.7s1-1.2,1.5-1.8
                    c0.4-0.6,0.8-1.1,1.1-1.6c0.3-0.5,0.4-0.8,0.4-1c0-0.1,0-0.2-0.1-0.2C42.1,15.8,42,15.8,42,15.8z M35.6,16.6L35.6,16.6v0.2V16.6
                    L35.6,16.6z M39.6,13.7c-0.3,0-0.6,0.1-0.9,0.3c-0.3,0.2-0.7,0.4-1,0.6c-0.3,0.2-0.6,0.5-0.9,0.8c-0.3,0.3-0.5,0.5-0.6,0.7
                    c0.1,0,0.2-0.1,0.3-0.2s0.3-0.3,0.5-0.4s0.3-0.3,0.5-0.4c0.2-0.1,0.3-0.2,0.4-0.3c0.1,0,0.2-0.1,0.4-0.2c0.2-0.1,0.4-0.2,0.5-0.3
                    c0.2-0.1,0.4-0.2,0.5-0.3S39.5,13.7,39.6,13.7z M37.3,14.7c-0.1,0-0.2,0.1-0.4,0.3c-0.2,0.2-0.3,0.2-0.3,0.3L37.3,14.7z M41.5,15.6
                    c-0.6,0.2-1.2,0.5-1.6,0.8s-0.9,0.7-1.3,1.1c-0.4,0.4-0.7,0.9-1,1.4c-0.3,0.5-0.6,1-0.9,1.6c0.1-0.2,0.3-0.4,0.4-0.6
                    s0.2-0.4,0.4-0.6c0.3-0.5,0.7-0.9,0.9-1.3c0.3-0.3,0.6-0.6,0.9-0.9c0.3-0.2,0.6-0.5,1-0.7C40.6,16.2,41,15.9,41.5,15.6z M36.8,23.5
                    c0.1,0,0.2-0.1,0.4-0.3c0.2-0.2,0.4-0.4,0.7-0.7c0.3-0.3,0.5-0.6,0.9-0.9c0.3-0.3,0.6-0.6,0.8-0.9c0.2-0.3,0.5-0.5,0.6-0.8
                    c0.2-0.2,0.3-0.4,0.3-0.4l0,0c-0.2,0.2-0.5,0.5-0.9,0.8s-0.7,0.7-1,1.1s-0.7,0.8-1,1.1C37.3,22.9,37,23.2,36.8,23.5z M37.4,16.6
                    c0.1,0,0.2-0.1,0.3-0.2c0.2-0.1,0.3-0.3,0.5-0.4c0.2-0.2,0.4-0.3,0.5-0.4c0.2-0.1,0.3-0.3,0.4-0.3c-0.3,0.2-0.7,0.4-0.9,0.6
                    C37.9,16,37.6,16.3,37.4,16.6z M37.5,18.2c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1-0.1,0.1-0.2c0.4-0.5,0.9-0.9,1.4-1.3
                    c0.5-0.4,1-0.8,1.5-1.3c-0.2,0-0.5,0.1-0.8,0.4c-0.3,0.2-0.6,0.5-0.9,0.8c-0.3,0.3-0.6,0.6-0.9,0.9C37.8,17.8,37.6,18,37.5,18.2z
                    M39.5,22.6c0,0-0.1,0.1-0.2,0.2c-0.1,0.2-0.3,0.3-0.5,0.5s-0.3,0.4-0.5,0.6c-0.2,0.2-0.3,0.4-0.4,0.5c0.3-0.3,0.6-0.6,0.8-0.9
                    C39,23.2,39.2,22.9,39.5,22.6z M39.6,22.1c-0.2,0.2-0.4,0.4-0.5,0.6c-0.2,0.2-0.3,0.4-0.5,0.6c0.1-0.1,0.1-0.1,0.3-0.2
                    c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.2,0.3-0.4C39.5,22.2,39.6,22.1,39.6,22.1z M39.8,21.1l-0.1,0.1l0,0.1l0.1,0L39.8,21.1
                    L39.8,21.1z M40.3,22.1c0,0.1,0,0.1,0,0.2L40.3,22.1z M40.8,21.2c-0.1,0.3-0.2,0.5-0.3,0.8c-0.1,0.3-0.1,0.5-0.2,0.8
                    c0.1-0.2,0.2-0.5,0.3-0.8C40.7,21.8,40.8,21.5,40.8,21.2L40.8,21.2z M41,20.7L41,20.7l-0.1,0.2L41,20.7z M43.3,18.1
                    c-0.2,0.3-0.4,0.8-0.6,1.3c-0.2,0.5-0.4,1-0.7,1.6s-0.4,1.1-0.5,1.6c-0.1,0.5-0.2,1-0.2,1.3V24c0.1-0.1,0.1-0.2,0.1-0.4
                    c0-0.1,0-0.3,0.1-0.4c0.2-0.9,0.4-1.7,0.8-2.6C42.6,19.7,43,18.9,43.3,18.1z M42.1,24.8c0.2-1,0.5-2,0.8-2.9c0.3-1,0.7-1.9,1-2.9
                    c-0.4,1-0.8,1.9-1.1,2.9C42.5,22.8,42.3,23.8,42.1,24.8z M43,16.6c0,0-0.1,0.1-0.2,0.2c-0.1,0.1-0.1,0.2-0.2,0.4s-0.1,0.2-0.2,0.4
                    s-0.1,0.2-0.1,0.2c0.1-0.1,0.2-0.3,0.4-0.6C42.8,16.9,42.9,16.7,43,16.6z M42.5,16.9c-0.1,0.1-0.1,0.1-0.2,0.2
                    c-0.1,0.1-0.1,0.1-0.1,0.2l0.2-0.2L42.5,16.9z M42.6,24.8c0.4-1.6,0.9-3.1,1.6-4.6c0.6-1.5,1.3-3,1.8-4.5c-0.2,0.5-0.5,1.1-0.9,1.9
                    c-0.4,0.8-0.8,1.7-1.2,2.6s-0.7,1.7-1,2.6c-0.3,0.8-0.4,1.5-0.4,2L42.6,24.8L42.6,24.8z M43.5,17.8c0-0.1,0.1-0.1,0.1-0.2
                    c0-0.1,0-0.2,0-0.3l-0.2,0.3V17.8z M45.4,13.8c-0.1,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.3,0.3-0.5,0.5s-0.3,0.4-0.4,0.5
                    c-0.1,0.2-0.2,0.3-0.3,0.4h0.2c0.2-0.3,0.5-0.5,0.8-0.8C45,14.4,45.2,14.1,45.4,13.8z M43.9,16.9c0,0,0.1-0.1,0.2-0.3
                    c0.1-0.2,0.1-0.3,0.2-0.4v-0.1c-0.1,0-0.2,0.1-0.3,0.3C44,16.6,44,16.8,43.9,16.9z M44.5,17.8l-0.1,0.2l0.1-0.1L44.5,17.8z
                    M44.6,17.6l-0.1,0.1l0,0.1L44.6,17.6L44.6,17.6z M45.5,14.5c0,0-0.1,0.1-0.2,0.2c-0.1,0.1-0.2,0.2-0.3,0.4s-0.2,0.3-0.3,0.4
                    c-0.1,0.1-0.1,0.2-0.2,0.3c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.1,0.2-0.3,0.2-0.4
                    C45.4,14.6,45.5,14.6,45.5,14.5z M46.6,14.1c-0.1,0-0.2,0.1-0.3,0.4C46.1,14.7,46,14.9,46,15C46.3,14.7,46.5,14.4,46.6,14.1z
                    M46.7,14.5c0,0.1-0.1,0.2-0.2,0.5c-0.1,0.2-0.2,0.4-0.3,0.4L46.7,14.5z"/>
                <path d="M62.1,6.4c0.1,0.1,0.1,0.2,0.1,0.4v0l-0.4,0.5l0.2,0C62,7.5,62,7.7,62,7.9c0,0.2,0,0.4-0.1,0.6c0,0.1-0.1,0.3-0.2,0.5
                    c-0.1,0.2-0.2,0.4-0.2,0.7s-0.1,0.5-0.2,0.7c-0.1,0.2-0.1,0.4-0.1,0.4L61,11l0.1,0.1c-0.3,1.1-0.7,2.1-1.1,3.1
                    c-0.4,1-0.9,2.1-1.3,3.1c-0.4,1-0.8,2.1-1.1,3.1c-0.3,1.1-0.6,2.1-0.8,3.2l0,0.1c0,0,0.1-0.2,0.2-0.4c0.1-0.2,0.2-0.5,0.3-0.9
                    c0.1-0.3,0.2-0.7,0.4-1.1s0.3-0.7,0.4-1.1c0.1-0.3,0.2-0.6,0.3-0.8c0.1-0.2,0.1-0.3,0.1-0.4l0.1-0.2c0.1-0.3,0.2-0.5,0.3-0.8
                    c0.1-0.2,0.2-0.5,0.3-0.8c0-0.2,0.1-0.5,0.3-0.9c0.1-0.4,0.3-0.8,0.4-1.3c0.2-0.4,0.3-0.9,0.5-1.3c0.2-0.4,0.3-0.6,0.4-0.8l0-0.2
                    c0-0.1,0-0.2,0.1-0.4s0.2-0.2,0.3-0.3c0,0.1,0,0.2,0,0.3c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.2-0.1,0.5-0.2,1.1c-0.2,0.5-0.4,1.2-0.7,1.9
                    c-0.3,0.7-0.6,1.5-0.9,2.3c-0.3,0.8-0.6,1.7-0.9,2.5c-0.3,0.8-0.5,1.6-0.7,2.3c-0.2,0.7-0.3,1.3-0.3,1.7c0,0,0,0.2,0,0.3
                    s0.1,0.3,0.2,0.3c0.2,0,0.4-0.1,0.7-0.4s0.6-0.5,0.8-0.8s0.5-0.6,0.7-0.8c0.2-0.2,0.4-0.4,0.4-0.4v0c0,0,0,0.1,0,0.1
                    c0,0.1,0,0.1,0,0.1c0,0,0.1,0,0.1-0.1c0.1-0.1,0.1-0.1,0.2-0.1c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.1,0.2,0.2l-0.1,0.2l-0.1,0.1
                    c-0.2,0.4-0.4,0.8-0.7,1.2c-0.3,0.4-0.6,0.7-0.9,1.1c-0.3,0.3-0.7,0.5-1.1,0.7c-0.4,0.2-0.9,0.3-1.4,0.3c-0.5,0-0.9-0.1-1.2-0.3
                    s-0.5-0.5-0.7-0.8c-0.2-0.3-0.3-0.7-0.5-1.1c-0.1-0.4-0.3-0.8-0.5-1.1c-0.1,0.2-0.3,0.5-0.5,0.7c-0.2,0.2-0.4,0.4-0.6,0.5
                    c-0.2,0.2-0.4,0.3-0.7,0.5c-0.2,0.2-0.4,0.4-0.6,0.6c-0.3,0.1-0.6,0.2-1,0.2s-0.7,0-1,0c-0.5,0-0.9-0.1-1.4-0.3
                    c-0.4-0.2-0.8-0.5-1.1-0.8c-0.3-0.3-0.5-0.7-0.7-1.2c-0.2-0.4-0.2-0.9-0.2-1.4c0-1,0.3-2,0.8-3.2c0.5-1.1,1.2-2.2,2.1-3.2
                    s1.8-1.9,2.8-2.5c1-0.7,2.1-1,3.1-1c0.2,0,0.5,0.1,0.9,0.2c0.4,0.1,0.7,0.3,0.8,0.4c0.1-0.2,0.2-0.6,0.4-1c0.2-0.5,0.5-1,0.8-1.5
                    c0.3-0.5,0.6-1.1,1-1.6c0.4-0.6,0.7-1.1,1.1-1.5c0.3-0.5,0.7-0.8,1-1.1c0.3-0.3,0.6-0.4,0.9-0.4l0.1,0.1c-0.1,0.2-0.3,0.4-0.5,0.5
                    l-0.2,0.4V6.4c-0.1,0.2-0.3,0.4-0.4,0.6c-0.1,0.2-0.3,0.4-0.5,0.6c0,0,0.1,0,0.2,0c0.1,0,0.1,0,0.1,0.1l0.3-0.4V7.2l0.2-0.1
                    l0.9-0.9l0.2,0.3l0.2-0.4L62,6.1l0,0l-0.1,0.2L62.1,6.4z M47.1,19.8L47.1,19.8L47,19.9L47,20L47.1,19.8z M47.4,19.4
                    c0.1-0.1,0.2-0.3,0.3-0.5c0.1-0.3,0.3-0.5,0.4-0.8s0.3-0.5,0.5-0.7c0.2-0.2,0.3-0.4,0.3-0.4c0.1-0.1,0.2-0.4,0.4-0.6
                    c0.2-0.3,0.5-0.6,0.7-0.9c0.3-0.3,0.5-0.5,0.7-0.8s0.3-0.4,0.3-0.4v0c-0.2,0-0.4,0.2-0.8,0.6c-0.3,0.4-0.7,0.8-1.1,1.3
                    c-0.4,0.5-0.7,1-1,1.4c-0.3,0.5-0.5,0.8-0.6,1v-0.1c0-0.1,0-0.2,0-0.2l-0.2,0.2l0,0.2h0.1c0,0.1-0.1,0.2-0.1,0.4
                    c0,0.1-0.1,0.2-0.2,0.3l0,0.3l0,0.1L47.4,19.4L47.4,19.4z M47.7,18.1c0.1-0.1,0.1-0.2,0.1-0.3L47.7,18.1z M48.4,19.7L48.4,19.7
                    l-0.2,0l0.1,0.1L48.4,19.7z M48.7,19c0.3-0.4,0.7-0.9,1-1.4c0.3-0.5,0.6-1,0.8-1.5c-0.1,0-0.2,0.1-0.4,0.4
                    c-0.2,0.3-0.4,0.6-0.6,0.9c-0.2,0.3-0.4,0.7-0.5,1C48.8,18.7,48.8,18.9,48.7,19z M50.4,19c-0.1,0.1-0.1,0.2-0.2,0.5
                    c-0.1,0.2-0.2,0.5-0.2,0.8c-0.1,0.3-0.2,0.5-0.2,0.8c-0.1,0.3-0.1,0.5-0.2,0.6c0.2-0.3,0.3-0.7,0.5-1.1s0.2-0.7,0.4-1.1
                    c0.1-0.4,0.3-0.7,0.4-1.1c0.2-0.3,0.4-0.7,0.6-1c0.3-0.4,0.6-0.7,0.9-1.1c0.3-0.4,0.6-0.7,0.9-1.1c-0.2,0.1-0.4,0.4-0.7,0.7
                    c-0.3,0.3-0.6,0.7-0.9,1.1s-0.5,0.8-0.8,1.1C50.4,18.5,50.3,18.8,50.4,19z M49.7,23.9c0.2,0,0.6-0.2,1-0.6c0.4-0.4,0.9-0.8,1.3-1.3
                    c0.4-0.5,0.8-0.9,1.2-1.4c0.3-0.5,0.5-0.8,0.6-1c0-0.2,0.2-0.7,0.3-1.2c0.2-0.6,0.4-1.2,0.6-1.8c0.2-0.6,0.4-1.1,0.5-1.6
                    c0.2-0.5,0.2-0.7,0.2-0.8v-0.1c-0.2,0-0.4,0.1-0.5,0.1c-0.2,0.1-0.4,0.1-0.5,0.1c-0.6,0.4-1.2,0.9-1.8,1.6s-1.1,1.5-1.5,2.4
                    c-0.4,0.9-0.8,1.7-1.1,2.6c-0.3,0.9-0.4,1.7-0.4,2.4c0,0.1,0,0.2,0,0.3S49.7,23.8,49.7,23.9z M52,15.7c-0.1,0-0.3,0.2-0.5,0.5
                    s-0.5,0.7-0.7,1.1c-0.2,0.4-0.5,0.8-0.7,1.2c-0.2,0.4-0.3,0.7-0.4,0.8c0.1-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.2,0.1-0.2
                    c0.3-0.6,0.6-1.1,0.9-1.7C51.2,16.7,51.6,16.2,52,15.7L52,15.7z M52.3,22.4c0.2-0.2,0.5-0.5,0.7-0.8c0.3-0.3,0.4-0.6,0.5-0.9
                    c-0.1,0-0.2,0.1-0.3,0.3c-0.1,0.2-0.2,0.3-0.4,0.5s-0.3,0.3-0.4,0.5C52.5,22.2,52.4,22.3,52.3,22.4z M55.1,17.8L55.1,17.8l0,0.2
                    L55.1,17.8L55.1,17.8z M55.3,17.7c0.1-0.1,0.2-0.2,0.2-0.2l-0.2,0l-0.1,0.1L55.3,17.7z M55.6,16.8l0.1-0.2l-0.1-0.1V16.8L55.6,16.8
                    z M55.8,16.2l0-0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0.1,0,0.1l0,0.1L55.8,16.2z M55.9,15.9c0.1,0,0.2,0,0.2-0.1c0.1-0.1,0.1-0.1,0.2-0.1
                    l-0.2,0.1l-0.1-0.1v-0.3H56l0,0.2c-0.1,0.1-0.1,0.2-0.2,0.2L55.9,15.9z M56,20.2l-0.2,0.1l0,0.1L56,20.2L56,20.2z M56.2,19.5
                    L56.2,19.5l0,0.1L56.2,19.5z M56.4,23c0.1-0.5,0.3-1.1,0.5-1.6c0.2-0.5,0.4-1,0.6-1.6c0.2-0.5,0.4-1,0.6-1.6
                    c0.2-0.5,0.3-1.1,0.4-1.6c-0.4,1-0.9,2-1.3,3.1S56.5,22,56.4,23z M56.5,18.9L56.5,18.9L56.4,19l0.1,0L56.5,18.9z M57,13.4l0.4-0.3
                    c-0.1-0.1-0.1-0.2-0.1-0.2v0L57,12.9l-0.1,0.1v0.1l-0.1,0.2l0.1,0.2L57,13.4z M57.2,13.6l0.1-0.2v-0.2c-0.1,0.1-0.2,0.2-0.2,0.4
                    l0,0.2l0.1-0.1V13.6z M57.2,12.5L57.2,12.5l0.1,0L57.2,12.5L57.2,12.5L57.2,12.5z M57.4,12c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.1
                    L57.4,12z M57.5,15.4l0,0.1L57.5,15.4L57.5,15.4L57.5,15.4z M57.6,15.6l-0.1,0.2h0L57.6,15.6L57.6,15.6z M57.8,14.8L57.8,14.8
                    L57.8,14.8l0.1,0.1v0L57.8,14.8z M58.2,13.6l0.1,0C58.3,13.6,58.3,13.6,58.2,13.6C58.3,13.6,58.3,13.6,58.2,13.6L58.2,13.6
                    L58.2,13.6z M58.4,13.1L58.4,13.1l0.2,0.1l0,0v-0.2L58.4,13.1L58.4,13.1z M58.6,16.4l-0.1,0.1l0,0.1L58.6,16.4L58.6,16.4z M59,12.6
                    l-0.2,0.1v0l0.1,0L59,12.6z M58.9,12.4L58.9,12.4l0-0.2L58.9,12.4L58.9,12.4z M59.1,15l0-0.1l-0.1-0.1L59.1,15L59.1,15z M59.3,11.3
                    l-0.1-0.1l-0.1,0.1L59.3,11.3L59.3,11.3z M59.5,10.8l-0.1,0.1l0.1,0.1L59.5,10.8L59.5,10.8z M59.8,10.2L59.8,10.2L59.8,10.2
                    l-0.1,0.2L59.8,10.2z M60.2,7.3L60.2,7.3l0,0.2L60.2,7.3L60.2,7.3z M60.2,9.2l-0.2-0.1L60.2,9.2L60.2,9.2z M60.2,11.5
                    C60.2,11.5,60.2,11.5,60.2,11.5v-0.2V11.5z M60.4,11c-0.1,0-0.1,0.1-0.1,0.2v0L60.4,11L60.4,11z M61.4,7.2c0-0.1,0-0.2,0-0.2
                    c0-0.1,0.1-0.2,0.1-0.2c-0.2,0.2-0.4,0.4-0.5,0.7c-0.2,0.2-0.2,0.5-0.2,0.8v0c0-0.1,0.1-0.1,0.1-0.2C60.9,7.9,61,7.8,61,7.8
                    c0,0,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2L61.4,7.2z M61,10.1L61,10.1l-0.2,0l0.1,0L61,10.1z M60.9,12.3c0-0.1,0.1-0.2,0.1-0.2l0.1-0.1
                    l0.1,0.1c0,0,0,0.1-0.1,0.2C61,12.3,61,12.3,60.9,12.3L60.9,12.3z M61,6.9L61,6.9L61,6.9L61,6.9L61,6.9z M61.3,11.1
                    c0,0.1,0,0.2-0.1,0.4c-0.1,0.1-0.1,0.2-0.1,0.3l0-0.1c0-0.1,0.1-0.2,0.1-0.3C61.3,11.3,61.3,11.2,61.3,11.1z M61.8,5.9L61.7,6
                    l-0.3,0.1L61.8,5.9z M61.5,9.3L61.5,9.3l-0.2,0L61.5,9.3L61.5,9.3z M61.7,7.8L61.7,7.8h0.2v0L61.7,7.8z M62.2,7.3L62.2,7.3
                    L62.2,7.3L62.2,7.3z"/>
                <path d="M64.2,26.3c-0.6,0-1.1-0.1-1.6-0.4s-0.9-0.6-1.3-1.1c-0.4-0.4-0.6-0.9-0.8-1.5c-0.2-0.5-0.3-1.1-0.3-1.6
                    c0-0.9,0.2-2,0.6-3.2c0.4-1.2,0.9-2.3,1.6-3.3c0.7-1,1.5-1.9,2.4-2.6s1.9-1.1,3-1.1c1,0,2,0.3,2.9,0.8c0.9,0.6,1.5,1.3,1.9,2.3
                    l-0.1,0.2c0.1,0.1,0.1,0.2,0.1,0.3c0,0.4-0.1,0.8-0.2,1.2c-0.1,0.4-0.2,0.8-0.2,1.2v0.1l0.1,0l0.1-0.2c0,0,0,0,0,0.1c0,0,0,0,0,0.1
                    c0,0.3,0,0.6-0.1,0.9c-0.1,0.3-0.2,0.7-0.3,0.9c-0.3,0.8-0.7,1.6-1.2,2.4s-1.1,1.5-1.8,2.2c-0.7,0.6-1.5,1.2-2.3,1.6
                    C66,26.1,65.1,26.3,64.2,26.3z M65.2,13.5c-0.1,0.2-0.3,0.5-0.5,0.7c-0.2,0.2-0.5,0.4-0.7,0.7c-0.3,0.2-0.5,0.5-0.8,0.9
                    c-0.2,0.3-0.4,0.8-0.6,1.3c0.1-0.1,0.1-0.1,0.2-0.2s0.1-0.1,0.1-0.2c0.4-0.6,0.8-1.1,1.3-1.6c0.5-0.5,0.9-1,1.4-1.5L65.2,13.5z
                    M62.9,15.8c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.1,0.3-0.2,0.3L62.9,15.8z M63.1,15.4L63.1,15.4L63.1,15.4L63.1,15.4L63.1,15.4z
                    M63.7,17.6c0.2-0.3,0.5-0.7,0.7-1.1c0.2-0.4,0.4-0.7,0.7-1.1c-0.1,0-0.2,0.1-0.3,0.3c-0.1,0.2-0.3,0.4-0.4,0.7
                    c-0.1,0.2-0.3,0.5-0.4,0.7S63.7,17.5,63.7,17.6z M64.5,19.2L64.5,19.2C64.4,19.2,64.4,19.2,64.5,19.2c-0.1,0-0.1,0-0.1,0.1
                    L64.5,19.2L64.5,19.2z M66.9,14.8c-0.5,0.6-1,1.2-1.3,1.8s-0.7,1.3-1,2l0,0h0l0.7-1.4c0.2-0.4,0.5-0.8,0.8-1.2
                    C66.5,15.7,66.7,15.3,66.9,14.8z M68.3,14.3c-0.3,0.1-0.7,0.4-1.1,0.9c-0.4,0.5-0.8,1.1-1.2,1.7c-0.4,0.6-0.7,1.2-1,1.9
                    c-0.3,0.6-0.4,1.1-0.4,1.4c0,0,0,0.1,0,0.1c0.2-0.4,0.3-0.8,0.4-1.2c0.1-0.4,0.3-0.8,0.5-1.2c0.3-0.7,0.8-1.3,1.3-1.8
                    C67.4,15.5,67.9,14.9,68.3,14.3z M69.8,15.4c0-0.3,0-0.6,0-0.8s0-0.5-0.1-0.8c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.4,0.2
                    c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.2-0.4,0.4-0.6,0.5c-0.2,0.2-0.3,0.3-0.4,0.4c-0.3,0.4-0.7,0.8-1,1.4c-0.3,0.6-0.6,1.2-0.9,1.8
                    c-0.3,0.6-0.5,1.3-0.7,1.9s-0.3,1.2-0.3,1.7c0,0.1,0,0.2,0,0.4c0,0.2,0,0.4,0,0.7c0,0.2,0.1,0.4,0.1,0.6c0.1,0.2,0.1,0.3,0.2,0.3
                    c0.1,0,0.1,0,0.2-0.1c0.1-0.1,0.2-0.1,0.2-0.1c0.7-0.5,1.3-1,1.7-1.6c0.4-0.6,0.7-1.3,1-2c0.3-0.7,0.5-1.4,0.7-2.1
                    c0.2-0.7,0.5-1.4,0.8-2.2V15.4z M67.8,22.6c0.1-0.1,0.3-0.3,0.4-0.5s0.3-0.5,0.5-0.8c0.2-0.3,0.3-0.6,0.4-0.9
                    c0.1-0.3,0.2-0.5,0.2-0.7l-0.1-0.2l-0.3,0.6l0.3-0.5c-0.2,0.5-0.4,1-0.7,1.5S68,22.1,67.8,22.6z M70,20c0,0.2-0.1,0.4-0.2,0.7
                    c-0.1,0.2-0.2,0.4-0.3,0.7v0c0,0,0.1-0.1,0.2-0.2c0.1-0.2,0.2-0.3,0.2-0.5c0.1-0.2,0.2-0.4,0.2-0.6c0.1-0.2,0.1-0.3,0.2-0.4L70,20z
                    M72.2,18c-0.1,0-0.1,0-0.1,0.1c0,0.1,0,0.1-0.1,0.2c-0.3,0.8-0.6,1.6-1,2.3c-0.4,0.7-0.8,1.5-1.1,2.3c0.2-0.3,0.4-0.6,0.6-1
                    c0.2-0.4,0.4-0.7,0.5-1.1c0.2-0.4,0.3-0.7,0.5-1c0.1-0.3,0.3-0.6,0.5-0.8c0-0.2,0.1-0.3,0.1-0.5S72.2,18.2,72.2,18z M71.1,19.6
                    c-0.2,0.4-0.4,0.8-0.6,1.3c-0.2,0.4-0.4,0.8-0.6,1.3c0.1,0,0.2-0.2,0.3-0.5s0.3-0.6,0.5-0.9s0.3-0.7,0.4-1c0.1-0.3,0.2-0.5,0.2-0.6
                    v0c0,0.1-0.1,0.2-0.2,0.3l0-0.1l-0.1,0L71.1,19.6z M70.4,19.4L70.4,19.4l0.2,0v-0.1L70.4,19.4L70.4,19.4z"/>
                <path d="M77.3,25.8c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.5,0.2-0.7,0.3s-0.5,0.1-0.8,0.2c-0.3,0-0.4,0.1-0.6,0.1
                    c-0.1,0-0.2,0-0.2-0.1l-0.2-0.1c-0.1,0-0.1,0-0.2-0.1C74,26.2,74,26.1,74,26c0-0.1-0.1-0.2-0.1-0.3s0-0.2,0-0.2
                    c-0.1-0.5-0.2-1-0.2-1.5c-0.1-0.5-0.1-1-0.1-1.5c0-0.2,0-0.6,0.1-1.2c0.1-0.6,0.1-1.2,0.2-2s0.2-1.5,0.3-2.3
                    c0.1-0.8,0.2-1.6,0.4-2.3c0.1-0.7,0.2-1.3,0.3-1.8s0.2-0.8,0.3-0.9c0.1-0.2,0.3-0.2,0.4-0.2c0.1,0,0.1,0,0.2,0.1l0.2,0
                    c0.1,0,0.3,0,0.5,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.4,0.3,0.5,0.5c0.2,0.2,0.2,0.3,0.2,0.5v0.1c0.1,0.1,0.2,0.3,0.4,0.5
                    c0.1,0.3,0.2,0.5,0.2,0.6c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.1,0.3c0,0.2-0.1,0.3-0.1,0.4c0,0.1,0,0.1,0,0.1h0l0.1,0.1
                    l-0.1,0.2l0.1,0l0.2,1c0,0,0,0.3-0.1,0.6s-0.1,0.8-0.1,1.3s-0.1,0.9-0.1,1.3s0,0.7,0,0.7c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0.1,0.2
                    v-0.1c0.2-0.3,0.4-0.6,0.6-0.9c0.2-0.3,0.5-0.6,0.7-0.9c0.7-0.9,1.2-1.9,1.4-2.9c0.2-1.1,0.4-2.2,0.5-3.3c0-0.1,0-0.2,0-0.4
                    c0-0.2,0-0.3,0-0.5c0-0.2,0-0.3,0-0.5c0-0.2,0-0.3,0.1-0.3c0-0.1,0.1-0.2,0.3-0.2s0.3-0.1,0.3-0.2c1,0.4,1.7,1,2.1,1.9
                    c0.4,0.8,0.6,1.8,0.7,2.7c0,0,0,0.1,0,0.2c0,0.1,0.1,0.2,0.1,0.3l-0.1,0.1v0.2l0.2,0l0,0v0.1c0,0,0,0,0,0c0,0.4,0,0.8-0.1,1.2
                    c-0.1,0.4-0.1,0.8-0.1,1.2v0.2c0.2-0.3,0.6-0.8,1.1-1.4s1-1.3,1.4-2.1c0.5-0.7,0.9-1.4,1.3-2.1c0.4-0.7,0.5-1.2,0.5-1.5
                    c0-0.1,0-0.2-0.1-0.3s-0.1-0.2-0.1-0.3l0-0.2l0-0.1c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2s0.3-0.1,0.4-0.1
                    c0.2,0,0.4,0.1,0.6,0.4s0.3,0.5,0.4,0.9c0.1,0.3,0.2,0.7,0.2,1c0.1,0.3,0.1,0.6,0.1,0.7c0,0.3-0.1,0.7-0.2,1
                    c-0.1,0.3-0.2,0.7-0.4,1c-0.2,0.3-0.3,0.6-0.4,1c-0.1,0.3-0.2,0.7-0.3,1c0.1-0.2,0.2-0.3,0.2-0.5c0.1-0.2,0.1-0.3,0.2-0.5
                    c-0.1,0.7-0.5,1.5-1,2.3c-0.5,0.8-1.2,1.7-1.9,2.5s-1.4,1.6-2.2,2.3c-0.7,0.7-1.4,1.3-1.9,1.8c-0.1,0.1-0.1,0.1-0.2,0.2l-0.1,0
                    l-0.6,0.4c-0.3,0-0.5,0.1-0.8,0.1c-0.2,0-0.5,0-0.8,0c-0.2,0-0.4,0-0.5-0.1l-0.2-0.4c0,0,0,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1
                    c-0.1,0-0.1-0.1-0.1-0.3c0-0.2,0-0.4,0-0.4c0-0.5,0-0.9,0.1-1.4c0.1-0.5,0.1-0.9,0.1-1.4c-0.2,0.2-0.5,0.4-0.7,0.6
                    c-0.3,0.3-0.5,0.5-0.8,0.8c-0.3,0.3-0.5,0.5-0.8,0.8C77.7,25.5,77.5,25.7,77.3,25.8z M74.4,23.8L74.4,23.8c0,0.1,0,0.2,0.1,0.2
                    L74.4,23.8z M74.4,24.5L74.4,24.5l0,0.1L74.4,24.5z M74.5,25.3L74.5,25.3C74.5,25.3,74.5,25.3,74.5,25.3
                    C74.5,25.3,74.5,25.3,74.5,25.3L74.5,25.3L74.5,25.3z M74.5,22.8c0,0.1,0,0.1,0,0.2s0,0.1,0,0.2C74.6,23,74.6,22.9,74.5,22.8z
                    M76.1,21.5c-0.1,0.1-0.1,0.3-0.2,0.5c-0.1,0.2-0.1,0.5-0.1,0.7c0,0.2-0.1,0.5-0.1,0.7s-0.1,0.5-0.1,0.6c0.1-0.4,0.2-0.8,0.3-1.3
                    C76,22.4,76.1,22,76.1,21.5L76.1,21.5z M77.1,22.9c0.1-0.4,0.2-0.8,0.3-1.1s0.1-0.7,0.2-1.1c-0.1,0.3-0.1,0.7-0.3,1
                    c-0.1,0.3-0.2,0.7-0.2,1V22.9z M78.3,18.9c-0.2,0.5-0.3,1-0.4,1.6c-0.1,0.6-0.2,1.2-0.2,1.7c0,0,0,0,0,0.1c0,0,0,0,0,0.1
                    c0.1,0,0.2-0.2,0.2-0.4c0.1-0.3,0.1-0.5,0.2-0.6c0-0.4,0-0.8,0-1.2C78.2,19.7,78.3,19.3,78.3,18.9z M78.4,16.6c0,0.3,0.1,0.6,0,0.8
                    c0,0.3,0,0.5,0.1,0.8c0-0.1,0.1-0.2,0.1-0.4c0-0.2,0-0.5,0-0.8c0-0.3,0-0.5-0.1-0.8L78.4,16.6z M80.5,19.7
                    c-0.2,0.1-0.3,0.3-0.5,0.5c-0.2,0.2-0.4,0.5-0.5,0.7c-0.2,0.3-0.4,0.5-0.5,0.7s-0.3,0.5-0.4,0.6c0.1-0.1,0.3-0.3,0.5-0.5
                    c0.2-0.2,0.5-0.5,0.7-0.8c0.2-0.3,0.5-0.6,0.6-0.8c0.2-0.3,0.3-0.5,0.3-0.7l-0.1-0.1L80.5,19.7z M79.8,23.4
                    c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.2,0.2-0.3c-0.1,0-0.2,0.1-0.3,0.3S79.8,23.3,79.8,23.4z M80,21.5L80,21.5L80,21.5l0,0.1
                    L80,21.5z M80.6,25.4c-0.1,0.1-0.2,0.2-0.2,0.3l0.1-0.1C80.6,25.6,80.6,25.6,80.6,25.4c0.1,0,0.1-0.1,0.1-0.1l-0.1-0.2L80.6,25.4z
                    M80.8,24.2c0,0,0,0.1,0,0.2c0,0.1,0,0.1,0.1,0.1L80.8,24.2L80.8,24.2z M80.9,23.3l0,0.1c0,0,0.1-0.1,0.1-0.2l0-0.1L80.9,23.3z
                    M81.9,24.3c0.2-0.3,0.3-0.7,0.3-1c-0.1,0.2-0.1,0.3-0.2,0.5C82,23.9,81.9,24,81.9,24.3L81.9,24.3z M84,22.7c0,0.1-0.1,0.2-0.2,0.3
                    c-0.1,0.1-0.2,0.2-0.2,0.3v0c0,0,0.1-0.1,0.2-0.3C84,23,84.1,22.8,84,22.7L84,22.7z M84.6,24.9L84.6,24.9l-0.1,0L84.6,24.9
                    L84.6,24.9z M85.3,20.9c0,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.1,0.1-0.2,0.2c0,0.1-0.1,0.2-0.2,0.4c-0.1,0.2-0.2,0.3-0.2,0.4v0
                    c0.1,0,0.2-0.2,0.4-0.5c0.2-0.3,0.4-0.7,0.7-1.1c0.3-0.4,0.5-0.8,0.7-1.1c0.2-0.3,0.4-0.5,0.5-0.6l0.2-0.2v-0.2L87,18.8
                    c0,0,0-0.1,0.1-0.3c0.1-0.2,0.1-0.3,0.2-0.3c0,0,0.1,0,0.1,0c0,0,0,0,0-0.1l0.1-0.3l0-0.1l-0.1,0.1c0,0.2-0.1,0.3-0.2,0.4
                    c-0.1,0.1-0.2,0.3-0.2,0.4L86.6,19c0-0.1,0-0.1,0-0.2c0,0,0.1-0.1,0.1-0.1c-0.1,0.1-0.2,0.2-0.3,0.4s-0.3,0.5-0.5,0.7
                    s-0.3,0.5-0.5,0.7c-0.1,0.2-0.2,0.4-0.3,0.5L85.3,20.9z M85.3,18.1c0,0-0.1,0.1-0.1,0.2c0,0.1,0,0.2-0.1,0.4c0,0.1-0.1,0.2-0.1,0.4
                    c0,0.1,0,0.2,0,0.2v0.1c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.1-0.3V18.1z M85.4,24.2L85.4,24.2L85.4,24.2L85.4,24.2L85.4,24.2z
                    M85.4,20.7c0.1-0.2,0.2-0.4,0.3-0.5s0.2-0.3,0.4-0.5c-0.1,0.1-0.2,0.3-0.4,0.6C85.6,20.5,85.5,20.7,85.4,20.7L85.4,20.7z
                    M89.7,18.5c-0.1,0-0.1,0.1-0.2,0.2c0,0.1-0.1,0.1-0.1,0.2c-0.5,0.9-1.2,1.8-1.9,2.7c-0.7,0.8-1.4,1.7-2.1,2.5
                    c0.2-0.2,0.4-0.4,0.8-0.8c0.4-0.4,0.7-0.8,1.1-1.3c0.4-0.4,0.7-0.9,1-1.3c0.3-0.4,0.5-0.7,0.5-0.8C89.3,19.5,89.6,19,89.7,18.5z
                    M85.8,22c0.3-0.4,0.7-0.8,1-1.3c0.4-0.4,0.6-0.9,0.8-1.4c0,0.1-0.1,0.1-0.2,0.2c-0.1,0-0.2,0.1-0.2,0.1c0,0.1-0.1,0.3-0.2,0.5
                    c-0.1,0.2-0.3,0.4-0.4,0.6c-0.2,0.2-0.3,0.4-0.5,0.6C85.9,21.6,85.8,21.8,85.8,22z M85.8,23c0.1-0.1,0.4-0.3,0.6-0.6
                    c0.3-0.3,0.6-0.7,0.9-1.1c0.3-0.4,0.6-0.8,0.8-1.1c0.3-0.4,0.4-0.6,0.5-0.8c-0.1,0-0.2,0.2-0.5,0.6c-0.3,0.3-0.6,0.7-0.9,1.1
                    c-0.3,0.4-0.6,0.8-0.9,1.2S85.9,22.9,85.8,23z M85.9,21.5c0.1-0.1,0.1-0.2,0.2-0.3L86,21.3L85.9,21.5z M86.4,19.3L86.4,19.3
                    l0.1-0.2l0,0L86.4,19.3z M87.9,17.1c0,0-0.1,0-0.1,0.1c-0.1,0.1-0.1,0.1-0.1,0.2l0,0.1c0,0,0.1-0.1,0.1-0.1S87.9,17.2,87.9,17.1
                    L87.9,17.1z M87.7,19l0.2,0l0.1-0.2l-0.2,0L87.7,19z M88.2,18.2C88.2,18.2,88.2,18.3,88.2,18.2c-0.1,0.1-0.1,0.2-0.1,0.2v0.1l0.1,0
                    V18.2L88.2,18.2z M88.4,18.1L88.4,18.1L88.4,18.1L88.4,18.1L88.4,18.1z"/>
                <path d="M98.8,12.1c0.4,0,0.7,0.1,1.1,0.2s0.7,0.3,1,0.5c0.3,0.2,0.6,0.5,0.8,0.8c0.2,0.3,0.3,0.7,0.3,1.1c0,0.2-0.1,0.5-0.2,0.8
                    c-0.1,0.3-0.3,0.6-0.5,0.9c-0.2,0.3-0.4,0.6-0.7,0.8c-0.2,0.2-0.5,0.4-0.7,0.4l0.5-0.6c-0.1,0.1-0.2,0.2-0.4,0.3s-0.3,0.2-0.5,0.2
                    l-0.1-0.2l0.1-0.2c-0.1,0.1-0.2,0.2-0.3,0.2l-0.1-0.1l0.6-1c0,0,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.1-0.2v-0.1
                    c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.3-0.3,0.5-0.5,0.6c0.1-0.4,0.2-0.8,0.5-1.2s0.5-0.8,0.7-1.1c-0.6,0.3-1.2,0.8-1.9,1.6
                    c-0.7,0.8-1.3,1.6-1.8,2.5c-0.6,0.9-1,1.8-1.4,2.8c-0.4,0.9-0.5,1.8-0.5,2.4c0,0.1,0,0.2,0,0.4c0,0.2,0.1,0.3,0.3,0.3
                    c0.4,0,0.8-0.1,1.2-0.3c0.4-0.2,0.8-0.5,1.2-0.7c0.4-0.3,0.7-0.6,1.1-1s0.6-0.7,0.9-0.9c0.1-0.1,0.2-0.3,0.4-0.5s0.3-0.3,0.4-0.4
                    l0,0.1c-0.4,0.5-0.8,1-1.2,1.4c-0.4,0.4-0.8,0.9-1.1,1.5c0.2-0.1,0.4-0.3,0.7-0.6c0.3-0.3,0.5-0.6,0.8-0.9c0.3-0.3,0.5-0.6,0.7-0.8
                    c0.2-0.2,0.4-0.4,0.5-0.5c0,0.1-0.1,0.3-0.2,0.4c-0.1,0.1-0.1,0.2-0.2,0.4c0.1-0.1,0.1-0.2,0.3-0.3c0.1-0.1,0.2-0.2,0.3-0.2l0,0.1
                    l-0.2,0.5l0.3-0.4c-0.2,0.3-0.4,0.7-0.6,1.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.2c0.1-0.1,0.1-0.1,0.1,0
                    c0,0-0.1,0.1-0.2,0.2c-0.1,0.1-0.2,0.1-0.2,0.2v0.1l0.1-0.1v0c-0.3,0.3-0.6,0.7-0.9,1.1c-0.3,0.4-0.7,0.7-1.1,1.1
                    c-0.4,0.3-0.8,0.6-1.3,0.8c-0.4,0.2-0.9,0.3-1.3,0.3c-0.7,0-1.3-0.1-1.8-0.3c-0.6-0.2-1.1-0.5-1.5-0.8c-0.4-0.4-0.8-0.8-1-1.3
                    s-0.4-1.2-0.4-1.9c0-0.7,0.1-1.4,0.4-2.1c0.2-0.8,0.5-1.5,0.9-2.3c0.4-0.8,0.9-1.5,1.4-2.3s1.1-1.4,1.8-1.9c0.6-0.6,1.3-1,2-1.4
                    C97.4,12.2,98.1,12.1,98.8,12.1z M93.2,17.1c0.2-0.1,0.5-0.3,0.7-0.6c0.3-0.3,0.6-0.5,0.9-0.9c0.3-0.3,0.6-0.6,0.9-0.9
                    s0.6-0.5,0.9-0.7c0.2-0.2,0.5-0.3,0.7-0.5c0.2-0.2,0.5-0.4,0.7-0.5c-0.3,0-0.7,0.2-1.2,0.5s-1,0.7-1.5,1.2c-0.5,0.5-1,0.9-1.4,1.4
                    S93.4,16.9,93.2,17.1z M93.7,16.1c0.2-0.2,0.5-0.5,0.7-0.7c0.2-0.2,0.5-0.5,0.7-0.7c-0.2,0.2-0.5,0.4-0.7,0.7
                    C94.1,15.7,93.8,15.9,93.7,16.1z M94.3,23.8L94.3,23.8l-0.2,0.1l0,0.1l0.1,0L94.3,23.8z M94.3,22.6L94.3,22.6l0.1-0.3L94.3,22.6z
                    M94.7,21.6l0-0.2c-0.1,0.1-0.1,0.2-0.1,0.3v0.1L94.7,21.6z M95.1,20.6c0-0.3,0.1-0.5,0.3-0.7s0.3-0.5,0.4-0.7
                    c-0.3,0.3-0.5,0.6-0.7,1c-0.2,0.4-0.3,0.8-0.4,1.2L95.1,20.6z M94.8,17.3c0.5-0.5,1-1,1.5-1.5c0.5-0.5,0.9-1,1.5-1.5
                    c-0.6,0.4-1.1,0.9-1.6,1.4C95.7,16.2,95.3,16.7,94.8,17.3z M96,19c0.5-0.9,1.1-1.8,1.8-2.5c0.7-0.7,1.5-1.4,2.4-2
                    c-0.2,0-0.6,0.2-1.1,0.6c-0.5,0.4-1,0.8-1.5,1.3c-0.5,0.5-0.9,1-1.3,1.5c-0.4,0.5-0.5,0.9-0.5,1.1v0L96,19z M99.2,14.5
                    c-0.5,0.4-1,0.8-1.5,1.2s-0.9,0.9-1.3,1.4c0.1,0,0.2-0.1,0.3-0.2s0.2-0.2,0.3-0.3c0.3-0.4,0.7-0.7,1.1-1.1S98.8,14.9,99.2,14.5z
                    M96.6,15c0,0-0.1,0.1-0.2,0.2L96.6,15z M99.4,23.6c-0.2,0.2-0.3,0.3-0.4,0.5c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.1,0.2-0.1,0.2
                    c0.1-0.1,0.2-0.2,0.4-0.5C99.2,23.9,99.3,23.7,99.4,23.6L99.4,23.6z M99,23.8c-0.1,0-0.1,0.1-0.2,0.2c-0.1,0.1-0.1,0.1-0.2,0.2
                    c0.1,0,0.1-0.1,0.2-0.2C98.9,24,98.9,23.9,99,23.8z M99.4,22.6l0.1-0.1l0.1-0.1L99.4,22.6L99.4,22.6z M100,16.8
                    c0.3-0.2,0.4-0.4,0.5-0.6c-0.1,0.1-0.2,0.2-0.3,0.3C100.2,16.6,100.1,16.7,100,16.8z M100.4,17.6c0.2-0.2,0.3-0.3,0.5-0.5
                    s0.3-0.3,0.5-0.5h0c0,0.1-0.2,0.3-0.4,0.5C100.7,17.4,100.5,17.5,100.4,17.6z M100.6,15L100.6,15l-0.2,0.2v0.1L100.6,15z
                    M100.6,16.8c0,0,0,0.1-0.1,0.2l0.4-0.5c0,0-0.1,0.1-0.2,0.1S100.7,16.8,100.6,16.8z M100.9,15.6l-0.1,0.1l0,0.2L100.9,15.6z
                    M101.1,15.5L101.1,15.5l-0.2-0.1L101.1,15.5L101.1,15.5z M101.6,16.1l-0.2,0.3v0L101.6,16.1z"/>
                <path d="M103.4,26.8c-0.4,0-0.8-0.1-1.1-0.2c-0.3-0.2-0.5-0.4-0.6-0.7s-0.3-0.6-0.3-0.9c-0.1-0.3-0.1-0.7-0.1-1
                    c0-0.7,0.1-1.6,0.3-2.8c0.2-1.2,0.5-2.6,0.9-4c0.4-1.5,0.8-3,1.3-4.5c0.5-1.5,1.1-2.9,1.7-4.2c0.6-1.2,1.3-2.3,2-3.1
                    c0.7-0.8,1.5-1.2,2.3-1.2c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0,0.2,0l0,0.1l-0.1,0.2l0.1,0.1c0,0,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0
                    c0.1,0,0.2,0,0.3,0.1c0.1,0,0.2,0.1,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.2,0.2c0,0,0,0.1,0,0.1c0,0,0,0,0,0
                    c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.2-0.2,0.3-0.3,0.5s-0.1,0.2-0.1,0.3V7c0.2-0.3,0.3-0.5,0.5-0.8
                    c0.2-0.3,0.4-0.5,0.5-0.7h0.2c0,0.1-0.1,0.3-0.2,0.6c-0.1,0.3-0.3,0.6-0.5,0.9c-0.2,0.3-0.3,0.7-0.5,1c-0.2,0.3-0.3,0.5-0.3,0.7
                    c-0.1,0.2-0.1,0.3-0.2,0.5c0,0.1-0.1,0.3-0.2,0.5c-0.2,0.5-0.4,1-0.6,1.5c-0.2,0.5-0.5,1-0.7,1.5c-0.4,0.9-0.8,1.9-1.2,2.9
                    c-0.4,1-0.8,2.1-1.2,3.1c-0.4,1-0.7,2.1-1,3.2s-0.5,2.1-0.6,3.1c0,0.1,0,0.1,0,0.2s0,0.1,0,0.2c0,0.1,0,0.2,0.1,0.3
                    c0.1-0.8,0.3-1.5,0.4-2.3s0.3-1.5,0.5-2.2c0.6-2,1.3-4,2.1-6c0.8-2,1.6-3.9,2.4-5.9c0.2-0.6,0.5-1.1,0.9-1.7
                    c0.4-0.5,0.6-1.1,0.9-1.7c0,0.3-0.1,0.7-0.3,1c-0.1,0.3-0.3,0.7-0.5,1.1s-0.3,0.7-0.5,1s-0.3,0.7-0.4,1c-0.2,0.6-0.5,1.4-0.9,2.2
                    s-0.7,1.7-1.1,2.6c-0.4,0.9-0.8,1.8-1.2,2.8c-0.4,0.9-0.8,1.9-1.1,2.7c-0.3,0.9-0.6,1.7-0.8,2.5c-0.2,0.8-0.3,1.5-0.3,2.1
                    c0,0.1,0,0.2,0,0.3s0,0.2,0,0.3c0.1,0,0.3,0,0.5,0s0.3,0.1,0.3,0.2c0,0.1-0.1,0.2-0.3,0.4c-0.2,0.1-0.5,0.3-0.7,0.4
                    c-0.3,0.1-0.5,0.2-0.8,0.3C103.6,26.8,103.5,26.8,103.4,26.8z M102.2,24.9c0.2-1.4,0.4-2.7,0.7-4.1c0.2-1.4,0.5-2.7,0.9-4.1
                    c-0.2,0.5-0.3,1.1-0.5,1.7c-0.2,0.6-0.3,1.2-0.5,1.8c-0.2,0.6-0.3,1.2-0.4,1.8c-0.1,0.6-0.2,1.2-0.2,1.7c0,0.2,0,0.4,0,0.5
                    C102.2,24.5,102.2,24.7,102.2,24.9z M102.9,18.3L102.9,18.3L102.9,18.3L102.9,18.3z M103,17.9L103,17.9c0,0,0.1-0.1,0.1-0.2
                    s0.1-0.2,0.1-0.3l-0.1,0.1L103,17.9z M103.3,16.9c0-0.2,0.1-0.4,0.2-0.7c0.1-0.3,0.2-0.6,0.3-0.9c0.1-0.3,0.2-0.6,0.3-0.9
                    s0.2-0.5,0.2-0.7c-0.1,0.2-0.1,0.4-0.3,0.7c-0.1,0.3-0.2,0.6-0.3,0.9s-0.2,0.6-0.2,0.9C103.3,16.5,103.3,16.7,103.3,16.9
                    L103.3,16.9z M103.3,25C103.3,25,103.3,24.9,103.3,25c0.1-0.2,0.1-0.2,0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3l0-0.6
                    c-0.1,0.4-0.1,0.8-0.1,1.2L103.3,25L103.3,25z M104.3,24.9c0-0.2,0-0.4,0-0.6c0-0.2,0-0.4,0-0.6c0,0.1,0,0.2,0,0.3
                    c0,0.1,0,0.2,0,0.4c0,0.3,0,0.7,0.1,1V24.9z M104.3,13.4L104.3,13.4l0.1-0.3l0,0L104.3,13.4z M104.5,18.5L104.5,18.5L104.5,18.5
                    l0,0.2L104.5,18.5z M104.6,18.3c0.2-0.5,0.4-1.1,0.6-1.7c0.2-0.6,0.3-1.1,0.5-1.7c0.1-0.5,0.3-0.9,0.6-1.4c0.2-0.4,0.5-0.9,0.7-1.4
                    c0.2-0.5,0.4-0.9,0.6-1.4c0.2-0.5,0.3-0.9,0.4-1.4c-0.2,0.3-0.5,0.9-0.9,1.7c-0.4,0.8-0.7,1.7-1.1,2.6c-0.4,0.9-0.7,1.8-0.9,2.7
                    C104.7,17.3,104.6,17.9,104.6,18.3L104.6,18.3z M104.8,12c0.2-0.4,0.4-0.8,0.5-1.1c0.2-0.3,0.3-0.6,0.5-1c0.2-0.4,0.4-0.7,0.5-1.1
                    s0.3-0.6,0.5-0.9c0.1-0.3,0.2-0.4,0.3-0.5c-0.4,0.8-0.8,1.5-1.1,2.3c-0.3,0.8-0.6,1.6-0.9,2.4c0.3-0.9,0.7-1.7,1.1-2.6
                    c0.4-0.8,0.9-1.6,1.4-2.4c0.2-0.3,0.5-0.6,0.8-0.9c0.3-0.3,0.6-0.6,0.8-1c-0.3,0-0.7,0.2-1,0.6c-0.3,0.3-0.6,0.7-0.8,1l0.1-0.2
                    c-0.2,0.2-0.4,0.5-0.7,1s-0.6,1-0.9,1.6c-0.3,0.6-0.5,1.1-0.8,1.6C105,11.3,104.9,11.7,104.8,12z M105.6,18.9
                    c0.3-0.8,0.6-1.5,0.8-2c0.2-0.6,0.5-1.2,0.8-1.9c0.3-0.7,0.5-1.4,0.8-2c0.3-0.7,0.5-1.2,0.7-1.7c0.2-0.5,0.3-0.8,0.4-1.1l-0.1,0.1
                    c-0.7,1.4-1.3,2.8-1.8,4.3C106.6,15.9,106.1,17.4,105.6,18.9z M107.2,7.4C107.2,7.4,107.2,7.3,107.2,7.4
                    C107.2,7.3,107.2,7.4,107.2,7.4z M107.2,7.3c0.1-0.1,0.2-0.3,0.3-0.5L107.2,7.3z M109.9,6c-0.4,0.5-0.8,1.1-1.1,1.6
                    c-0.3,0.6-0.6,1.2-0.9,1.8c0.3-0.6,0.7-1.1,1-1.7C109.3,7.1,109.6,6.6,109.9,6z M110.4,7.2c0,0-0.1,0.1-0.2,0.2S110,7.8,109.9,8
                    c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.2-0.1,0.3-0.2,0.4c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.2,0.1-0.3,0.2-0.5s0.1-0.3,0.2-0.5
                    C110.2,7.5,110.3,7.3,110.4,7.2L110.4,7.2z"/>
                <path d="M114.5,16c-0.1,0.1-0.2,0.4-0.3,0.9c-0.2,0.4-0.3,1-0.5,1.6c-0.2,0.6-0.4,1.3-0.5,1.9c-0.2,0.7-0.4,1.3-0.5,1.9
                    c-0.2,0.6-0.3,1.1-0.4,1.6c-0.1,0.4-0.2,0.7-0.2,0.9c0,0.3,0.1,0.7,0.2,1l-0.2,0.1l-0.2,0.5l-0.5,0l-0.1,0l-0.3,0l0.1,0.1
                    c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c-0.5,0-0.9-0.1-1.2-0.3s-0.7-0.4-0.9-0.8c-0.2-0.3-0.4-0.7-0.5-1.1s-0.2-0.8-0.2-1.3
                    c0-0.4,0.1-1,0.2-1.7c0.1-0.7,0.3-1.4,0.6-2.1s0.5-1.5,0.9-2.3c0.3-0.8,0.7-1.5,1.1-2.2c0.4-0.7,0.8-1.2,1.2-1.7
                    c0.4-0.5,0.8-0.8,1.2-1.1l0.2,0.3c0,0.1-0.1,0.2-0.2,0.3l-0.1,0.3l-0.1,0l-0.5,0.5v0.1c0,0.1-0.1,0.2-0.2,0.3
                    c-0.1,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0c0,0,0,0,0,0l0.2,0.1l-0.1-0.2l0-0.1l0.2-0.1l0.1,0.1v0.2c0.1,0,0.1-0.1,0.1-0.2s0-0.1,0-0.2
                    c0.1-0.1,0.3-0.3,0.4-0.4c0.2-0.1,0.3-0.2,0.4-0.4c0-0.1,0.1-0.2,0.3-0.4c0.1-0.2,0.3-0.3,0.3-0.3c0,0,0.1,0,0.1,0.1
                    c0,0,0,0.1,0,0.1c0,0.1,0.1,0.2,0.1,0.3c0,0.1-0.1,0.3-0.2,0.4c-0.1,0.2-0.2,0.4-0.2,0.6l0.1,0.1l0-0.3l0.2-0.1
                    c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.2c0,0,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2,0,0.2l0,0.2l0.1,0.1
                    c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2,0.1,0.3c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.3c0,0.2-0.1,0.2-0.1,0.3
                    c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2l-0.1,0.1l-0.1-0.1c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.3,0,0.3
                    l0.1,0.1h0c0.1-0.1,0.2-0.3,0.2-0.5L114.5,16z M110,16.5l-0.2-0.2L110,16.5L110,16.5z M110.4,17.1l-0.1,0.1l0,0.1L110.4,17.1
                    L110.4,17.1z M110.8,16.5L110.8,16.5l-0.2,0L110.8,16.5L110.8,16.5z M111.4,15L111.4,15l0,0.2L111.4,15z M112.4,21.6
                    c-0.2,0.2-0.3,0.5-0.4,0.9c-0.1,0.4-0.1,0.7-0.2,1c0.1,0,0.1,0,0.1-0.1v0l0-0.2c0-0.1,0.1-0.2,0.1-0.2c0-0.2,0.1-0.4,0.2-0.7
                    C112.3,22.2,112.4,21.9,112.4,21.6L112.4,21.6z M112,25.1L112,25.1L112,25.1l-0.1,0.1L112,25.1z M112.2,13.6c0-0.1,0.1-0.2,0.2-0.3
                    s0.2-0.2,0.2-0.2c0,0.1,0,0.2-0.1,0.3C112.3,13.5,112.3,13.6,112.2,13.6z M112.5,21.3L112.5,21.3L112.5,21.3l-0.1,0.2L112.5,21.3z
                    M116.8,7c0.2,0.2,0.3,0.5,0.3,0.8c0,0.2,0,0.4-0.1,0.6s-0.2,0.4-0.4,0.5c0,0,0,0,0,0c-0.1,0.1-0.1,0.1-0.2,0.2
                    c-0.1,0.1-0.2,0.3-0.3,0.5c-0.1,0.1-0.2,0.3-0.4,0.4c-0.2,0.1-0.3,0.2-0.5,0.3l-0.1,0.3l-0.1-0.1c-0.1,0-0.2,0-0.3,0l-0.2,0.1
                    c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.1-0.4-0.3-0.5-0.4V9.8h-0.2c-0.1,0.1-0.2,0.1-0.3-0.1s-0.2-0.3-0.2-0.5c-0.1-0.2-0.1-0.4-0.1-0.6
                    c0-0.2,0-0.3,0-0.4c0-0.3,0.1-0.6,0.2-0.9c0.1-0.3,0.3-0.5,0.6-0.8c0.2-0.2,0.5-0.4,0.8-0.6c0.3-0.1,0.6-0.2,0.9-0.2
                    c0.3,0,0.6,0.1,0.9,0.2s0.5,0.3,0.8,0.5c0,0.1,0.1,0.1,0.1,0.2C116.8,6.8,116.8,6.9,116.8,7L116.8,7z M112.7,20.7L112.7,20.7
                    l-0.2,0.2l0.1,0L112.7,20.7z M112.7,15.1L112.7,15.1L112.7,15.1L112.7,15.1z M112.9,20.3L112.9,20.3l-0.1,0.2v0.1l0.1-0.1
                    L112.9,20.3z M113.4,18.9c0,0-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.1,0.4c0,0.1-0.1,0.2-0.1,0.2V20
                    c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.2,0.1-0.3l0.2-0.2L113.4,18.9z M113.2,14.1L113.2,14.1l0,0.2L113.2,14.1z M113.6,18.7l-0.1-0.3
                    l-0.1,0.1l0,0.2L113.6,18.7z M113.5,7.2c0.1-0.1,0.3-0.2,0.6-0.3c0.3-0.2,0.4-0.3,0.5-0.4l0-0.1C114.2,6.6,113.8,6.8,113.5,7.2z
                    M113.5,18.1L113.5,18.1h0.1l-0.1-0.1L113.5,18.1z M113.8,17.7l-0.1,0.2l0,0L113.8,17.7L113.8,17.7z M114.2,16.8
                    c-0.1,0-0.1,0-0.1,0.1s-0.1,0.1-0.1,0.2l0.1,0.2l0-0.1l0-0.1C114.1,17,114.1,16.9,114.2,16.8L114.2,16.8z M114,16.7L114,16.7l0.1,0
                    l-0.1-0.1L114,16.7z M114.6,7.2c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c0.1-0.1,0.2-0.2,0.3-0.2
                    C114.4,7.4,114.5,7.3,114.6,7.2z M114.3,14L114.3,14L114.3,14L114.3,14L114.3,14z M114.5,15.6c0.1-0.2,0.2-0.3,0.2-0.5l0.1-0.1
                    c0,0.1,0.1,0.2,0.1,0.2c0,0,0,0.1,0,0.1s0,0.1,0,0.1L114.5,15.6z M114.7,14l0-0.3l0.1,0.1l-0.1,0.4l0-0.1L114.7,14z M114.9,7.8
                    L114.9,7.8l-0.2,0.1l0,0L114.9,7.8z M114.7,9.3c0.2,0,0.4-0.1,0.5-0.2c0.2-0.1,0.3-0.2,0.4-0.4c0,0,0,0,0,0c0,0,0,0,0,0
                    c-0.1,0-0.2,0.1-0.4,0.3S114.8,9.2,114.7,9.3z M114.8,14.9L114.8,14.9l0-0.2L114.8,14.9L114.8,14.9z M114.9,7.7
                    C114.9,7.7,114.9,7.8,114.9,7.7L114.9,7.7L114.9,7.7L114.9,7.7z M115.6,9.9c0.1-0.1,0.2-0.1,0.2-0.2c0.1-0.1,0.1-0.1,0.2-0.2
                    L115.6,9.9L115.6,9.9z M116.3,9.6c-0.1,0.3-0.3,0.5-0.6,0.6L116.3,9.6z M116,6.2l0.1-0.1L116,6.2L116,6.2L116,6.2z M116.3,7.4h-0.2
                    l0,0L116,7.7l0,0.1l0.1-0.1l0-0.2h0.2V7.4z"/>
                <path d="M125.1,24.1c-0.2,0.1-0.3,0.2-0.5,0.3c0,0.1-0.1,0.2-0.1,0.2c0,0.1-0.1,0.1-0.2,0.2c-0.3,0-0.4,0.1-0.5,0.1
                    c-0.1,0-0.2,0-0.2,0.1c0,0-0.1,0.1-0.2,0.1s-0.2,0.1-0.4,0.2l-0.1,0.2c-0.2,0-0.4,0.1-0.5,0.2c-0.2,0.1-0.4,0.1-0.6,0.1
                    c-0.2,0-0.5,0-0.7-0.1c-0.2,0.1-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.1-0.4,0.1c-0.3,0-0.7-0.1-1-0.2s-0.6-0.2-1-0.2
                    c-0.1,0-0.2,0-0.2,0.1c0,0.1,0,0.1-0.1,0.2c-0.1,0.2-0.1,0.6-0.2,1.1c-0.1,0.6-0.2,1.1-0.4,1.7c-0.1,0.6-0.3,1.1-0.4,1.6
                    c-0.2,0.5-0.3,0.8-0.4,0.8l-0.2,0.1c0.1,0.1,0.1,0.1,0.2,0.1h0.1l-0.2,0.9h0l0-0.2l-0.1,0.1c0,0.2-0.1,0.4-0.1,0.6
                    c0,0.2-0.1,0.4-0.1,0.6c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1-0.1-0.1c0,0.1-0.1,0.3-0.2,0.4
                    c-0.1,0.2-0.2,0.3-0.2,0.4c-0.1,0-0.2-0.1-0.1-0.2c0-0.1,0-0.2-0.1-0.2l0,0.1c0,0.3-0.1,0.6-0.3,0.9l-0.2-0.1c0,0-0.1-0.1-0.1-0.2
                    s0-0.2,0-0.4c0-0.1,0-0.3,0-0.4c0-0.1,0-0.2,0-0.3c0-1.5,0.1-3,0.3-4.4c0.2-1.4,0.5-2.9,0.9-4.3c0.4-1.4,0.8-2.8,1.2-4.2
                    c0.4-1.4,0.9-2.8,1.3-4.2c0.1-0.1,0.2-0.3,0.3-0.5c0-0.2,0.1-0.4,0.1-0.5v0c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1-0.1,0.2-0.2,0.3
                    c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0.1-0.2v-0.1c-0.1,0.1-0.2,0.4-0.4,0.8c-0.2,0.4-0.3,0.8-0.5,1.2s-0.3,0.8-0.5,1.2s-0.2,0.6-0.2,0.7
                    c0.1-0.1,0.1-0.1,0.2-0.2c0-0.1,0.1-0.2,0.1-0.3c0.1-0.4,0.3-0.7,0.4-1.1c0.1-0.4,0.3-0.7,0.5-1.1l0,0c0,0.1-0.1,0.3-0.2,0.7
                    c-0.1,0.4-0.3,0.8-0.5,1.2c-0.2,0.4-0.3,0.8-0.5,1.1c-0.2,0.3-0.3,0.5-0.3,0.5c0,0.1,0,0.3,0,0.6c-0.1,0.3-0.1,0.6-0.2,1
                    c-0.1,0.3-0.2,0.7-0.3,0.9c-0.1,0.3-0.2,0.4-0.3,0.5l0,0l0.2,0.2l-0.1,0.2c0,0.3-0.1,0.7-0.2,1c-0.1,0.3-0.2,0.7-0.3,1l0,0.2
                    c-0.1,0.3-0.1,0.6-0.2,1.1c-0.1,0.5-0.2,0.8-0.3,1c0,0.1,0,0.3-0.1,0.6c0,0.3-0.1,0.6-0.2,1c-0.1,0.4-0.1,0.7-0.2,1.1
                    c-0.1,0.4-0.1,0.7-0.1,0.9c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3l-0.1,0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2c0,0.3,0,0.7,0,1
                    c0,0.3,0.1,0.7,0.1,1l-0.1,0.2c-0.2-0.1-0.3-0.3-0.4-0.6c-0.1-0.3-0.1-0.5-0.2-0.8c0-0.3-0.1-0.6-0.1-0.9c0-0.3,0-0.5,0-0.7
                    c0-0.6,0-1.2,0.1-1.8c0.1-0.6,0.2-1.2,0.3-1.8c0.1-0.6,0.2-1.2,0.4-1.8s0.2-1.2,0.3-1.8c0-0.3,0.2-0.9,0.4-1.6s0.4-1.6,0.7-2.6
                    c0.3-0.9,0.6-1.9,1-2.9s0.7-1.9,1.1-2.8c0.4-0.8,0.8-1.5,1.2-2.1c0.4-0.5,0.7-0.8,1-0.9c0,0.1,0,0.2-0.1,0.3s-0.1,0.1-0.1,0.2
                    l0.2-0.2c-0.1,0.2-0.2,0.5-0.3,0.7c-0.1,0.2-0.2,0.5-0.3,0.7c0.1,0,0.2-0.1,0.3-0.3s0.2-0.3,0.4-0.5c0.1-0.2,0.2-0.3,0.3-0.5
                    c0.1-0.1,0.2-0.2,0.2-0.2c0.1,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.1c0.3,0.1,0.5,0.3,0.6,0.4s0.2,0.4,0.2,0.7c0,0.2,0,0.4,0,0.5
                    c0,0.2,0,0.4,0,0.6v0.2l0.1-0.3l0.1-0.1c0,0.3,0,0.5-0.1,0.8c0,0.2-0.1,0.5-0.1,0.8c0.3-0.3,0.5-0.5,0.9-0.9s0.6-0.6,1-0.9
                    c0.4-0.3,0.7-0.5,1.1-0.7c0.4-0.2,0.8-0.3,1.2-0.3c0.4,0,0.8,0.1,1.2,0.3c0.4,0.2,0.8,0.4,1.1,0.7c0.3,0.3,0.6,0.6,0.9,1
                    c0.2,0.4,0.3,0.8,0.3,1.2c0,0.2,0,0.3,0.1,0.4c0,0.3-0.1,0.6-0.2,1c-0.1,0.3-0.2,0.6-0.3,0.9l0.3-0.3c0-0.1,0-0.2,0.1-0.2
                    c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0.2-0.1,0.5-0.3,0.9c-0.2,0.5-0.5,1-0.7,1.5s-0.6,1-0.8,1.4c-0.3,0.4-0.5,0.7-0.6,0.8
                    c-0.1,0.1-0.1,0.2-0.2,0.4c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.2,0.3-0.4,0.4c-0.1,0.1-0.2,0.2-0.3,0.2l-0.3,0.2
                    c0,0.1,0,0.2-0.1,0.2H125.1L125.1,24.1z M115.3,33c0,0,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1V33L115.3,33z M115.6,30.7L115.6,30.7
                    C115.6,30.7,115.6,30.7,115.6,30.7l-0.1,0L115.6,30.7L115.6,30.7z M115.9,28.1C115.9,28,115.9,28,115.9,28.1c0.1-0.1,0-0.2,0-0.2
                    V28.1z M116.1,32.4C116.1,32.4,116.1,32.3,116.1,32.4c0.1-0.2,0.1-0.2,0.2-0.2l0-0.1l-0.2,0L116.1,32.4L116.1,32.4z M116.6,32.7
                    L116.6,32.7h-0.2H116.6L116.6,32.7z M116.4,26L116.4,26l0.1,0.1c0.1,0,0.1-0.1,0.1-0.2l-0.1-0.1L116.4,26z M117,18.8L117,18.8
                    c-0.1,0.1-0.1,0.2-0.1,0.2c0,0.1,0,0.1,0,0.2L117,18.8z M116.9,30.4L116.9,30.4l0,0.1L116.9,30.4z M116.9,29.2c0,0,0,0.1,0,0.2
                    L116.9,29.2z M117.1,27.5l0-0.1l-0.1-0.2l-0.1,0.1L117.1,27.5z M117.4,29.3C117.4,29.3,117.3,29.3,117.4,29.3
                    c-0.1-0.1-0.2-0.1-0.2-0.1h-0.2C117.1,29.4,117.2,29.4,117.4,29.3L117.4,29.3z M117.2,26.7l-0.2,0.2l0,0.1L117.2,26.7L117.2,26.7z
                    M117.4,26.2L117.4,26.2l0,0.2L117.4,26.2L117.4,26.2z M117.4,28.7c0.2-0.5,0.4-1,0.5-1.5c0.1-0.5,0.2-1,0.3-1.6l0-0.1
                    c-0.2,0.4-0.3,0.9-0.5,1.5c-0.2,0.6-0.3,1.1-0.3,1.6V28.7z M117.5,22L117.5,22l0.1-0.1l-0.1,0L117.5,22z M117.6,25.5
                    c0.1-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.2-0.1-0.3l-0.1,0.2l0.1,0l0,0.2c-0.1,0.1-0.1,0.2-0.1,0.3l0,0.2L117.6,25.5z M117.6,21.6
                    c0.1-0.3,0.2-0.7,0.4-1.2c0.2-0.5,0.4-1,0.6-1.6c0.2-0.5,0.4-1.1,0.5-1.6c0.2-0.5,0.3-0.9,0.3-1.1c-0.1,0-0.2,0.3-0.4,0.9
                    c-0.2,0.5-0.4,1.1-0.6,1.7s-0.4,1.2-0.6,1.8C117.7,21.1,117.6,21.5,117.6,21.6z M118.2,15.8c0,0,0-0.1,0.1-0.2
                    c0.1-0.1,0.1-0.2,0.2-0.2c0,0-0.1,0.1-0.1,0.3C118.3,15.8,118.2,15.8,118.2,15.8L118.2,15.8z M118.6,22c0,0,0.1-0.1,0.1-0.2
                    c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.2,0.1-0.4c0-0.1,0-0.2,0-0.2v-0.1L118.6,22z M119.4,23.8c0.5,0,1-0.2,1.5-0.5
                    c0.5-0.3,0.9-0.7,1.4-1.2c0.4-0.5,0.9-0.9,1.2-1.5c0.4-0.5,0.7-0.9,0.9-1.3c0.5-0.7,0.8-1.5,1.1-2.2s0.5-1.6,0.6-2.4
                    c-0.4,0-0.9,0.2-1.4,0.6s-0.9,0.9-1.4,1.5c-0.4,0.6-0.8,1.1-1.2,1.7c-0.4,0.6-0.7,1-1,1.3c0.1-0.3,0.2-0.6,0.4-0.8
                    c0.2-0.3,0.3-0.5,0.5-0.7c0.2-0.2,0.4-0.5,0.6-0.7c0.2-0.2,0.3-0.5,0.5-0.8c-0.2,0.1-0.4,0.4-0.7,0.8c-0.3,0.4-0.6,0.9-0.9,1.4
                    c-0.3,0.5-0.6,1-0.9,1.5c-0.3,0.5-0.5,0.8-0.6,1c0.1-0.3,0.3-0.6,0.4-0.9c0.2-0.3,0.3-0.6,0.4-0.9c-0.2,0.2-0.3,0.4-0.4,0.6
                    c-0.1,0.2-0.2,0.4-0.4,0.6c0,0.1-0.1,0.3-0.1,0.4s-0.1,0.3-0.2,0.4l0,0.1l0.2-0.2c-0.2,0.4-0.3,0.7-0.5,1.1
                    c-0.1,0.4-0.3,0.7-0.4,1.1C119.1,23.8,119.2,23.8,119.4,23.8z M119.5,21.2c-0.1,0-0.1,0.1-0.2,0.2c0,0.1-0.1,0.2-0.1,0.3
                    L119.5,21.2z M119.5,25.7h-0.1l0,0L119.5,25.7z M119.6,15.8L119.6,15.8l0,0.2l0.1-0.1L119.6,15.8z M119.8,20.5c0,0-0.1,0.1-0.1,0.3
                    s-0.1,0.2-0.1,0.2V21c0.1-0.1,0.1-0.2,0.2-0.3C119.7,20.7,119.7,20.6,119.8,20.5z M120.6,16c0,0.1-0.1,0.2-0.2,0.4
                    s-0.2,0.4-0.3,0.7c-0.1,0.2-0.2,0.5-0.2,0.7c-0.1,0.2-0.1,0.3-0.1,0.4v0.1c0.2-0.3,0.3-0.6,0.5-1.1S120.6,16.3,120.6,16z
                    M120.6,17.6c-0.1,0.1-0.2,0.3-0.3,0.5c-0.1,0.2-0.2,0.4-0.2,0.5c0.1-0.2,0.2-0.3,0.2-0.5C120.5,18,120.6,17.8,120.6,17.6
                    L120.6,17.6z M121,19.2c-0.1,0-0.1,0.1-0.1,0.1l0.1,0L121,19.2L121,19.2z M121.4,15.1c-0.1,0.1-0.2,0.3-0.2,0.4
                    c-0.1,0.2-0.1,0.3-0.1,0.5c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.3,0.1-0.4V15.1z M121.1,19.1c0.1-0.2,0.2-0.3,0.4-0.5
                    c0.1-0.2,0.2-0.4,0.3-0.6c-0.1,0.1-0.2,0.3-0.4,0.5C121.2,18.8,121.1,19,121.1,19.1z M122.9,14.8c-0.1,0-0.3,0.1-0.4,0.3
                    c-0.2,0.2-0.3,0.4-0.5,0.6c-0.2,0.2-0.3,0.4-0.5,0.7c-0.1,0.2-0.2,0.4-0.2,0.5C121.9,16.2,122.4,15.5,122.9,14.8z M121.6,25.6
                    L121.6,25.6L121.6,25.6L121.6,25.6L121.6,25.6z M121.9,13.5c0,0,0-0.1,0-0.2L121.9,13.5z M122.1,17.5L122.1,17.5l-0.2,0.2h0
                    L122.1,17.5z M122.8,25.3L122.8,25.3l0,0.1L122.8,25.3L122.8,25.3z M124.5,21.1c0.2-0.3,0.5-0.7,0.8-1.1c0.3-0.4,0.5-0.8,0.5-1.2
                    c-0.1,0-0.1,0.1-0.2,0.2c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0.2-0.3,0.5-0.5,0.7c-0.2,0.3-0.4,0.6-0.7,0.9c-0.2,0.3-0.4,0.6-0.6,0.8
                    c-0.2,0.3-0.3,0.5-0.4,0.6c0.3-0.2,0.5-0.4,0.7-0.6C124.1,21.4,124.2,21.2,124.5,21.1z M123.8,25.1c0,0-0.1,0.1-0.1,0.2
                    c0,0.1-0.1,0.1-0.2,0.1l-0.1,0v-0.2L123.8,25.1z M124.3,24.5L124.3,24.5h-0.1H124.3L124.3,24.5z M124.5,24.3
                    c0.1-0.1,0.2-0.2,0.2-0.2l0,0c0,0-0.1,0-0.1,0.1C124.5,24.2,124.4,24.2,124.5,24.3L124.5,24.3z M125.2,23.5l-0.1-0.1l0,0.1H125.2
                    L125.2,23.5z M125.6,18.6c-0.1,0.1-0.1,0.2-0.2,0.2s-0.1,0.2-0.1,0.3c0.1-0.1,0.1-0.2,0.2-0.2C125.5,18.8,125.5,18.7,125.6,18.6z
                    M125.4,23.5L125.4,23.5l0,0.2L125.4,23.5L125.4,23.5z M126.3,19.6c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.3
                    c0.2-0.2,0.3-0.4,0.5-0.6c0.2-0.2,0.3-0.5,0.4-0.8c-0.1,0.1-0.2,0.2-0.3,0.5C126.5,19.5,126.4,19.6,126.3,19.6z M128.1,18.8
                    c-0.3,0.5-0.6,1-0.9,1.5c-0.3,0.5-0.6,1-1,1.5c0.1-0.1,0.3-0.2,0.5-0.5c0.2-0.3,0.4-0.6,0.6-0.9s0.3-0.6,0.5-0.9S128,19,128.1,18.8
                    z M128.9,18.4c-0.1,0.2-0.3,0.5-0.6,0.9c-0.3,0.4-0.6,0.9-0.9,1.4s-0.6,0.9-0.8,1.4s-0.4,0.7-0.4,0.8c0.2-0.2,0.4-0.5,0.7-0.9
                    c0.3-0.4,0.5-0.8,0.8-1.3c0.3-0.4,0.5-0.9,0.7-1.3S128.8,18.6,128.9,18.4z"/>
            </g>
        </svg>
        { props.isLayered &&
            <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 131 35" className={`sh-logo sh-logo-${props.size} sh-logo-layered`}>
            <g>
                <path d="M2,22.4c0.1,0,0.1,0.1,0.1,0.2c0,0.3-0.1,0.5-0.3,0.8L1.5,24c0-0.1,0.1-0.2,0.3-0.4C2,23.4,2.1,23.2,2.3,23
                    c0.2-0.2,0.3-0.4,0.4-0.6c0.1-0.2,0.2-0.3,0.2-0.3v0c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.2,0.2L2,22.1
                    c0.9-0.7,1.8-1.2,2.8-1.7c1-0.5,2-0.7,3.1-0.7c0.3,0,0.6,0.1,0.8,0.2c0.3,0.1,0.5,0.3,0.6,0.6l-0.2,0.1c0,0-0.2,0.1-0.4,0.2
                    c-0.2,0.1-0.5,0.1-0.7,0.2C7.7,20.9,7.5,21,7.2,21c-0.2,0.1-0.4,0.1-0.5,0.1c-0.4,0.2-0.8,0.4-1.2,0.7c-0.4,0.3-0.8,0.6-1.1,0.9
                    c-0.3,0.3-0.6,0.7-0.8,1.1c-0.2,0.4-0.3,0.9-0.3,1.3c0,0.3,0.1,0.7,0.4,1.1s0.5,0.7,0.7,0.9c1,0,2-0.2,3-0.4c1-0.3,1.8-0.7,2.6-1.2
                    c0.8-0.5,1.4-1.2,1.8-2c0.4-0.8,0.7-1.8,0.7-3c0-0.6-0.1-1.2-0.4-1.7c-0.3-0.5-0.6-1-1-1.4c-0.4-0.4-0.8-0.9-1.3-1.3
                    c-0.5-0.4-0.9-0.9-1.3-1.3c-0.4-0.5-0.7-1-1-1.5c-0.3-0.5-0.4-1.2-0.4-1.8c0-0.1,0-0.1,0-0.2s0-0.1,0-0.2c0-0.3,0.2-0.8,0.5-1.5
                    C8.2,8.9,8.6,8.3,9,7.6C9.5,7,10,6.4,10.4,6c0.5-0.5,0.9-0.7,1.3-0.7v0l0.2-0.1C12,5,12.3,4.7,12.6,4.5c0.4-0.2,0.8-0.4,1.3-0.6
                    c0.5-0.2,0.9-0.3,1.4-0.4c0.5-0.1,0.8-0.2,1.1-0.2c0.3,0,0.7,0.1,1,0.2c0.4,0.2,0.7,0.4,1,0.6c0.3,0.3,0.5,0.6,0.8,0.9
                    c0.2,0.3,0.4,0.6,0.4,0.9l0,0.1c0,0.2-0.2,0.5-0.4,1c-0.3,0.5-0.6,1-0.9,1.5c-0.4,0.5-0.7,1-1.1,1.4c-0.4,0.4-0.6,0.6-0.8,0.6
                    c0,0,0-0.1,0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.3s0.2-0.2,0.4-0.3c0.1-0.1,0.2-0.2,0.2-0.2c-0.1,0-0.3,0.2-0.5,0.3s-0.3,0.3-0.4,0.4
                    c-0.1,0-0.2,0.1-0.4,0.2s-0.3,0.2-0.4,0.2c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0-0.1,0.1-0.2,0.1-0.2s0.1-0.1,0.2-0.2l-0.6,0.5
                    c0.1-0.2,0.3-0.5,0.5-0.8c0.2-0.3,0.3-0.6,0.4-0.7l0.1-0.1c-0.1,0.1-0.1,0.2-0.2,0.3C16,9.7,15.9,9.9,15.8,10
                    c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.2-0.3,0.2c0.4-0.7,0.8-1.4,1.2-2.2c0.4-0.7,0.7-1.5,1.1-2.2c-0.6,0-1.1,0.2-1.6,0.6
                    c-0.5,0.4-1,0.8-1.4,1.4c-0.4,0.5-0.8,1.1-1.1,1.7c-0.3,0.6-0.6,1.1-0.9,1.5c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.3,0.1
                    l0.1,0.1c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0,0.1L12.1,12L12,12.2c0,0.1,0,0.2,0,0.4s0,0.2,0,0.4c0,0.4,0.1,0.8,0.4,1
                    c0.2,0.2,0.5,0.5,0.8,0.7c0.5,0.4,1,0.9,1.6,1.5c0.5,0.6,1.1,1.2,1.5,1.8c0.5,0.7,0.8,1.3,1.1,2c0.3,0.7,0.4,1.4,0.4,2.1
                    c0,0.2,0,0.5,0,0.8c0,0.3-0.1,0.5-0.2,0.7c-0.4,1.1-0.9,2-1.6,2.8c-0.7,0.8-1.6,1.4-2.5,1.9c-0.9,0.5-1.9,0.8-3,1.1
                    c-1.1,0.2-2.1,0.3-3.2,0.3c-0.7,0-1.5-0.1-2.2-0.2S3.7,29,3,28.7c-0.7-0.3-1.2-0.8-1.6-1.3c-0.4-0.6-0.6-1.3-0.6-2.2
                    c0-0.5,0.1-1.1,0.3-1.6c0.2-0.5,0.5-0.9,0.8-1.4L2,22.4z M2.5,23.6c0,0,0.1-0.1,0.1-0.2c0-0.1,0-0.2,0.1-0.2c0,0-0.1,0.1-0.1,0.2
                    C2.6,23.5,2.6,23.6,2.5,23.6z M3.4,23.4L3.7,23C3.5,23.1,3.4,23.3,3.4,23.4L3.4,23.4z M4.1,22.8L4.1,22.8c-0.3,0.3-0.4,0.5-0.5,0.8
                    c-0.1,0.2-0.1,0.6-0.2,1L4.1,22.8z M10.2,7.8c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.4-0.4,0.9-0.8,1.4-1.1
                    c0.5-0.3,1-0.7,1.5-1c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.3-0.2,0.3-0.4l-0.2,0l-0.2,0.1c-0.1,0-0.4,0.1-0.9,0.4
                    c-0.4,0.3-0.9,0.6-1.3,1c-0.5,0.4-0.9,0.7-1.2,1.1C10.4,7.5,10.2,7.8,10.2,7.8C10.2,7.9,10.2,7.9,10.2,7.8z M11.2,6.6
                    c-0.1,0-0.3,0.2-0.5,0.4c-0.2,0.3-0.4,0.4-0.5,0.5c0,0,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.2,0.3-0.3C10.8,7,10.9,6.9,11,6.8
                    C11.1,6.7,11.1,6.6,11.2,6.6z M13.3,6.3C13.2,6.7,13,7,12.7,7.2c-0.3,0.2-0.5,0.4-0.7,0.7C12.3,7.6,12.6,7.3,13,7
                    c0.4-0.3,0.7-0.6,1-1l-0.2,0.2l-0.2,0.2l0-0.1l0-0.1h-0.1L13.3,6.3L13.3,6.3z M12.9,24.1c0,0-0.1,0.1-0.2,0.2
                    c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.2,0.3-0.3,0.4c-0.1,0.1-0.1,0.2-0.2,0.3l0.2-0.1c0.2-0.2,0.3-0.4,0.5-0.6
                    C12.7,24.6,12.9,24.3,12.9,24.1z M12.8,23.7c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.1-0.1,0.2-0.1,0.3c0-0.1,0.1-0.2,0.2-0.3
                    C12.7,23.8,12.8,23.7,12.8,23.7z M14.2,8.1c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.1,0.3-0.3,0.5-0.4c0.2-0.1,0.3-0.2,0.5-0.4
                    s0.3-0.3,0.4-0.4C15.1,6.8,14.5,7.3,14,8c-0.5,0.6-0.9,1.3-1.4,2c0.2-0.2,0.4-0.4,0.5-0.6s0.3-0.4,0.5-0.6c0-0.1,0.1-0.2,0.2-0.4
                    C14,8.2,14.1,8.1,14.2,8.1z M12.7,6.9c0.1,0,0.1-0.1,0.2-0.2L12.7,6.9z M15.3,6.2c-0.1,0-0.2,0.1-0.5,0.4S14.3,7,14,7.2
                    c-0.3,0.3-0.5,0.5-0.7,0.8C13.1,8.3,13,8.4,13,8.5v0.1c0.5-0.5,0.8-0.8,1.1-1.1s0.5-0.6,0.8-0.8C15.1,6.4,15.2,6.2,15.3,6.2z
                    M14.3,24.5c0,0-0.1,0.1-0.1,0.1c0,0.1-0.1,0.1-0.1,0.2l0.2-0.2L14.3,24.5z M17.1,23.8c-0.3,0.5-0.7,1-1,1.4
                    c-0.3,0.5-0.7,1-0.9,1.5c0.1-0.1,0.3-0.3,0.5-0.5c0.2-0.2,0.4-0.5,0.7-0.8s0.4-0.6,0.5-0.8C17,24.3,17.1,24,17.1,23.8L17.1,23.8z
                    M16.1,24.1c-0.1,0.2-0.3,0.5-0.5,0.7c-0.2,0.2-0.3,0.5-0.5,0.7c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.3,0.3-0.4
                    c0.1-0.2,0.2-0.3,0.2-0.4C16.1,24.4,16.1,24.3,16.1,24.1z M16.7,9.7c-0.1,0-0.1,0.1-0.2,0.2h0c0,0,0,0,0,0c0,0,0,0,0,0L16.7,9.7z
                    M17.7,22.5c-0.2,0.2-0.3,0.4-0.3,0.6c-0.1,0.2-0.1,0.4-0.2,0.6v0.1c0.1,0,0.3-0.1,0.3-0.3c0.1-0.2,0.1-0.3,0.2-0.4
                    C17.7,22.9,17.7,22.7,17.7,22.5L17.7,22.5z M17.3,10.3c0,0,0-0.1,0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.1,0.2-0.2,0.3-0.4
                    c0.1-0.1,0.2-0.2,0.2-0.2c0,0,0,0.1-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.2-0.3,0.3C17.4,10.2,17.4,10.3,17.3,10.3z
                    M17.7,21.9l0.1,0.1l0-0.2L17.7,21.9z M18.5,6.6L18.5,6.6l0.2,0.1l0-0.1L18.5,6.6z"/>
                <path d="M27.6,17.2c-0.4,0.6-0.8,1.2-1.3,1.8c-0.5,0.6-0.9,1.1-1.4,1.7l0.3-0.6c-0.2,0.2-0.3,0.4-0.5,0.7c-0.2,0.3-0.3,0.5-0.4,0.8
                    l-0.3,0.3L23.9,22l-0.3,0.2c0,0.1-0.1,0.3-0.2,0.4c-0.1,0.2-0.2,0.3-0.3,0.3l-0.1,0.2v0.1c-0.3,0.4-0.7,0.8-1,1.3
                    c-0.3,0.4-0.7,0.8-1.2,1.1v-0.1l-0.1,0.1v0.1c-0.2,0.1-0.5,0.2-0.8,0.3c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0-0.3-0.1-0.5-0.2H19
                    c-0.1-0.2-0.1-0.5-0.1-0.8c0-0.3,0-0.6,0-0.9c0-0.6,0.1-1.3,0.3-2.1c0.2-0.8,0.4-1.7,0.7-2.6c0.3-0.9,0.6-1.9,0.9-2.9
                    c0.3-1,0.7-1.9,1-2.9c0.3-0.9,0.7-1.8,1-2.6c0.3-0.8,0.6-1.5,0.8-2.2c0.1-0.2,0.2-0.5,0.3-0.9c0.2-0.4,0.4-0.8,0.6-1.3
                    c0.2-0.4,0.4-0.8,0.7-1.1s0.4-0.5,0.6-0.5l0.1,0.1c0,0-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.1,0.2c0,0,0,0,0,0.1c0,0,0,0,0,0.1
                    l-0.1,0l-0.1,0.2l-0.2,0.2l0.1,0.1l0.2,0.1l0.1-0.1c0-0.1,0.1-0.3,0.3-0.5c0.1-0.2,0.2-0.3,0.4-0.3c0,0,0.1,0,0.1,0.1
                    c0,0,0,0.1,0,0.1v0.1l0.2,0.1l0.1-0.1c0.1,0,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.2,0.1,0.4c0,0.1,0.1,0.2,0.1,0.4c0,0.1,0,0.2,0,0.3
                    c0,0.2,0,0.5-0.1,0.7c0,0.2-0.1,0.5-0.1,0.7c0,0.1,0,0.2,0,0.3L27,8l0,0C27,8.1,27,8.2,27,8.3c0,0.1,0,0.2,0,0.3c0,0-0.1,0-0.1,0.1
                    c-0.1,0-0.1,0.1-0.1,0.1c0,0.1,0,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.4-0.1,1-0.3,1.8c-0.2,0.7-0.4,1.5-0.7,2.3
                    c-0.3,0.8-0.5,1.6-0.8,2.4c-0.3,0.8-0.5,1.4-0.6,1.9c0.3-0.3,0.7-0.8,1.2-1.3c0.5-0.5,1-1.1,1.6-1.6c0.5-0.5,1.1-1,1.7-1.4
                    c0.6-0.4,1.1-0.6,1.6-0.6c0.3,0,0.6,0.1,0.9,0.3c0.3,0.2,0.5,0.5,0.7,0.8c0.2,0.3,0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1
                    c0,0.2-0.1,0.6-0.2,1.1c-0.2,0.6-0.4,1.2-0.6,2c-0.3,0.7-0.6,1.5-0.9,2.4c-0.3,0.8-0.6,1.7-0.9,2.4s-0.5,1.5-0.8,2.1
                    c-0.2,0.6-0.4,1.1-0.5,1.4c0-0.6,0.1-1.2,0.3-2c0.2-0.8,0.5-1.6,0.8-2.4c0.3-0.8,0.6-1.6,1-2.4c0.3-0.8,0.6-1.5,0.9-2
                    c-0.4,0.7-0.7,1.4-1,2.1c-0.3,0.7-0.6,1.4-0.9,2.1c-0.2,0.5-0.4,1-0.6,1.6c-0.2,0.5-0.4,1-0.5,1.6c0,0.2-0.1,0.4-0.1,0.6
                    c0,0.2-0.1,0.4-0.1,0.6l-0.1,0.5l-0.1-0.1l-0.2,0l-0.5,0.3c-0.5-0.1-1-0.4-1.3-0.8c-0.3-0.4-0.5-0.9-0.5-1.5c0-0.7,0.1-1.4,0.3-2.2
                    c0.2-0.8,0.4-1.6,0.7-2.4c0.3-0.8,0.6-1.6,0.9-2.4c0.3-0.8,0.6-1.5,0.9-2.1l-0.5,0.4c0.1-0.1,0.1-0.3,0.2-0.4
                    c0.1-0.1,0.2-0.2,0.3-0.3c-0.6,0.6-1.2,1.2-1.8,1.9s-1.2,1.4-1.6,2.1c0.1-0.1,0.3-0.3,0.5-0.6c0.2-0.3,0.4-0.5,0.7-0.8
                    c0.2-0.3,0.4-0.6,0.7-0.8S27.5,17.2,27.6,17.2z M19.4,25.1c0.1-0.1,0.2-0.3,0.2-0.4c0-0.2,0.1-0.3,0.1-0.5c0.4-2.2,1-4.3,1.7-6.4
                    c0.7-2.1,1.3-4.2,2-6.3c-0.2,0.5-0.4,1.1-0.7,1.8c-0.3,0.7-0.5,1.5-0.8,2.3c-0.3,0.8-0.6,1.7-0.9,2.5c-0.3,0.9-0.6,1.7-0.8,2.5
                    c-0.2,0.8-0.4,1.6-0.6,2.2c-0.2,0.7-0.2,1.2-0.2,1.7V25.1z M20.8,25.7L20.8,25.7h-0.1H20.8L20.8,25.7z M22.7,23L22.7,23l-0.1,0
                    L22.7,23L22.7,23z M24.3,12.2c-0.1,0.1-0.2,0.3-0.3,0.7c-0.1,0.4-0.3,0.8-0.4,1.3c-0.2,0.5-0.3,0.9-0.4,1.3s-0.2,0.7-0.3,0.9
                    c0.3-0.7,0.6-1.3,0.8-2C24,13.6,24.2,12.9,24.3,12.2z M23.3,11.5L23.3,11.5l0.1-0.3l0,0L23.3,11.5z M23.5,17.8
                    c0.4-1,0.8-2.1,1.1-3.1c0.3-1,0.6-2.1,1-3.2c-0.4,1-0.8,2.1-1.1,3.1C24.1,15.7,23.8,16.8,23.5,17.8z M23.9,18.4
                    c0.3-0.6,0.6-1.2,0.7-1.9c0.2-0.7,0.4-1.4,0.6-2c0.3-0.9,0.6-1.8,0.9-2.7c0.3-0.9,0.5-1.8,0.7-2.7L26.8,9l-0.2,0.3
                    c-0.1,0.5-0.2,1.1-0.4,1.6c-0.2,0.5-0.4,1.1-0.5,1.6c-0.3,1-0.6,2-0.9,2.9C24.4,16.4,24.1,17.4,23.9,18.4L23.9,18.4z M23.9,19.3
                    c0,0,0.1-0.1,0.4-0.4c0.2-0.2,0.5-0.5,0.8-0.9c0.3-0.3,0.6-0.7,1-1.1c0.4-0.4,0.7-0.8,1-1.2s0.6-0.7,0.9-1c0.2-0.3,0.4-0.5,0.5-0.6
                    c-0.3,0.2-0.7,0.5-1.1,0.9s-0.9,0.9-1.4,1.4c-0.5,0.5-0.9,1-1.3,1.5C24.3,18.6,24.1,19,23.9,19.3z M26.2,18.2L26.2,18.2l0,0.2
                    L26.2,18.2L26.2,18.2z M26.2,6L26.2,6L26.2,6l0.1-0.1L26.2,6z M26.7,17.6c-0.1,0.1-0.2,0.2-0.2,0.3l0.2-0.2L26.7,17.6z M27.1,7.7
                    c0,0,0-0.1,0-0.2L27.1,7.7z M27.1,23.4c0-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.1-0.4,0.1-0.6v0c-0.1,0.2-0.1,0.4-0.2,0.6
                    C27.2,23,27.2,23.2,27.1,23.4z M27.6,16.3c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0.1,0.1l0-0.1v-0.1L27.6,16.3z M28,16.8
                    c0,0.1,0,0.1-0.1,0.2s-0.1,0.1-0.2,0.1c0,0,0.1-0.1,0.1-0.2S27.9,16.8,28,16.8z M27.9,24.8c-0.1,0-0.1,0.1,0,0.2V24.8z M28.8,21.7
                    c-0.2,0.5-0.3,0.9-0.5,1.4c-0.2,0.5-0.3,1-0.4,1.5c0,0,0.1-0.2,0.2-0.5c0.1-0.3,0.2-0.6,0.3-0.9c0.1-0.3,0.2-0.7,0.3-1
                    C28.8,21.9,28.8,21.7,28.8,21.7z M28.3,15.9L28.1,16l0.1,0L28.3,15.9z M28.3,18.8l0.2,0l0.1-0.1l-0.1-0.1L28.3,18.8z M30.8,19.4
                    c-0.5,1.1-0.9,2.3-1.4,3.5c-0.5,1.2-0.8,2.4-0.9,3.6c0.3-1.2,0.7-2.4,1.1-3.6C30.1,21.8,30.4,20.6,30.8,19.4z M28.6,18L28.6,18
                    L28.6,18l0.2,0L28.8,18L28.6,18z M29.4,17.1l0.1,0.2L29.4,17.1L29.4,17.1z M32.3,17.4L32.3,17.4h0.1H32.3l0-0.1L32.3,17.4z
                    M32.4,16.9L32.4,16.9L32.4,16.9v-0.1V16.9z M32.8,15.5c-0.1,0.2-0.1,0.4-0.2,0.6s-0.1,0.4-0.1,0.6c0,0,0.1-0.1,0.1-0.2
                    c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0.1-0.3,0.1-0.4C32.8,15.6,32.8,15.5,32.8,15.5L32.8,15.5z"/>
                <path d="M32.3,22.9c0-0.2,0-0.4,0-0.6c0-0.2,0-0.4,0.1-0.6c0.3-1,0.6-2,0.9-2.9c0.3-0.9,0.8-1.8,1.5-2.7c0.3-0.4,0.7-0.9,1.2-1.3
                    c0.4-0.4,0.9-0.8,1.4-1.1c0.5-0.3,1-0.6,1.6-0.8c0.6-0.2,1.1-0.3,1.7-0.3c0.7,0,1.3,0.2,1.7,0.5c0.4,0.4,0.8,0.8,1.1,1.4
                    c0.1-0.1,0.3-0.2,0.5-0.4c0.2-0.2,0.4-0.3,0.6-0.4c0.2-0.1,0.4-0.3,0.6-0.4c0.2-0.1,0.4-0.2,0.5-0.2c0.1,0,0.1,0,0.1,0
                    c0,0.1,0,0.1-0.1,0.2c0,0.1-0.1,0.1-0.1,0.1s0,0,0,0s0,0,0,0c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0c0,0,0,0.1-0.1,0.1
                    c0,0.1,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0l0.3,0.1l0.1,0.3c-0.1,0.3-0.2,0.5-0.3,0.8s-0.2,0.5-0.4,0.7l0.1-0.1
                    c-0.2,0.7-0.5,1.4-0.8,2.2c-0.4,0.8-0.7,1.7-1.1,2.5c-0.4,0.9-0.7,1.7-1,2.5c-0.3,0.8-0.4,1.5-0.4,2.1c0,0.2,0,0.4,0.1,0.5
                    s0.1,0.2,0.2,0.3s0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.1,0.3c-0.2,0.3-0.6,0.4-0.9,0.5c-0.4,0.1-0.8,0.1-1.1,0.1
                    c-0.4,0-0.7-0.1-1-0.3c-0.2-0.2-0.4-0.4-0.6-0.7c-0.1-0.3-0.2-0.6-0.3-0.9c0-0.3-0.1-0.7-0.1-1c0-0.2,0-0.3,0-0.4
                    c0-0.1,0-0.3,0-0.4c-0.2,0.2-0.5,0.5-0.8,0.9c-0.3,0.3-0.7,0.7-1,1c-0.4,0.3-0.7,0.6-1.1,0.9c-0.4,0.2-0.7,0.4-1.1,0.4
                    c-0.4,0-0.8,0-1.1-0.1c-0.3,0-0.6-0.1-0.8-0.3c-0.2-0.1-0.4-0.3-0.6-0.5c-0.2-0.2-0.4-0.5-0.6-0.9c0-0.2,0-0.4,0-0.6
                    C32.3,23.4,32.3,23.2,32.3,22.9z M35.4,16.9h-0.2l0,0L35.4,16.9z M35.3,21.8L35.3,21.8L35.3,21.8L35.3,21.8L35.3,21.8z M35.5,23
                    c0.2-0.2,0.3-0.4,0.4-0.6c0.1-0.3,0.2-0.5,0.2-0.7l-0.6,0.9L35.5,23z M42,15.8c-0.5,0-1.1,0.2-1.6,0.7c-0.5,0.4-1,1-1.5,1.6
                    s-0.9,1.2-1.3,1.8c-0.4,0.6-0.8,1.1-1.1,1.5c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.2,0.4-0.3,0.7s-0.2,0.5-0.3,0.7
                    c-0.1,0.2-0.1,0.4-0.1,0.5v0c0.2,0,0.5-0.2,0.9-0.5c0.4-0.3,0.9-0.7,1.4-1.3c0.5-0.5,1-1.1,1.5-1.7s1-1.2,1.5-1.8
                    c0.4-0.6,0.8-1.1,1.1-1.6c0.3-0.5,0.4-0.8,0.4-1c0-0.1,0-0.2-0.1-0.2C42.1,15.8,42,15.8,42,15.8z M35.6,16.6L35.6,16.6v0.2V16.6
                    L35.6,16.6z M39.6,13.7c-0.3,0-0.6,0.1-0.9,0.3c-0.3,0.2-0.7,0.4-1,0.6c-0.3,0.2-0.6,0.5-0.9,0.8c-0.3,0.3-0.5,0.5-0.6,0.7
                    c0.1,0,0.2-0.1,0.3-0.2s0.3-0.3,0.5-0.4s0.3-0.3,0.5-0.4c0.2-0.1,0.3-0.2,0.4-0.3c0.1,0,0.2-0.1,0.4-0.2c0.2-0.1,0.4-0.2,0.5-0.3
                    c0.2-0.1,0.4-0.2,0.5-0.3S39.5,13.7,39.6,13.7z M37.3,14.7c-0.1,0-0.2,0.1-0.4,0.3c-0.2,0.2-0.3,0.2-0.3,0.3L37.3,14.7z M41.5,15.6
                    c-0.6,0.2-1.2,0.5-1.6,0.8s-0.9,0.7-1.3,1.1c-0.4,0.4-0.7,0.9-1,1.4c-0.3,0.5-0.6,1-0.9,1.6c0.1-0.2,0.3-0.4,0.4-0.6
                    s0.2-0.4,0.4-0.6c0.3-0.5,0.7-0.9,0.9-1.3c0.3-0.3,0.6-0.6,0.9-0.9c0.3-0.2,0.6-0.5,1-0.7C40.6,16.2,41,15.9,41.5,15.6z M36.8,23.5
                    c0.1,0,0.2-0.1,0.4-0.3c0.2-0.2,0.4-0.4,0.7-0.7c0.3-0.3,0.5-0.6,0.9-0.9c0.3-0.3,0.6-0.6,0.8-0.9c0.2-0.3,0.5-0.5,0.6-0.8
                    c0.2-0.2,0.3-0.4,0.3-0.4l0,0c-0.2,0.2-0.5,0.5-0.9,0.8s-0.7,0.7-1,1.1s-0.7,0.8-1,1.1C37.3,22.9,37,23.2,36.8,23.5z M37.4,16.6
                    c0.1,0,0.2-0.1,0.3-0.2c0.2-0.1,0.3-0.3,0.5-0.4c0.2-0.2,0.4-0.3,0.5-0.4c0.2-0.1,0.3-0.3,0.4-0.3c-0.3,0.2-0.7,0.4-0.9,0.6
                    C37.9,16,37.6,16.3,37.4,16.6z M37.5,18.2c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1-0.1,0.1-0.2c0.4-0.5,0.9-0.9,1.4-1.3
                    c0.5-0.4,1-0.8,1.5-1.3c-0.2,0-0.5,0.1-0.8,0.4c-0.3,0.2-0.6,0.5-0.9,0.8c-0.3,0.3-0.6,0.6-0.9,0.9C37.8,17.8,37.6,18,37.5,18.2z
                    M39.5,22.6c0,0-0.1,0.1-0.2,0.2c-0.1,0.2-0.3,0.3-0.5,0.5s-0.3,0.4-0.5,0.6c-0.2,0.2-0.3,0.4-0.4,0.5c0.3-0.3,0.6-0.6,0.8-0.9
                    C39,23.2,39.2,22.9,39.5,22.6z M39.6,22.1c-0.2,0.2-0.4,0.4-0.5,0.6c-0.2,0.2-0.3,0.4-0.5,0.6c0.1-0.1,0.1-0.1,0.3-0.2
                    c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.2,0.3-0.4C39.5,22.2,39.6,22.1,39.6,22.1z M39.8,21.1l-0.1,0.1l0,0.1l0.1,0L39.8,21.1
                    L39.8,21.1z M40.3,22.1c0,0.1,0,0.1,0,0.2L40.3,22.1z M40.8,21.2c-0.1,0.3-0.2,0.5-0.3,0.8c-0.1,0.3-0.1,0.5-0.2,0.8
                    c0.1-0.2,0.2-0.5,0.3-0.8C40.7,21.8,40.8,21.5,40.8,21.2L40.8,21.2z M41,20.7L41,20.7l-0.1,0.2L41,20.7z M43.3,18.1
                    c-0.2,0.3-0.4,0.8-0.6,1.3c-0.2,0.5-0.4,1-0.7,1.6s-0.4,1.1-0.5,1.6c-0.1,0.5-0.2,1-0.2,1.3V24c0.1-0.1,0.1-0.2,0.1-0.4
                    c0-0.1,0-0.3,0.1-0.4c0.2-0.9,0.4-1.7,0.8-2.6C42.6,19.7,43,18.9,43.3,18.1z M42.1,24.8c0.2-1,0.5-2,0.8-2.9c0.3-1,0.7-1.9,1-2.9
                    c-0.4,1-0.8,1.9-1.1,2.9C42.5,22.8,42.3,23.8,42.1,24.8z M43,16.6c0,0-0.1,0.1-0.2,0.2c-0.1,0.1-0.1,0.2-0.2,0.4s-0.1,0.2-0.2,0.4
                    s-0.1,0.2-0.1,0.2c0.1-0.1,0.2-0.3,0.4-0.6C42.8,16.9,42.9,16.7,43,16.6z M42.5,16.9c-0.1,0.1-0.1,0.1-0.2,0.2
                    c-0.1,0.1-0.1,0.1-0.1,0.2l0.2-0.2L42.5,16.9z M42.6,24.8c0.4-1.6,0.9-3.1,1.6-4.6c0.6-1.5,1.3-3,1.8-4.5c-0.2,0.5-0.5,1.1-0.9,1.9
                    c-0.4,0.8-0.8,1.7-1.2,2.6s-0.7,1.7-1,2.6c-0.3,0.8-0.4,1.5-0.4,2L42.6,24.8L42.6,24.8z M43.5,17.8c0-0.1,0.1-0.1,0.1-0.2
                    c0-0.1,0-0.2,0-0.3l-0.2,0.3V17.8z M45.4,13.8c-0.1,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.3,0.3-0.5,0.5s-0.3,0.4-0.4,0.5
                    c-0.1,0.2-0.2,0.3-0.3,0.4h0.2c0.2-0.3,0.5-0.5,0.8-0.8C45,14.4,45.2,14.1,45.4,13.8z M43.9,16.9c0,0,0.1-0.1,0.2-0.3
                    c0.1-0.2,0.1-0.3,0.2-0.4v-0.1c-0.1,0-0.2,0.1-0.3,0.3C44,16.6,44,16.8,43.9,16.9z M44.5,17.8l-0.1,0.2l0.1-0.1L44.5,17.8z
                    M44.6,17.6l-0.1,0.1l0,0.1L44.6,17.6L44.6,17.6z M45.5,14.5c0,0-0.1,0.1-0.2,0.2c-0.1,0.1-0.2,0.2-0.3,0.4s-0.2,0.3-0.3,0.4
                    c-0.1,0.1-0.1,0.2-0.2,0.3c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.1,0.2-0.3,0.2-0.4
                    C45.4,14.6,45.5,14.6,45.5,14.5z M46.6,14.1c-0.1,0-0.2,0.1-0.3,0.4C46.1,14.7,46,14.9,46,15C46.3,14.7,46.5,14.4,46.6,14.1z
                    M46.7,14.5c0,0.1-0.1,0.2-0.2,0.5c-0.1,0.2-0.2,0.4-0.3,0.4L46.7,14.5z"/>
                <path d="M62.1,6.4c0.1,0.1,0.1,0.2,0.1,0.4v0l-0.4,0.5l0.2,0C62,7.5,62,7.7,62,7.9c0,0.2,0,0.4-0.1,0.6c0,0.1-0.1,0.3-0.2,0.5
                    c-0.1,0.2-0.2,0.4-0.2,0.7s-0.1,0.5-0.2,0.7c-0.1,0.2-0.1,0.4-0.1,0.4L61,11l0.1,0.1c-0.3,1.1-0.7,2.1-1.1,3.1
                    c-0.4,1-0.9,2.1-1.3,3.1c-0.4,1-0.8,2.1-1.1,3.1c-0.3,1.1-0.6,2.1-0.8,3.2l0,0.1c0,0,0.1-0.2,0.2-0.4c0.1-0.2,0.2-0.5,0.3-0.9
                    c0.1-0.3,0.2-0.7,0.4-1.1s0.3-0.7,0.4-1.1c0.1-0.3,0.2-0.6,0.3-0.8c0.1-0.2,0.1-0.3,0.1-0.4l0.1-0.2c0.1-0.3,0.2-0.5,0.3-0.8
                    c0.1-0.2,0.2-0.5,0.3-0.8c0-0.2,0.1-0.5,0.3-0.9c0.1-0.4,0.3-0.8,0.4-1.3c0.2-0.4,0.3-0.9,0.5-1.3c0.2-0.4,0.3-0.6,0.4-0.8l0-0.2
                    c0-0.1,0-0.2,0.1-0.4s0.2-0.2,0.3-0.3c0,0.1,0,0.2,0,0.3c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.2-0.1,0.5-0.2,1.1c-0.2,0.5-0.4,1.2-0.7,1.9
                    c-0.3,0.7-0.6,1.5-0.9,2.3c-0.3,0.8-0.6,1.7-0.9,2.5c-0.3,0.8-0.5,1.6-0.7,2.3c-0.2,0.7-0.3,1.3-0.3,1.7c0,0,0,0.2,0,0.3
                    s0.1,0.3,0.2,0.3c0.2,0,0.4-0.1,0.7-0.4s0.6-0.5,0.8-0.8s0.5-0.6,0.7-0.8c0.2-0.2,0.4-0.4,0.4-0.4v0c0,0,0,0.1,0,0.1
                    c0,0.1,0,0.1,0,0.1c0,0,0.1,0,0.1-0.1c0.1-0.1,0.1-0.1,0.2-0.1c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.1,0.2,0.2l-0.1,0.2l-0.1,0.1
                    c-0.2,0.4-0.4,0.8-0.7,1.2c-0.3,0.4-0.6,0.7-0.9,1.1c-0.3,0.3-0.7,0.5-1.1,0.7c-0.4,0.2-0.9,0.3-1.4,0.3c-0.5,0-0.9-0.1-1.2-0.3
                    s-0.5-0.5-0.7-0.8c-0.2-0.3-0.3-0.7-0.5-1.1c-0.1-0.4-0.3-0.8-0.5-1.1c-0.1,0.2-0.3,0.5-0.5,0.7c-0.2,0.2-0.4,0.4-0.6,0.5
                    c-0.2,0.2-0.4,0.3-0.7,0.5c-0.2,0.2-0.4,0.4-0.6,0.6c-0.3,0.1-0.6,0.2-1,0.2s-0.7,0-1,0c-0.5,0-0.9-0.1-1.4-0.3
                    c-0.4-0.2-0.8-0.5-1.1-0.8c-0.3-0.3-0.5-0.7-0.7-1.2c-0.2-0.4-0.2-0.9-0.2-1.4c0-1,0.3-2,0.8-3.2c0.5-1.1,1.2-2.2,2.1-3.2
                    s1.8-1.9,2.8-2.5c1-0.7,2.1-1,3.1-1c0.2,0,0.5,0.1,0.9,0.2c0.4,0.1,0.7,0.3,0.8,0.4c0.1-0.2,0.2-0.6,0.4-1c0.2-0.5,0.5-1,0.8-1.5
                    c0.3-0.5,0.6-1.1,1-1.6c0.4-0.6,0.7-1.1,1.1-1.5c0.3-0.5,0.7-0.8,1-1.1c0.3-0.3,0.6-0.4,0.9-0.4l0.1,0.1c-0.1,0.2-0.3,0.4-0.5,0.5
                    l-0.2,0.4V6.4c-0.1,0.2-0.3,0.4-0.4,0.6c-0.1,0.2-0.3,0.4-0.5,0.6c0,0,0.1,0,0.2,0c0.1,0,0.1,0,0.1,0.1l0.3-0.4V7.2l0.2-0.1
                    l0.9-0.9l0.2,0.3l0.2-0.4L62,6.1l0,0l-0.1,0.2L62.1,6.4z M47.1,19.8L47.1,19.8L47,19.9L47,20L47.1,19.8z M47.4,19.4
                    c0.1-0.1,0.2-0.3,0.3-0.5c0.1-0.3,0.3-0.5,0.4-0.8s0.3-0.5,0.5-0.7c0.2-0.2,0.3-0.4,0.3-0.4c0.1-0.1,0.2-0.4,0.4-0.6
                    c0.2-0.3,0.5-0.6,0.7-0.9c0.3-0.3,0.5-0.5,0.7-0.8s0.3-0.4,0.3-0.4v0c-0.2,0-0.4,0.2-0.8,0.6c-0.3,0.4-0.7,0.8-1.1,1.3
                    c-0.4,0.5-0.7,1-1,1.4c-0.3,0.5-0.5,0.8-0.6,1v-0.1c0-0.1,0-0.2,0-0.2l-0.2,0.2l0,0.2h0.1c0,0.1-0.1,0.2-0.1,0.4
                    c0,0.1-0.1,0.2-0.2,0.3l0,0.3l0,0.1L47.4,19.4L47.4,19.4z M47.7,18.1c0.1-0.1,0.1-0.2,0.1-0.3L47.7,18.1z M48.4,19.7L48.4,19.7
                    l-0.2,0l0.1,0.1L48.4,19.7z M48.7,19c0.3-0.4,0.7-0.9,1-1.4c0.3-0.5,0.6-1,0.8-1.5c-0.1,0-0.2,0.1-0.4,0.4
                    c-0.2,0.3-0.4,0.6-0.6,0.9c-0.2,0.3-0.4,0.7-0.5,1C48.8,18.7,48.8,18.9,48.7,19z M50.4,19c-0.1,0.1-0.1,0.2-0.2,0.5
                    c-0.1,0.2-0.2,0.5-0.2,0.8c-0.1,0.3-0.2,0.5-0.2,0.8c-0.1,0.3-0.1,0.5-0.2,0.6c0.2-0.3,0.3-0.7,0.5-1.1s0.2-0.7,0.4-1.1
                    c0.1-0.4,0.3-0.7,0.4-1.1c0.2-0.3,0.4-0.7,0.6-1c0.3-0.4,0.6-0.7,0.9-1.1c0.3-0.4,0.6-0.7,0.9-1.1c-0.2,0.1-0.4,0.4-0.7,0.7
                    c-0.3,0.3-0.6,0.7-0.9,1.1s-0.5,0.8-0.8,1.1C50.4,18.5,50.3,18.8,50.4,19z M49.7,23.9c0.2,0,0.6-0.2,1-0.6c0.4-0.4,0.9-0.8,1.3-1.3
                    c0.4-0.5,0.8-0.9,1.2-1.4c0.3-0.5,0.5-0.8,0.6-1c0-0.2,0.2-0.7,0.3-1.2c0.2-0.6,0.4-1.2,0.6-1.8c0.2-0.6,0.4-1.1,0.5-1.6
                    c0.2-0.5,0.2-0.7,0.2-0.8v-0.1c-0.2,0-0.4,0.1-0.5,0.1c-0.2,0.1-0.4,0.1-0.5,0.1c-0.6,0.4-1.2,0.9-1.8,1.6s-1.1,1.5-1.5,2.4
                    c-0.4,0.9-0.8,1.7-1.1,2.6c-0.3,0.9-0.4,1.7-0.4,2.4c0,0.1,0,0.2,0,0.3S49.7,23.8,49.7,23.9z M52,15.7c-0.1,0-0.3,0.2-0.5,0.5
                    s-0.5,0.7-0.7,1.1c-0.2,0.4-0.5,0.8-0.7,1.2c-0.2,0.4-0.3,0.7-0.4,0.8c0.1-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.2,0.1-0.2
                    c0.3-0.6,0.6-1.1,0.9-1.7C51.2,16.7,51.6,16.2,52,15.7L52,15.7z M52.3,22.4c0.2-0.2,0.5-0.5,0.7-0.8c0.3-0.3,0.4-0.6,0.5-0.9
                    c-0.1,0-0.2,0.1-0.3,0.3c-0.1,0.2-0.2,0.3-0.4,0.5s-0.3,0.3-0.4,0.5C52.5,22.2,52.4,22.3,52.3,22.4z M55.1,17.8L55.1,17.8l0,0.2
                    L55.1,17.8L55.1,17.8z M55.3,17.7c0.1-0.1,0.2-0.2,0.2-0.2l-0.2,0l-0.1,0.1L55.3,17.7z M55.6,16.8l0.1-0.2l-0.1-0.1V16.8L55.6,16.8
                    z M55.8,16.2l0-0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0.1,0,0.1l0,0.1L55.8,16.2z M55.9,15.9c0.1,0,0.2,0,0.2-0.1c0.1-0.1,0.1-0.1,0.2-0.1
                    l-0.2,0.1l-0.1-0.1v-0.3H56l0,0.2c-0.1,0.1-0.1,0.2-0.2,0.2L55.9,15.9z M56,20.2l-0.2,0.1l0,0.1L56,20.2L56,20.2z M56.2,19.5
                    L56.2,19.5l0,0.1L56.2,19.5z M56.4,23c0.1-0.5,0.3-1.1,0.5-1.6c0.2-0.5,0.4-1,0.6-1.6c0.2-0.5,0.4-1,0.6-1.6
                    c0.2-0.5,0.3-1.1,0.4-1.6c-0.4,1-0.9,2-1.3,3.1S56.5,22,56.4,23z M56.5,18.9L56.5,18.9L56.4,19l0.1,0L56.5,18.9z M57,13.4l0.4-0.3
                    c-0.1-0.1-0.1-0.2-0.1-0.2v0L57,12.9l-0.1,0.1v0.1l-0.1,0.2l0.1,0.2L57,13.4z M57.2,13.6l0.1-0.2v-0.2c-0.1,0.1-0.2,0.2-0.2,0.4
                    l0,0.2l0.1-0.1V13.6z M57.2,12.5L57.2,12.5l0.1,0L57.2,12.5L57.2,12.5L57.2,12.5z M57.4,12c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.1
                    L57.4,12z M57.5,15.4l0,0.1L57.5,15.4L57.5,15.4L57.5,15.4z M57.6,15.6l-0.1,0.2h0L57.6,15.6L57.6,15.6z M57.8,14.8L57.8,14.8
                    L57.8,14.8l0.1,0.1v0L57.8,14.8z M58.2,13.6l0.1,0C58.3,13.6,58.3,13.6,58.2,13.6C58.3,13.6,58.3,13.6,58.2,13.6L58.2,13.6
                    L58.2,13.6z M58.4,13.1L58.4,13.1l0.2,0.1l0,0v-0.2L58.4,13.1L58.4,13.1z M58.6,16.4l-0.1,0.1l0,0.1L58.6,16.4L58.6,16.4z M59,12.6
                    l-0.2,0.1v0l0.1,0L59,12.6z M58.9,12.4L58.9,12.4l0-0.2L58.9,12.4L58.9,12.4z M59.1,15l0-0.1l-0.1-0.1L59.1,15L59.1,15z M59.3,11.3
                    l-0.1-0.1l-0.1,0.1L59.3,11.3L59.3,11.3z M59.5,10.8l-0.1,0.1l0.1,0.1L59.5,10.8L59.5,10.8z M59.8,10.2L59.8,10.2L59.8,10.2
                    l-0.1,0.2L59.8,10.2z M60.2,7.3L60.2,7.3l0,0.2L60.2,7.3L60.2,7.3z M60.2,9.2l-0.2-0.1L60.2,9.2L60.2,9.2z M60.2,11.5
                    C60.2,11.5,60.2,11.5,60.2,11.5v-0.2V11.5z M60.4,11c-0.1,0-0.1,0.1-0.1,0.2v0L60.4,11L60.4,11z M61.4,7.2c0-0.1,0-0.2,0-0.2
                    c0-0.1,0.1-0.2,0.1-0.2c-0.2,0.2-0.4,0.4-0.5,0.7c-0.2,0.2-0.2,0.5-0.2,0.8v0c0-0.1,0.1-0.1,0.1-0.2C60.9,7.9,61,7.8,61,7.8
                    c0,0,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2L61.4,7.2z M61,10.1L61,10.1l-0.2,0l0.1,0L61,10.1z M60.9,12.3c0-0.1,0.1-0.2,0.1-0.2l0.1-0.1
                    l0.1,0.1c0,0,0,0.1-0.1,0.2C61,12.3,61,12.3,60.9,12.3L60.9,12.3z M61,6.9L61,6.9L61,6.9L61,6.9L61,6.9z M61.3,11.1
                    c0,0.1,0,0.2-0.1,0.4c-0.1,0.1-0.1,0.2-0.1,0.3l0-0.1c0-0.1,0.1-0.2,0.1-0.3C61.3,11.3,61.3,11.2,61.3,11.1z M61.8,5.9L61.7,6
                    l-0.3,0.1L61.8,5.9z M61.5,9.3L61.5,9.3l-0.2,0L61.5,9.3L61.5,9.3z M61.7,7.8L61.7,7.8h0.2v0L61.7,7.8z M62.2,7.3L62.2,7.3
                    L62.2,7.3L62.2,7.3z"/>
                <path d="M64.2,26.3c-0.6,0-1.1-0.1-1.6-0.4s-0.9-0.6-1.3-1.1c-0.4-0.4-0.6-0.9-0.8-1.5c-0.2-0.5-0.3-1.1-0.3-1.6
                    c0-0.9,0.2-2,0.6-3.2c0.4-1.2,0.9-2.3,1.6-3.3c0.7-1,1.5-1.9,2.4-2.6s1.9-1.1,3-1.1c1,0,2,0.3,2.9,0.8c0.9,0.6,1.5,1.3,1.9,2.3
                    l-0.1,0.2c0.1,0.1,0.1,0.2,0.1,0.3c0,0.4-0.1,0.8-0.2,1.2c-0.1,0.4-0.2,0.8-0.2,1.2v0.1l0.1,0l0.1-0.2c0,0,0,0,0,0.1c0,0,0,0,0,0.1
                    c0,0.3,0,0.6-0.1,0.9c-0.1,0.3-0.2,0.7-0.3,0.9c-0.3,0.8-0.7,1.6-1.2,2.4s-1.1,1.5-1.8,2.2c-0.7,0.6-1.5,1.2-2.3,1.6
                    C66,26.1,65.1,26.3,64.2,26.3z M65.2,13.5c-0.1,0.2-0.3,0.5-0.5,0.7c-0.2,0.2-0.5,0.4-0.7,0.7c-0.3,0.2-0.5,0.5-0.8,0.9
                    c-0.2,0.3-0.4,0.8-0.6,1.3c0.1-0.1,0.1-0.1,0.2-0.2s0.1-0.1,0.1-0.2c0.4-0.6,0.8-1.1,1.3-1.6c0.5-0.5,0.9-1,1.4-1.5L65.2,13.5z
                    M62.9,15.8c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.1,0.3-0.2,0.3L62.9,15.8z M63.1,15.4L63.1,15.4L63.1,15.4L63.1,15.4L63.1,15.4z
                    M63.7,17.6c0.2-0.3,0.5-0.7,0.7-1.1c0.2-0.4,0.4-0.7,0.7-1.1c-0.1,0-0.2,0.1-0.3,0.3c-0.1,0.2-0.3,0.4-0.4,0.7
                    c-0.1,0.2-0.3,0.5-0.4,0.7S63.7,17.5,63.7,17.6z M64.5,19.2L64.5,19.2C64.4,19.2,64.4,19.2,64.5,19.2c-0.1,0-0.1,0-0.1,0.1
                    L64.5,19.2L64.5,19.2z M66.9,14.8c-0.5,0.6-1,1.2-1.3,1.8s-0.7,1.3-1,2l0,0h0l0.7-1.4c0.2-0.4,0.5-0.8,0.8-1.2
                    C66.5,15.7,66.7,15.3,66.9,14.8z M68.3,14.3c-0.3,0.1-0.7,0.4-1.1,0.9c-0.4,0.5-0.8,1.1-1.2,1.7c-0.4,0.6-0.7,1.2-1,1.9
                    c-0.3,0.6-0.4,1.1-0.4,1.4c0,0,0,0.1,0,0.1c0.2-0.4,0.3-0.8,0.4-1.2c0.1-0.4,0.3-0.8,0.5-1.2c0.3-0.7,0.8-1.3,1.3-1.8
                    C67.4,15.5,67.9,14.9,68.3,14.3z M69.8,15.4c0-0.3,0-0.6,0-0.8s0-0.5-0.1-0.8c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.4,0.2
                    c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.2-0.4,0.4-0.6,0.5c-0.2,0.2-0.3,0.3-0.4,0.4c-0.3,0.4-0.7,0.8-1,1.4c-0.3,0.6-0.6,1.2-0.9,1.8
                    c-0.3,0.6-0.5,1.3-0.7,1.9s-0.3,1.2-0.3,1.7c0,0.1,0,0.2,0,0.4c0,0.2,0,0.4,0,0.7c0,0.2,0.1,0.4,0.1,0.6c0.1,0.2,0.1,0.3,0.2,0.3
                    c0.1,0,0.1,0,0.2-0.1c0.1-0.1,0.2-0.1,0.2-0.1c0.7-0.5,1.3-1,1.7-1.6c0.4-0.6,0.7-1.3,1-2c0.3-0.7,0.5-1.4,0.7-2.1
                    c0.2-0.7,0.5-1.4,0.8-2.2V15.4z M67.8,22.6c0.1-0.1,0.3-0.3,0.4-0.5s0.3-0.5,0.5-0.8c0.2-0.3,0.3-0.6,0.4-0.9
                    c0.1-0.3,0.2-0.5,0.2-0.7l-0.1-0.2l-0.3,0.6l0.3-0.5c-0.2,0.5-0.4,1-0.7,1.5S68,22.1,67.8,22.6z M70,20c0,0.2-0.1,0.4-0.2,0.7
                    c-0.1,0.2-0.2,0.4-0.3,0.7v0c0,0,0.1-0.1,0.2-0.2c0.1-0.2,0.2-0.3,0.2-0.5c0.1-0.2,0.2-0.4,0.2-0.6c0.1-0.2,0.1-0.3,0.2-0.4L70,20z
                    M72.2,18c-0.1,0-0.1,0-0.1,0.1c0,0.1,0,0.1-0.1,0.2c-0.3,0.8-0.6,1.6-1,2.3c-0.4,0.7-0.8,1.5-1.1,2.3c0.2-0.3,0.4-0.6,0.6-1
                    c0.2-0.4,0.4-0.7,0.5-1.1c0.2-0.4,0.3-0.7,0.5-1c0.1-0.3,0.3-0.6,0.5-0.8c0-0.2,0.1-0.3,0.1-0.5S72.2,18.2,72.2,18z M71.1,19.6
                    c-0.2,0.4-0.4,0.8-0.6,1.3c-0.2,0.4-0.4,0.8-0.6,1.3c0.1,0,0.2-0.2,0.3-0.5s0.3-0.6,0.5-0.9s0.3-0.7,0.4-1c0.1-0.3,0.2-0.5,0.2-0.6
                    v0c0,0.1-0.1,0.2-0.2,0.3l0-0.1l-0.1,0L71.1,19.6z M70.4,19.4L70.4,19.4l0.2,0v-0.1L70.4,19.4L70.4,19.4z"/>
                <path d="M77.3,25.8c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.5,0.2-0.7,0.3s-0.5,0.1-0.8,0.2c-0.3,0-0.4,0.1-0.6,0.1
                    c-0.1,0-0.2,0-0.2-0.1l-0.2-0.1c-0.1,0-0.1,0-0.2-0.1C74,26.2,74,26.1,74,26c0-0.1-0.1-0.2-0.1-0.3s0-0.2,0-0.2
                    c-0.1-0.5-0.2-1-0.2-1.5c-0.1-0.5-0.1-1-0.1-1.5c0-0.2,0-0.6,0.1-1.2c0.1-0.6,0.1-1.2,0.2-2s0.2-1.5,0.3-2.3
                    c0.1-0.8,0.2-1.6,0.4-2.3c0.1-0.7,0.2-1.3,0.3-1.8s0.2-0.8,0.3-0.9c0.1-0.2,0.3-0.2,0.4-0.2c0.1,0,0.1,0,0.2,0.1l0.2,0
                    c0.1,0,0.3,0,0.5,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.4,0.3,0.5,0.5c0.2,0.2,0.2,0.3,0.2,0.5v0.1c0.1,0.1,0.2,0.3,0.4,0.5
                    c0.1,0.3,0.2,0.5,0.2,0.6c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.1,0.3c0,0.2-0.1,0.3-0.1,0.4c0,0.1,0,0.1,0,0.1h0l0.1,0.1
                    l-0.1,0.2l0.1,0l0.2,1c0,0,0,0.3-0.1,0.6s-0.1,0.8-0.1,1.3s-0.1,0.9-0.1,1.3s0,0.7,0,0.7c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0.1,0.2
                    v-0.1c0.2-0.3,0.4-0.6,0.6-0.9c0.2-0.3,0.5-0.6,0.7-0.9c0.7-0.9,1.2-1.9,1.4-2.9c0.2-1.1,0.4-2.2,0.5-3.3c0-0.1,0-0.2,0-0.4
                    c0-0.2,0-0.3,0-0.5c0-0.2,0-0.3,0-0.5c0-0.2,0-0.3,0.1-0.3c0-0.1,0.1-0.2,0.3-0.2s0.3-0.1,0.3-0.2c1,0.4,1.7,1,2.1,1.9
                    c0.4,0.8,0.6,1.8,0.7,2.7c0,0,0,0.1,0,0.2c0,0.1,0.1,0.2,0.1,0.3l-0.1,0.1v0.2l0.2,0l0,0v0.1c0,0,0,0,0,0c0,0.4,0,0.8-0.1,1.2
                    c-0.1,0.4-0.1,0.8-0.1,1.2v0.2c0.2-0.3,0.6-0.8,1.1-1.4s1-1.3,1.4-2.1c0.5-0.7,0.9-1.4,1.3-2.1c0.4-0.7,0.5-1.2,0.5-1.5
                    c0-0.1,0-0.2-0.1-0.3s-0.1-0.2-0.1-0.3l0-0.2l0-0.1c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2s0.3-0.1,0.4-0.1
                    c0.2,0,0.4,0.1,0.6,0.4s0.3,0.5,0.4,0.9c0.1,0.3,0.2,0.7,0.2,1c0.1,0.3,0.1,0.6,0.1,0.7c0,0.3-0.1,0.7-0.2,1
                    c-0.1,0.3-0.2,0.7-0.4,1c-0.2,0.3-0.3,0.6-0.4,1c-0.1,0.3-0.2,0.7-0.3,1c0.1-0.2,0.2-0.3,0.2-0.5c0.1-0.2,0.1-0.3,0.2-0.5
                    c-0.1,0.7-0.5,1.5-1,2.3c-0.5,0.8-1.2,1.7-1.9,2.5s-1.4,1.6-2.2,2.3c-0.7,0.7-1.4,1.3-1.9,1.8c-0.1,0.1-0.1,0.1-0.2,0.2l-0.1,0
                    l-0.6,0.4c-0.3,0-0.5,0.1-0.8,0.1c-0.2,0-0.5,0-0.8,0c-0.2,0-0.4,0-0.5-0.1l-0.2-0.4c0,0,0,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1
                    c-0.1,0-0.1-0.1-0.1-0.3c0-0.2,0-0.4,0-0.4c0-0.5,0-0.9,0.1-1.4c0.1-0.5,0.1-0.9,0.1-1.4c-0.2,0.2-0.5,0.4-0.7,0.6
                    c-0.3,0.3-0.5,0.5-0.8,0.8c-0.3,0.3-0.5,0.5-0.8,0.8C77.7,25.5,77.5,25.7,77.3,25.8z M74.4,23.8L74.4,23.8c0,0.1,0,0.2,0.1,0.2
                    L74.4,23.8z M74.4,24.5L74.4,24.5l0,0.1L74.4,24.5z M74.5,25.3L74.5,25.3C74.5,25.3,74.5,25.3,74.5,25.3
                    C74.5,25.3,74.5,25.3,74.5,25.3L74.5,25.3L74.5,25.3z M74.5,22.8c0,0.1,0,0.1,0,0.2s0,0.1,0,0.2C74.6,23,74.6,22.9,74.5,22.8z
                    M76.1,21.5c-0.1,0.1-0.1,0.3-0.2,0.5c-0.1,0.2-0.1,0.5-0.1,0.7c0,0.2-0.1,0.5-0.1,0.7s-0.1,0.5-0.1,0.6c0.1-0.4,0.2-0.8,0.3-1.3
                    C76,22.4,76.1,22,76.1,21.5L76.1,21.5z M77.1,22.9c0.1-0.4,0.2-0.8,0.3-1.1s0.1-0.7,0.2-1.1c-0.1,0.3-0.1,0.7-0.3,1
                    c-0.1,0.3-0.2,0.7-0.2,1V22.9z M78.3,18.9c-0.2,0.5-0.3,1-0.4,1.6c-0.1,0.6-0.2,1.2-0.2,1.7c0,0,0,0,0,0.1c0,0,0,0,0,0.1
                    c0.1,0,0.2-0.2,0.2-0.4c0.1-0.3,0.1-0.5,0.2-0.6c0-0.4,0-0.8,0-1.2C78.2,19.7,78.3,19.3,78.3,18.9z M78.4,16.6c0,0.3,0.1,0.6,0,0.8
                    c0,0.3,0,0.5,0.1,0.8c0-0.1,0.1-0.2,0.1-0.4c0-0.2,0-0.5,0-0.8c0-0.3,0-0.5-0.1-0.8L78.4,16.6z M80.5,19.7
                    c-0.2,0.1-0.3,0.3-0.5,0.5c-0.2,0.2-0.4,0.5-0.5,0.7c-0.2,0.3-0.4,0.5-0.5,0.7s-0.3,0.5-0.4,0.6c0.1-0.1,0.3-0.3,0.5-0.5
                    c0.2-0.2,0.5-0.5,0.7-0.8c0.2-0.3,0.5-0.6,0.6-0.8c0.2-0.3,0.3-0.5,0.3-0.7l-0.1-0.1L80.5,19.7z M79.8,23.4
                    c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.2,0.2-0.3c-0.1,0-0.2,0.1-0.3,0.3S79.8,23.3,79.8,23.4z M80,21.5L80,21.5L80,21.5l0,0.1
                    L80,21.5z M80.6,25.4c-0.1,0.1-0.2,0.2-0.2,0.3l0.1-0.1C80.6,25.6,80.6,25.6,80.6,25.4c0.1,0,0.1-0.1,0.1-0.1l-0.1-0.2L80.6,25.4z
                    M80.8,24.2c0,0,0,0.1,0,0.2c0,0.1,0,0.1,0.1,0.1L80.8,24.2L80.8,24.2z M80.9,23.3l0,0.1c0,0,0.1-0.1,0.1-0.2l0-0.1L80.9,23.3z
                    M81.9,24.3c0.2-0.3,0.3-0.7,0.3-1c-0.1,0.2-0.1,0.3-0.2,0.5C82,23.9,81.9,24,81.9,24.3L81.9,24.3z M84,22.7c0,0.1-0.1,0.2-0.2,0.3
                    c-0.1,0.1-0.2,0.2-0.2,0.3v0c0,0,0.1-0.1,0.2-0.3C84,23,84.1,22.8,84,22.7L84,22.7z M84.6,24.9L84.6,24.9l-0.1,0L84.6,24.9
                    L84.6,24.9z M85.3,20.9c0,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.1,0.1-0.2,0.2c0,0.1-0.1,0.2-0.2,0.4c-0.1,0.2-0.2,0.3-0.2,0.4v0
                    c0.1,0,0.2-0.2,0.4-0.5c0.2-0.3,0.4-0.7,0.7-1.1c0.3-0.4,0.5-0.8,0.7-1.1c0.2-0.3,0.4-0.5,0.5-0.6l0.2-0.2v-0.2L87,18.8
                    c0,0,0-0.1,0.1-0.3c0.1-0.2,0.1-0.3,0.2-0.3c0,0,0.1,0,0.1,0c0,0,0,0,0-0.1l0.1-0.3l0-0.1l-0.1,0.1c0,0.2-0.1,0.3-0.2,0.4
                    c-0.1,0.1-0.2,0.3-0.2,0.4L86.6,19c0-0.1,0-0.1,0-0.2c0,0,0.1-0.1,0.1-0.1c-0.1,0.1-0.2,0.2-0.3,0.4s-0.3,0.5-0.5,0.7
                    s-0.3,0.5-0.5,0.7c-0.1,0.2-0.2,0.4-0.3,0.5L85.3,20.9z M85.3,18.1c0,0-0.1,0.1-0.1,0.2c0,0.1,0,0.2-0.1,0.4c0,0.1-0.1,0.2-0.1,0.4
                    c0,0.1,0,0.2,0,0.2v0.1c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.1-0.3V18.1z M85.4,24.2L85.4,24.2L85.4,24.2L85.4,24.2L85.4,24.2z
                    M85.4,20.7c0.1-0.2,0.2-0.4,0.3-0.5s0.2-0.3,0.4-0.5c-0.1,0.1-0.2,0.3-0.4,0.6C85.6,20.5,85.5,20.7,85.4,20.7L85.4,20.7z
                    M89.7,18.5c-0.1,0-0.1,0.1-0.2,0.2c0,0.1-0.1,0.1-0.1,0.2c-0.5,0.9-1.2,1.8-1.9,2.7c-0.7,0.8-1.4,1.7-2.1,2.5
                    c0.2-0.2,0.4-0.4,0.8-0.8c0.4-0.4,0.7-0.8,1.1-1.3c0.4-0.4,0.7-0.9,1-1.3c0.3-0.4,0.5-0.7,0.5-0.8C89.3,19.5,89.6,19,89.7,18.5z
                    M85.8,22c0.3-0.4,0.7-0.8,1-1.3c0.4-0.4,0.6-0.9,0.8-1.4c0,0.1-0.1,0.1-0.2,0.2c-0.1,0-0.2,0.1-0.2,0.1c0,0.1-0.1,0.3-0.2,0.5
                    c-0.1,0.2-0.3,0.4-0.4,0.6c-0.2,0.2-0.3,0.4-0.5,0.6C85.9,21.6,85.8,21.8,85.8,22z M85.8,23c0.1-0.1,0.4-0.3,0.6-0.6
                    c0.3-0.3,0.6-0.7,0.9-1.1c0.3-0.4,0.6-0.8,0.8-1.1c0.3-0.4,0.4-0.6,0.5-0.8c-0.1,0-0.2,0.2-0.5,0.6c-0.3,0.3-0.6,0.7-0.9,1.1
                    c-0.3,0.4-0.6,0.8-0.9,1.2S85.9,22.9,85.8,23z M85.9,21.5c0.1-0.1,0.1-0.2,0.2-0.3L86,21.3L85.9,21.5z M86.4,19.3L86.4,19.3
                    l0.1-0.2l0,0L86.4,19.3z M87.9,17.1c0,0-0.1,0-0.1,0.1c-0.1,0.1-0.1,0.1-0.1,0.2l0,0.1c0,0,0.1-0.1,0.1-0.1S87.9,17.2,87.9,17.1
                    L87.9,17.1z M87.7,19l0.2,0l0.1-0.2l-0.2,0L87.7,19z M88.2,18.2C88.2,18.2,88.2,18.3,88.2,18.2c-0.1,0.1-0.1,0.2-0.1,0.2v0.1l0.1,0
                    V18.2L88.2,18.2z M88.4,18.1L88.4,18.1L88.4,18.1L88.4,18.1L88.4,18.1z"/>
                <path d="M98.8,12.1c0.4,0,0.7,0.1,1.1,0.2s0.7,0.3,1,0.5c0.3,0.2,0.6,0.5,0.8,0.8c0.2,0.3,0.3,0.7,0.3,1.1c0,0.2-0.1,0.5-0.2,0.8
                    c-0.1,0.3-0.3,0.6-0.5,0.9c-0.2,0.3-0.4,0.6-0.7,0.8c-0.2,0.2-0.5,0.4-0.7,0.4l0.5-0.6c-0.1,0.1-0.2,0.2-0.4,0.3s-0.3,0.2-0.5,0.2
                    l-0.1-0.2l0.1-0.2c-0.1,0.1-0.2,0.2-0.3,0.2l-0.1-0.1l0.6-1c0,0,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.1-0.2v-0.1
                    c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.3-0.3,0.5-0.5,0.6c0.1-0.4,0.2-0.8,0.5-1.2s0.5-0.8,0.7-1.1c-0.6,0.3-1.2,0.8-1.9,1.6
                    c-0.7,0.8-1.3,1.6-1.8,2.5c-0.6,0.9-1,1.8-1.4,2.8c-0.4,0.9-0.5,1.8-0.5,2.4c0,0.1,0,0.2,0,0.4c0,0.2,0.1,0.3,0.3,0.3
                    c0.4,0,0.8-0.1,1.2-0.3c0.4-0.2,0.8-0.5,1.2-0.7c0.4-0.3,0.7-0.6,1.1-1s0.6-0.7,0.9-0.9c0.1-0.1,0.2-0.3,0.4-0.5s0.3-0.3,0.4-0.4
                    l0,0.1c-0.4,0.5-0.8,1-1.2,1.4c-0.4,0.4-0.8,0.9-1.1,1.5c0.2-0.1,0.4-0.3,0.7-0.6c0.3-0.3,0.5-0.6,0.8-0.9c0.3-0.3,0.5-0.6,0.7-0.8
                    c0.2-0.2,0.4-0.4,0.5-0.5c0,0.1-0.1,0.3-0.2,0.4c-0.1,0.1-0.1,0.2-0.2,0.4c0.1-0.1,0.1-0.2,0.3-0.3c0.1-0.1,0.2-0.2,0.3-0.2l0,0.1
                    l-0.2,0.5l0.3-0.4c-0.2,0.3-0.4,0.7-0.6,1.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.2c0.1-0.1,0.1-0.1,0.1,0
                    c0,0-0.1,0.1-0.2,0.2c-0.1,0.1-0.2,0.1-0.2,0.2v0.1l0.1-0.1v0c-0.3,0.3-0.6,0.7-0.9,1.1c-0.3,0.4-0.7,0.7-1.1,1.1
                    c-0.4,0.3-0.8,0.6-1.3,0.8c-0.4,0.2-0.9,0.3-1.3,0.3c-0.7,0-1.3-0.1-1.8-0.3c-0.6-0.2-1.1-0.5-1.5-0.8c-0.4-0.4-0.8-0.8-1-1.3
                    s-0.4-1.2-0.4-1.9c0-0.7,0.1-1.4,0.4-2.1c0.2-0.8,0.5-1.5,0.9-2.3c0.4-0.8,0.9-1.5,1.4-2.3s1.1-1.4,1.8-1.9c0.6-0.6,1.3-1,2-1.4
                    C97.4,12.2,98.1,12.1,98.8,12.1z M93.2,17.1c0.2-0.1,0.5-0.3,0.7-0.6c0.3-0.3,0.6-0.5,0.9-0.9c0.3-0.3,0.6-0.6,0.9-0.9
                    s0.6-0.5,0.9-0.7c0.2-0.2,0.5-0.3,0.7-0.5c0.2-0.2,0.5-0.4,0.7-0.5c-0.3,0-0.7,0.2-1.2,0.5s-1,0.7-1.5,1.2c-0.5,0.5-1,0.9-1.4,1.4
                    S93.4,16.9,93.2,17.1z M93.7,16.1c0.2-0.2,0.5-0.5,0.7-0.7c0.2-0.2,0.5-0.5,0.7-0.7c-0.2,0.2-0.5,0.4-0.7,0.7
                    C94.1,15.7,93.8,15.9,93.7,16.1z M94.3,23.8L94.3,23.8l-0.2,0.1l0,0.1l0.1,0L94.3,23.8z M94.3,22.6L94.3,22.6l0.1-0.3L94.3,22.6z
                    M94.7,21.6l0-0.2c-0.1,0.1-0.1,0.2-0.1,0.3v0.1L94.7,21.6z M95.1,20.6c0-0.3,0.1-0.5,0.3-0.7s0.3-0.5,0.4-0.7
                    c-0.3,0.3-0.5,0.6-0.7,1c-0.2,0.4-0.3,0.8-0.4,1.2L95.1,20.6z M94.8,17.3c0.5-0.5,1-1,1.5-1.5c0.5-0.5,0.9-1,1.5-1.5
                    c-0.6,0.4-1.1,0.9-1.6,1.4C95.7,16.2,95.3,16.7,94.8,17.3z M96,19c0.5-0.9,1.1-1.8,1.8-2.5c0.7-0.7,1.5-1.4,2.4-2
                    c-0.2,0-0.6,0.2-1.1,0.6c-0.5,0.4-1,0.8-1.5,1.3c-0.5,0.5-0.9,1-1.3,1.5c-0.4,0.5-0.5,0.9-0.5,1.1v0L96,19z M99.2,14.5
                    c-0.5,0.4-1,0.8-1.5,1.2s-0.9,0.9-1.3,1.4c0.1,0,0.2-0.1,0.3-0.2s0.2-0.2,0.3-0.3c0.3-0.4,0.7-0.7,1.1-1.1S98.8,14.9,99.2,14.5z
                    M96.6,15c0,0-0.1,0.1-0.2,0.2L96.6,15z M99.4,23.6c-0.2,0.2-0.3,0.3-0.4,0.5c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.1,0.2-0.1,0.2
                    c0.1-0.1,0.2-0.2,0.4-0.5C99.2,23.9,99.3,23.7,99.4,23.6L99.4,23.6z M99,23.8c-0.1,0-0.1,0.1-0.2,0.2c-0.1,0.1-0.1,0.1-0.2,0.2
                    c0.1,0,0.1-0.1,0.2-0.2C98.9,24,98.9,23.9,99,23.8z M99.4,22.6l0.1-0.1l0.1-0.1L99.4,22.6L99.4,22.6z M100,16.8
                    c0.3-0.2,0.4-0.4,0.5-0.6c-0.1,0.1-0.2,0.2-0.3,0.3C100.2,16.6,100.1,16.7,100,16.8z M100.4,17.6c0.2-0.2,0.3-0.3,0.5-0.5
                    s0.3-0.3,0.5-0.5h0c0,0.1-0.2,0.3-0.4,0.5C100.7,17.4,100.5,17.5,100.4,17.6z M100.6,15L100.6,15l-0.2,0.2v0.1L100.6,15z
                    M100.6,16.8c0,0,0,0.1-0.1,0.2l0.4-0.5c0,0-0.1,0.1-0.2,0.1S100.7,16.8,100.6,16.8z M100.9,15.6l-0.1,0.1l0,0.2L100.9,15.6z
                    M101.1,15.5L101.1,15.5l-0.2-0.1L101.1,15.5L101.1,15.5z M101.6,16.1l-0.2,0.3v0L101.6,16.1z"/>
                <path d="M103.4,26.8c-0.4,0-0.8-0.1-1.1-0.2c-0.3-0.2-0.5-0.4-0.6-0.7s-0.3-0.6-0.3-0.9c-0.1-0.3-0.1-0.7-0.1-1
                    c0-0.7,0.1-1.6,0.3-2.8c0.2-1.2,0.5-2.6,0.9-4c0.4-1.5,0.8-3,1.3-4.5c0.5-1.5,1.1-2.9,1.7-4.2c0.6-1.2,1.3-2.3,2-3.1
                    c0.7-0.8,1.5-1.2,2.3-1.2c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0,0.2,0l0,0.1l-0.1,0.2l0.1,0.1c0,0,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0
                    c0.1,0,0.2,0,0.3,0.1c0.1,0,0.2,0.1,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.2,0.2c0,0,0,0.1,0,0.1c0,0,0,0,0,0
                    c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.2-0.2,0.3-0.3,0.5s-0.1,0.2-0.1,0.3V7c0.2-0.3,0.3-0.5,0.5-0.8
                    c0.2-0.3,0.4-0.5,0.5-0.7h0.2c0,0.1-0.1,0.3-0.2,0.6c-0.1,0.3-0.3,0.6-0.5,0.9c-0.2,0.3-0.3,0.7-0.5,1c-0.2,0.3-0.3,0.5-0.3,0.7
                    c-0.1,0.2-0.1,0.3-0.2,0.5c0,0.1-0.1,0.3-0.2,0.5c-0.2,0.5-0.4,1-0.6,1.5c-0.2,0.5-0.5,1-0.7,1.5c-0.4,0.9-0.8,1.9-1.2,2.9
                    c-0.4,1-0.8,2.1-1.2,3.1c-0.4,1-0.7,2.1-1,3.2s-0.5,2.1-0.6,3.1c0,0.1,0,0.1,0,0.2s0,0.1,0,0.2c0,0.1,0,0.2,0.1,0.3
                    c0.1-0.8,0.3-1.5,0.4-2.3s0.3-1.5,0.5-2.2c0.6-2,1.3-4,2.1-6c0.8-2,1.6-3.9,2.4-5.9c0.2-0.6,0.5-1.1,0.9-1.7
                    c0.4-0.5,0.6-1.1,0.9-1.7c0,0.3-0.1,0.7-0.3,1c-0.1,0.3-0.3,0.7-0.5,1.1s-0.3,0.7-0.5,1s-0.3,0.7-0.4,1c-0.2,0.6-0.5,1.4-0.9,2.2
                    s-0.7,1.7-1.1,2.6c-0.4,0.9-0.8,1.8-1.2,2.8c-0.4,0.9-0.8,1.9-1.1,2.7c-0.3,0.9-0.6,1.7-0.8,2.5c-0.2,0.8-0.3,1.5-0.3,2.1
                    c0,0.1,0,0.2,0,0.3s0,0.2,0,0.3c0.1,0,0.3,0,0.5,0s0.3,0.1,0.3,0.2c0,0.1-0.1,0.2-0.3,0.4c-0.2,0.1-0.5,0.3-0.7,0.4
                    c-0.3,0.1-0.5,0.2-0.8,0.3C103.6,26.8,103.5,26.8,103.4,26.8z M102.2,24.9c0.2-1.4,0.4-2.7,0.7-4.1c0.2-1.4,0.5-2.7,0.9-4.1
                    c-0.2,0.5-0.3,1.1-0.5,1.7c-0.2,0.6-0.3,1.2-0.5,1.8c-0.2,0.6-0.3,1.2-0.4,1.8c-0.1,0.6-0.2,1.2-0.2,1.7c0,0.2,0,0.4,0,0.5
                    C102.2,24.5,102.2,24.7,102.2,24.9z M102.9,18.3L102.9,18.3L102.9,18.3L102.9,18.3z M103,17.9L103,17.9c0,0,0.1-0.1,0.1-0.2
                    s0.1-0.2,0.1-0.3l-0.1,0.1L103,17.9z M103.3,16.9c0-0.2,0.1-0.4,0.2-0.7c0.1-0.3,0.2-0.6,0.3-0.9c0.1-0.3,0.2-0.6,0.3-0.9
                    s0.2-0.5,0.2-0.7c-0.1,0.2-0.1,0.4-0.3,0.7c-0.1,0.3-0.2,0.6-0.3,0.9s-0.2,0.6-0.2,0.9C103.3,16.5,103.3,16.7,103.3,16.9
                    L103.3,16.9z M103.3,25C103.3,25,103.3,24.9,103.3,25c0.1-0.2,0.1-0.2,0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3l0-0.6
                    c-0.1,0.4-0.1,0.8-0.1,1.2L103.3,25L103.3,25z M104.3,24.9c0-0.2,0-0.4,0-0.6c0-0.2,0-0.4,0-0.6c0,0.1,0,0.2,0,0.3
                    c0,0.1,0,0.2,0,0.4c0,0.3,0,0.7,0.1,1V24.9z M104.3,13.4L104.3,13.4l0.1-0.3l0,0L104.3,13.4z M104.5,18.5L104.5,18.5L104.5,18.5
                    l0,0.2L104.5,18.5z M104.6,18.3c0.2-0.5,0.4-1.1,0.6-1.7c0.2-0.6,0.3-1.1,0.5-1.7c0.1-0.5,0.3-0.9,0.6-1.4c0.2-0.4,0.5-0.9,0.7-1.4
                    c0.2-0.5,0.4-0.9,0.6-1.4c0.2-0.5,0.3-0.9,0.4-1.4c-0.2,0.3-0.5,0.9-0.9,1.7c-0.4,0.8-0.7,1.7-1.1,2.6c-0.4,0.9-0.7,1.8-0.9,2.7
                    C104.7,17.3,104.6,17.9,104.6,18.3L104.6,18.3z M104.8,12c0.2-0.4,0.4-0.8,0.5-1.1c0.2-0.3,0.3-0.6,0.5-1c0.2-0.4,0.4-0.7,0.5-1.1
                    s0.3-0.6,0.5-0.9c0.1-0.3,0.2-0.4,0.3-0.5c-0.4,0.8-0.8,1.5-1.1,2.3c-0.3,0.8-0.6,1.6-0.9,2.4c0.3-0.9,0.7-1.7,1.1-2.6
                    c0.4-0.8,0.9-1.6,1.4-2.4c0.2-0.3,0.5-0.6,0.8-0.9c0.3-0.3,0.6-0.6,0.8-1c-0.3,0-0.7,0.2-1,0.6c-0.3,0.3-0.6,0.7-0.8,1l0.1-0.2
                    c-0.2,0.2-0.4,0.5-0.7,1s-0.6,1-0.9,1.6c-0.3,0.6-0.5,1.1-0.8,1.6C105,11.3,104.9,11.7,104.8,12z M105.6,18.9
                    c0.3-0.8,0.6-1.5,0.8-2c0.2-0.6,0.5-1.2,0.8-1.9c0.3-0.7,0.5-1.4,0.8-2c0.3-0.7,0.5-1.2,0.7-1.7c0.2-0.5,0.3-0.8,0.4-1.1l-0.1,0.1
                    c-0.7,1.4-1.3,2.8-1.8,4.3C106.6,15.9,106.1,17.4,105.6,18.9z M107.2,7.4C107.2,7.4,107.2,7.3,107.2,7.4
                    C107.2,7.3,107.2,7.4,107.2,7.4z M107.2,7.3c0.1-0.1,0.2-0.3,0.3-0.5L107.2,7.3z M109.9,6c-0.4,0.5-0.8,1.1-1.1,1.6
                    c-0.3,0.6-0.6,1.2-0.9,1.8c0.3-0.6,0.7-1.1,1-1.7C109.3,7.1,109.6,6.6,109.9,6z M110.4,7.2c0,0-0.1,0.1-0.2,0.2S110,7.8,109.9,8
                    c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.2-0.1,0.3-0.2,0.4c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.2,0.1-0.3,0.2-0.5s0.1-0.3,0.2-0.5
                    C110.2,7.5,110.3,7.3,110.4,7.2L110.4,7.2z"/>
                <path d="M114.5,16c-0.1,0.1-0.2,0.4-0.3,0.9c-0.2,0.4-0.3,1-0.5,1.6c-0.2,0.6-0.4,1.3-0.5,1.9c-0.2,0.7-0.4,1.3-0.5,1.9
                    c-0.2,0.6-0.3,1.1-0.4,1.6c-0.1,0.4-0.2,0.7-0.2,0.9c0,0.3,0.1,0.7,0.2,1l-0.2,0.1l-0.2,0.5l-0.5,0l-0.1,0l-0.3,0l0.1,0.1
                    c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c-0.5,0-0.9-0.1-1.2-0.3s-0.7-0.4-0.9-0.8c-0.2-0.3-0.4-0.7-0.5-1.1s-0.2-0.8-0.2-1.3
                    c0-0.4,0.1-1,0.2-1.7c0.1-0.7,0.3-1.4,0.6-2.1s0.5-1.5,0.9-2.3c0.3-0.8,0.7-1.5,1.1-2.2c0.4-0.7,0.8-1.2,1.2-1.7
                    c0.4-0.5,0.8-0.8,1.2-1.1l0.2,0.3c0,0.1-0.1,0.2-0.2,0.3l-0.1,0.3l-0.1,0l-0.5,0.5v0.1c0,0.1-0.1,0.2-0.2,0.3
                    c-0.1,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0c0,0,0,0,0,0l0.2,0.1l-0.1-0.2l0-0.1l0.2-0.1l0.1,0.1v0.2c0.1,0,0.1-0.1,0.1-0.2s0-0.1,0-0.2
                    c0.1-0.1,0.3-0.3,0.4-0.4c0.2-0.1,0.3-0.2,0.4-0.4c0-0.1,0.1-0.2,0.3-0.4c0.1-0.2,0.3-0.3,0.3-0.3c0,0,0.1,0,0.1,0.1
                    c0,0,0,0.1,0,0.1c0,0.1,0.1,0.2,0.1,0.3c0,0.1-0.1,0.3-0.2,0.4c-0.1,0.2-0.2,0.4-0.2,0.6l0.1,0.1l0-0.3l0.2-0.1
                    c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.2c0,0,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2,0,0.2l0,0.2l0.1,0.1
                    c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2,0.1,0.3c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.3c0,0.2-0.1,0.2-0.1,0.3
                    c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2l-0.1,0.1l-0.1-0.1c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.3,0,0.3
                    l0.1,0.1h0c0.1-0.1,0.2-0.3,0.2-0.5L114.5,16z M110,16.5l-0.2-0.2L110,16.5L110,16.5z M110.4,17.1l-0.1,0.1l0,0.1L110.4,17.1
                    L110.4,17.1z M110.8,16.5L110.8,16.5l-0.2,0L110.8,16.5L110.8,16.5z M111.4,15L111.4,15l0,0.2L111.4,15z M112.4,21.6
                    c-0.2,0.2-0.3,0.5-0.4,0.9c-0.1,0.4-0.1,0.7-0.2,1c0.1,0,0.1,0,0.1-0.1v0l0-0.2c0-0.1,0.1-0.2,0.1-0.2c0-0.2,0.1-0.4,0.2-0.7
                    C112.3,22.2,112.4,21.9,112.4,21.6L112.4,21.6z M112,25.1L112,25.1L112,25.1l-0.1,0.1L112,25.1z M112.2,13.6c0-0.1,0.1-0.2,0.2-0.3
                    s0.2-0.2,0.2-0.2c0,0.1,0,0.2-0.1,0.3C112.3,13.5,112.3,13.6,112.2,13.6z M112.5,21.3L112.5,21.3L112.5,21.3l-0.1,0.2L112.5,21.3z
                    M116.8,7c0.2,0.2,0.3,0.5,0.3,0.8c0,0.2,0,0.4-0.1,0.6s-0.2,0.4-0.4,0.5c0,0,0,0,0,0c-0.1,0.1-0.1,0.1-0.2,0.2
                    c-0.1,0.1-0.2,0.3-0.3,0.5c-0.1,0.1-0.2,0.3-0.4,0.4c-0.2,0.1-0.3,0.2-0.5,0.3l-0.1,0.3l-0.1-0.1c-0.1,0-0.2,0-0.3,0l-0.2,0.1
                    c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.1-0.4-0.3-0.5-0.4V9.8h-0.2c-0.1,0.1-0.2,0.1-0.3-0.1s-0.2-0.3-0.2-0.5c-0.1-0.2-0.1-0.4-0.1-0.6
                    c0-0.2,0-0.3,0-0.4c0-0.3,0.1-0.6,0.2-0.9c0.1-0.3,0.3-0.5,0.6-0.8c0.2-0.2,0.5-0.4,0.8-0.6c0.3-0.1,0.6-0.2,0.9-0.2
                    c0.3,0,0.6,0.1,0.9,0.2s0.5,0.3,0.8,0.5c0,0.1,0.1,0.1,0.1,0.2C116.8,6.8,116.8,6.9,116.8,7L116.8,7z M112.7,20.7L112.7,20.7
                    l-0.2,0.2l0.1,0L112.7,20.7z M112.7,15.1L112.7,15.1L112.7,15.1L112.7,15.1z M112.9,20.3L112.9,20.3l-0.1,0.2v0.1l0.1-0.1
                    L112.9,20.3z M113.4,18.9c0,0-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.1,0.4c0,0.1-0.1,0.2-0.1,0.2V20
                    c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.2,0.1-0.3l0.2-0.2L113.4,18.9z M113.2,14.1L113.2,14.1l0,0.2L113.2,14.1z M113.6,18.7l-0.1-0.3
                    l-0.1,0.1l0,0.2L113.6,18.7z M113.5,7.2c0.1-0.1,0.3-0.2,0.6-0.3c0.3-0.2,0.4-0.3,0.5-0.4l0-0.1C114.2,6.6,113.8,6.8,113.5,7.2z
                    M113.5,18.1L113.5,18.1h0.1l-0.1-0.1L113.5,18.1z M113.8,17.7l-0.1,0.2l0,0L113.8,17.7L113.8,17.7z M114.2,16.8
                    c-0.1,0-0.1,0-0.1,0.1s-0.1,0.1-0.1,0.2l0.1,0.2l0-0.1l0-0.1C114.1,17,114.1,16.9,114.2,16.8L114.2,16.8z M114,16.7L114,16.7l0.1,0
                    l-0.1-0.1L114,16.7z M114.6,7.2c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c0.1-0.1,0.2-0.2,0.3-0.2
                    C114.4,7.4,114.5,7.3,114.6,7.2z M114.3,14L114.3,14L114.3,14L114.3,14L114.3,14z M114.5,15.6c0.1-0.2,0.2-0.3,0.2-0.5l0.1-0.1
                    c0,0.1,0.1,0.2,0.1,0.2c0,0,0,0.1,0,0.1s0,0.1,0,0.1L114.5,15.6z M114.7,14l0-0.3l0.1,0.1l-0.1,0.4l0-0.1L114.7,14z M114.9,7.8
                    L114.9,7.8l-0.2,0.1l0,0L114.9,7.8z M114.7,9.3c0.2,0,0.4-0.1,0.5-0.2c0.2-0.1,0.3-0.2,0.4-0.4c0,0,0,0,0,0c0,0,0,0,0,0
                    c-0.1,0-0.2,0.1-0.4,0.3S114.8,9.2,114.7,9.3z M114.8,14.9L114.8,14.9l0-0.2L114.8,14.9L114.8,14.9z M114.9,7.7
                    C114.9,7.7,114.9,7.8,114.9,7.7L114.9,7.7L114.9,7.7L114.9,7.7z M115.6,9.9c0.1-0.1,0.2-0.1,0.2-0.2c0.1-0.1,0.1-0.1,0.2-0.2
                    L115.6,9.9L115.6,9.9z M116.3,9.6c-0.1,0.3-0.3,0.5-0.6,0.6L116.3,9.6z M116,6.2l0.1-0.1L116,6.2L116,6.2L116,6.2z M116.3,7.4h-0.2
                    l0,0L116,7.7l0,0.1l0.1-0.1l0-0.2h0.2V7.4z"/>
                <path d="M125.1,24.1c-0.2,0.1-0.3,0.2-0.5,0.3c0,0.1-0.1,0.2-0.1,0.2c0,0.1-0.1,0.1-0.2,0.2c-0.3,0-0.4,0.1-0.5,0.1
                    c-0.1,0-0.2,0-0.2,0.1c0,0-0.1,0.1-0.2,0.1s-0.2,0.1-0.4,0.2l-0.1,0.2c-0.2,0-0.4,0.1-0.5,0.2c-0.2,0.1-0.4,0.1-0.6,0.1
                    c-0.2,0-0.5,0-0.7-0.1c-0.2,0.1-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.1-0.4,0.1c-0.3,0-0.7-0.1-1-0.2s-0.6-0.2-1-0.2
                    c-0.1,0-0.2,0-0.2,0.1c0,0.1,0,0.1-0.1,0.2c-0.1,0.2-0.1,0.6-0.2,1.1c-0.1,0.6-0.2,1.1-0.4,1.7c-0.1,0.6-0.3,1.1-0.4,1.6
                    c-0.2,0.5-0.3,0.8-0.4,0.8l-0.2,0.1c0.1,0.1,0.1,0.1,0.2,0.1h0.1l-0.2,0.9h0l0-0.2l-0.1,0.1c0,0.2-0.1,0.4-0.1,0.6
                    c0,0.2-0.1,0.4-0.1,0.6c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1-0.1-0.1c0,0.1-0.1,0.3-0.2,0.4
                    c-0.1,0.2-0.2,0.3-0.2,0.4c-0.1,0-0.2-0.1-0.1-0.2c0-0.1,0-0.2-0.1-0.2l0,0.1c0,0.3-0.1,0.6-0.3,0.9l-0.2-0.1c0,0-0.1-0.1-0.1-0.2
                    s0-0.2,0-0.4c0-0.1,0-0.3,0-0.4c0-0.1,0-0.2,0-0.3c0-1.5,0.1-3,0.3-4.4c0.2-1.4,0.5-2.9,0.9-4.3c0.4-1.4,0.8-2.8,1.2-4.2
                    c0.4-1.4,0.9-2.8,1.3-4.2c0.1-0.1,0.2-0.3,0.3-0.5c0-0.2,0.1-0.4,0.1-0.5v0c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1-0.1,0.2-0.2,0.3
                    c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0.1-0.2v-0.1c-0.1,0.1-0.2,0.4-0.4,0.8c-0.2,0.4-0.3,0.8-0.5,1.2s-0.3,0.8-0.5,1.2s-0.2,0.6-0.2,0.7
                    c0.1-0.1,0.1-0.1,0.2-0.2c0-0.1,0.1-0.2,0.1-0.3c0.1-0.4,0.3-0.7,0.4-1.1c0.1-0.4,0.3-0.7,0.5-1.1l0,0c0,0.1-0.1,0.3-0.2,0.7
                    c-0.1,0.4-0.3,0.8-0.5,1.2c-0.2,0.4-0.3,0.8-0.5,1.1c-0.2,0.3-0.3,0.5-0.3,0.5c0,0.1,0,0.3,0,0.6c-0.1,0.3-0.1,0.6-0.2,1
                    c-0.1,0.3-0.2,0.7-0.3,0.9c-0.1,0.3-0.2,0.4-0.3,0.5l0,0l0.2,0.2l-0.1,0.2c0,0.3-0.1,0.7-0.2,1c-0.1,0.3-0.2,0.7-0.3,1l0,0.2
                    c-0.1,0.3-0.1,0.6-0.2,1.1c-0.1,0.5-0.2,0.8-0.3,1c0,0.1,0,0.3-0.1,0.6c0,0.3-0.1,0.6-0.2,1c-0.1,0.4-0.1,0.7-0.2,1.1
                    c-0.1,0.4-0.1,0.7-0.1,0.9c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3l-0.1,0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2c0,0.3,0,0.7,0,1
                    c0,0.3,0.1,0.7,0.1,1l-0.1,0.2c-0.2-0.1-0.3-0.3-0.4-0.6c-0.1-0.3-0.1-0.5-0.2-0.8c0-0.3-0.1-0.6-0.1-0.9c0-0.3,0-0.5,0-0.7
                    c0-0.6,0-1.2,0.1-1.8c0.1-0.6,0.2-1.2,0.3-1.8c0.1-0.6,0.2-1.2,0.4-1.8s0.2-1.2,0.3-1.8c0-0.3,0.2-0.9,0.4-1.6s0.4-1.6,0.7-2.6
                    c0.3-0.9,0.6-1.9,1-2.9s0.7-1.9,1.1-2.8c0.4-0.8,0.8-1.5,1.2-2.1c0.4-0.5,0.7-0.8,1-0.9c0,0.1,0,0.2-0.1,0.3s-0.1,0.1-0.1,0.2
                    l0.2-0.2c-0.1,0.2-0.2,0.5-0.3,0.7c-0.1,0.2-0.2,0.5-0.3,0.7c0.1,0,0.2-0.1,0.3-0.3s0.2-0.3,0.4-0.5c0.1-0.2,0.2-0.3,0.3-0.5
                    c0.1-0.1,0.2-0.2,0.2-0.2c0.1,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.1c0.3,0.1,0.5,0.3,0.6,0.4s0.2,0.4,0.2,0.7c0,0.2,0,0.4,0,0.5
                    c0,0.2,0,0.4,0,0.6v0.2l0.1-0.3l0.1-0.1c0,0.3,0,0.5-0.1,0.8c0,0.2-0.1,0.5-0.1,0.8c0.3-0.3,0.5-0.5,0.9-0.9s0.6-0.6,1-0.9
                    c0.4-0.3,0.7-0.5,1.1-0.7c0.4-0.2,0.8-0.3,1.2-0.3c0.4,0,0.8,0.1,1.2,0.3c0.4,0.2,0.8,0.4,1.1,0.7c0.3,0.3,0.6,0.6,0.9,1
                    c0.2,0.4,0.3,0.8,0.3,1.2c0,0.2,0,0.3,0.1,0.4c0,0.3-0.1,0.6-0.2,1c-0.1,0.3-0.2,0.6-0.3,0.9l0.3-0.3c0-0.1,0-0.2,0.1-0.2
                    c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0.2-0.1,0.5-0.3,0.9c-0.2,0.5-0.5,1-0.7,1.5s-0.6,1-0.8,1.4c-0.3,0.4-0.5,0.7-0.6,0.8
                    c-0.1,0.1-0.1,0.2-0.2,0.4c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.2,0.3-0.4,0.4c-0.1,0.1-0.2,0.2-0.3,0.2l-0.3,0.2
                    c0,0.1,0,0.2-0.1,0.2H125.1L125.1,24.1z M115.3,33c0,0,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1V33L115.3,33z M115.6,30.7L115.6,30.7
                    C115.6,30.7,115.6,30.7,115.6,30.7l-0.1,0L115.6,30.7L115.6,30.7z M115.9,28.1C115.9,28,115.9,28,115.9,28.1c0.1-0.1,0-0.2,0-0.2
                    V28.1z M116.1,32.4C116.1,32.4,116.1,32.3,116.1,32.4c0.1-0.2,0.1-0.2,0.2-0.2l0-0.1l-0.2,0L116.1,32.4L116.1,32.4z M116.6,32.7
                    L116.6,32.7h-0.2H116.6L116.6,32.7z M116.4,26L116.4,26l0.1,0.1c0.1,0,0.1-0.1,0.1-0.2l-0.1-0.1L116.4,26z M117,18.8L117,18.8
                    c-0.1,0.1-0.1,0.2-0.1,0.2c0,0.1,0,0.1,0,0.2L117,18.8z M116.9,30.4L116.9,30.4l0,0.1L116.9,30.4z M116.9,29.2c0,0,0,0.1,0,0.2
                    L116.9,29.2z M117.1,27.5l0-0.1l-0.1-0.2l-0.1,0.1L117.1,27.5z M117.4,29.3C117.4,29.3,117.3,29.3,117.4,29.3
                    c-0.1-0.1-0.2-0.1-0.2-0.1h-0.2C117.1,29.4,117.2,29.4,117.4,29.3L117.4,29.3z M117.2,26.7l-0.2,0.2l0,0.1L117.2,26.7L117.2,26.7z
                    M117.4,26.2L117.4,26.2l0,0.2L117.4,26.2L117.4,26.2z M117.4,28.7c0.2-0.5,0.4-1,0.5-1.5c0.1-0.5,0.2-1,0.3-1.6l0-0.1
                    c-0.2,0.4-0.3,0.9-0.5,1.5c-0.2,0.6-0.3,1.1-0.3,1.6V28.7z M117.5,22L117.5,22l0.1-0.1l-0.1,0L117.5,22z M117.6,25.5
                    c0.1-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.2-0.1-0.3l-0.1,0.2l0.1,0l0,0.2c-0.1,0.1-0.1,0.2-0.1,0.3l0,0.2L117.6,25.5z M117.6,21.6
                    c0.1-0.3,0.2-0.7,0.4-1.2c0.2-0.5,0.4-1,0.6-1.6c0.2-0.5,0.4-1.1,0.5-1.6c0.2-0.5,0.3-0.9,0.3-1.1c-0.1,0-0.2,0.3-0.4,0.9
                    c-0.2,0.5-0.4,1.1-0.6,1.7s-0.4,1.2-0.6,1.8C117.7,21.1,117.6,21.5,117.6,21.6z M118.2,15.8c0,0,0-0.1,0.1-0.2
                    c0.1-0.1,0.1-0.2,0.2-0.2c0,0-0.1,0.1-0.1,0.3C118.3,15.8,118.2,15.8,118.2,15.8L118.2,15.8z M118.6,22c0,0,0.1-0.1,0.1-0.2
                    c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.2,0.1-0.4c0-0.1,0-0.2,0-0.2v-0.1L118.6,22z M119.4,23.8c0.5,0,1-0.2,1.5-0.5
                    c0.5-0.3,0.9-0.7,1.4-1.2c0.4-0.5,0.9-0.9,1.2-1.5c0.4-0.5,0.7-0.9,0.9-1.3c0.5-0.7,0.8-1.5,1.1-2.2s0.5-1.6,0.6-2.4
                    c-0.4,0-0.9,0.2-1.4,0.6s-0.9,0.9-1.4,1.5c-0.4,0.6-0.8,1.1-1.2,1.7c-0.4,0.6-0.7,1-1,1.3c0.1-0.3,0.2-0.6,0.4-0.8
                    c0.2-0.3,0.3-0.5,0.5-0.7c0.2-0.2,0.4-0.5,0.6-0.7c0.2-0.2,0.3-0.5,0.5-0.8c-0.2,0.1-0.4,0.4-0.7,0.8c-0.3,0.4-0.6,0.9-0.9,1.4
                    c-0.3,0.5-0.6,1-0.9,1.5c-0.3,0.5-0.5,0.8-0.6,1c0.1-0.3,0.3-0.6,0.4-0.9c0.2-0.3,0.3-0.6,0.4-0.9c-0.2,0.2-0.3,0.4-0.4,0.6
                    c-0.1,0.2-0.2,0.4-0.4,0.6c0,0.1-0.1,0.3-0.1,0.4s-0.1,0.3-0.2,0.4l0,0.1l0.2-0.2c-0.2,0.4-0.3,0.7-0.5,1.1
                    c-0.1,0.4-0.3,0.7-0.4,1.1C119.1,23.8,119.2,23.8,119.4,23.8z M119.5,21.2c-0.1,0-0.1,0.1-0.2,0.2c0,0.1-0.1,0.2-0.1,0.3
                    L119.5,21.2z M119.5,25.7h-0.1l0,0L119.5,25.7z M119.6,15.8L119.6,15.8l0,0.2l0.1-0.1L119.6,15.8z M119.8,20.5c0,0-0.1,0.1-0.1,0.3
                    s-0.1,0.2-0.1,0.2V21c0.1-0.1,0.1-0.2,0.2-0.3C119.7,20.7,119.7,20.6,119.8,20.5z M120.6,16c0,0.1-0.1,0.2-0.2,0.4
                    s-0.2,0.4-0.3,0.7c-0.1,0.2-0.2,0.5-0.2,0.7c-0.1,0.2-0.1,0.3-0.1,0.4v0.1c0.2-0.3,0.3-0.6,0.5-1.1S120.6,16.3,120.6,16z
                    M120.6,17.6c-0.1,0.1-0.2,0.3-0.3,0.5c-0.1,0.2-0.2,0.4-0.2,0.5c0.1-0.2,0.2-0.3,0.2-0.5C120.5,18,120.6,17.8,120.6,17.6
                    L120.6,17.6z M121,19.2c-0.1,0-0.1,0.1-0.1,0.1l0.1,0L121,19.2L121,19.2z M121.4,15.1c-0.1,0.1-0.2,0.3-0.2,0.4
                    c-0.1,0.2-0.1,0.3-0.1,0.5c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.3,0.1-0.4V15.1z M121.1,19.1c0.1-0.2,0.2-0.3,0.4-0.5
                    c0.1-0.2,0.2-0.4,0.3-0.6c-0.1,0.1-0.2,0.3-0.4,0.5C121.2,18.8,121.1,19,121.1,19.1z M122.9,14.8c-0.1,0-0.3,0.1-0.4,0.3
                    c-0.2,0.2-0.3,0.4-0.5,0.6c-0.2,0.2-0.3,0.4-0.5,0.7c-0.1,0.2-0.2,0.4-0.2,0.5C121.9,16.2,122.4,15.5,122.9,14.8z M121.6,25.6
                    L121.6,25.6L121.6,25.6L121.6,25.6L121.6,25.6z M121.9,13.5c0,0,0-0.1,0-0.2L121.9,13.5z M122.1,17.5L122.1,17.5l-0.2,0.2h0
                    L122.1,17.5z M122.8,25.3L122.8,25.3l0,0.1L122.8,25.3L122.8,25.3z M124.5,21.1c0.2-0.3,0.5-0.7,0.8-1.1c0.3-0.4,0.5-0.8,0.5-1.2
                    c-0.1,0-0.1,0.1-0.2,0.2c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0.2-0.3,0.5-0.5,0.7c-0.2,0.3-0.4,0.6-0.7,0.9c-0.2,0.3-0.4,0.6-0.6,0.8
                    c-0.2,0.3-0.3,0.5-0.4,0.6c0.3-0.2,0.5-0.4,0.7-0.6C124.1,21.4,124.2,21.2,124.5,21.1z M123.8,25.1c0,0-0.1,0.1-0.1,0.2
                    c0,0.1-0.1,0.1-0.2,0.1l-0.1,0v-0.2L123.8,25.1z M124.3,24.5L124.3,24.5h-0.1H124.3L124.3,24.5z M124.5,24.3
                    c0.1-0.1,0.2-0.2,0.2-0.2l0,0c0,0-0.1,0-0.1,0.1C124.5,24.2,124.4,24.2,124.5,24.3L124.5,24.3z M125.2,23.5l-0.1-0.1l0,0.1H125.2
                    L125.2,23.5z M125.6,18.6c-0.1,0.1-0.1,0.2-0.2,0.2s-0.1,0.2-0.1,0.3c0.1-0.1,0.1-0.2,0.2-0.2C125.5,18.8,125.5,18.7,125.6,18.6z
                    M125.4,23.5L125.4,23.5l0,0.2L125.4,23.5L125.4,23.5z M126.3,19.6c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.3
                    c0.2-0.2,0.3-0.4,0.5-0.6c0.2-0.2,0.3-0.5,0.4-0.8c-0.1,0.1-0.2,0.2-0.3,0.5C126.5,19.5,126.4,19.6,126.3,19.6z M128.1,18.8
                    c-0.3,0.5-0.6,1-0.9,1.5c-0.3,0.5-0.6,1-1,1.5c0.1-0.1,0.3-0.2,0.5-0.5c0.2-0.3,0.4-0.6,0.6-0.9s0.3-0.6,0.5-0.9S128,19,128.1,18.8
                    z M128.9,18.4c-0.1,0.2-0.3,0.5-0.6,0.9c-0.3,0.4-0.6,0.9-0.9,1.4s-0.6,0.9-0.8,1.4s-0.4,0.7-0.4,0.8c0.2-0.2,0.4-0.5,0.7-0.9
                    c0.3-0.4,0.5-0.8,0.8-1.3c0.3-0.4,0.5-0.9,0.7-1.3S128.8,18.6,128.9,18.4z"/>
            </g>
            </svg>
        }
    </Fragment>
  );
}

export default Logo;

Logo.defaultProps = {
    size: 'base'
}