import React from 'react';

import './Spinner.scss';

const Spinner = () => {
    return (
        <div className='sh-spinner'/>
    );
}

export default Spinner;
